import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimeLineDot from "components/PageSpecific/Case/TimeLineDot";
import { getBeautyDateTime } from "helpers";
import tolgee from "services/translation";
export default function HistoryEntryTemplate(props) {
    var timeLineIcon = props.timeLineIcon, title = props.title, date = props.date, children = props.children, processedAt = props.processedAt;
    return (_jsxs(TimelineItem, { children: [_jsxs(TimelineSeparator, { children: [_jsx(TimeLineDot, { children: timeLineIcon }), _jsx(TimelineConnector, {})] }), _jsx(TimelineContent, { children: _jsxs("div", { className: "py-3 mb-0 transition-all duration-75 border rounded-lg hover:bg-slate-50", children: [_jsxs(Typography, { variant: "h6", component: "span", sx: { fontSize: 18, px: 2, pb: 1, display: "block" }, children: [title, " ", _jsx("span", { className: "text-xs italic font-thin text-gray-600 ", children: date })] }), _jsx(Divider, {}), children, _jsx("span", { className: "block w-full pt-3 pl-4 italic border-t text-brand-600", children: processedAt && (_jsxs("span", { className: "px-2 py-1 -mx-1 -my-1 rounded-md bg-brand-100 ", children: [tolgee.t({
                                        key: "dsr.processed_at",
                                    }), " ", getBeautyDateTime(processedAt), "."] })) })] }) })] }));
}
