import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import userReducer from "store/user";
import appReducer from "store/app";
export var store = configureStore({
    reducer: { user: userReducer, app: appReducer },
    middleware: function (getDefaultMiddleware) {
        return getDefaultMiddleware({
            thunk: {
                extraArgument: {},
            },
        });
    },
});
export function setupStore(preloadedState) {
    return configureStore({
        reducer: { user: userReducer, app: appReducer },
        preloadedState: preloadedState,
    });
}
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export var useAppDispatch = useDispatch;
export var useAppSelector = useSelector;
