import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";
import ConfirmationModal from "components/DataDisplay/Modal/ConfirmationModal";
export default function BaseButton(props) {
    var _a = props.variant, variant = _a === void 0 ? "contained" : _a, _b = props.color, color = _b === void 0 ? "primary" : _b, _c = props.disabled, disabled = _c === void 0 ? false : _c, className = props.className, _d = props.onClick, onClick = _d === void 0 ? function () { } : _d, children = props.children, style = props.style, confirmPopup = props.confirmPopup, _e = props.testid, testid = _e === void 0 ? "" : _e;
    var _f = useState(false), open = _f[0], setOpen = _f[1];
    var handleClick = function () {
        if (confirmPopup) {
            setOpen(true);
        }
        else {
            onClick();
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(Button, { "data-testid": testid, variant: variant, disabled: disabled, color: color, style: style, className: clsx(className, "w-fit h-fit"), onClick: handleClick, children: children }), confirmPopup && _jsx(ConfirmationModal, { modalState: [open, setOpen], onYes: onClick })] }));
}
