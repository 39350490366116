var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export function serializeContactDirectory(contact) {
    var _a;
    return __assign(__assign({}, contact), { addresses: (_a = contact.addresses) === null || _a === void 0 ? void 0 : _a.map(function (address) {
            var _a;
            return (__assign(__assign({}, address), { country: null, 
                // TODO you are serializing Country to country_iso in here. There is no better way for now, look at it in future.
                country_iso: (_a = address.country) === null || _a === void 0 ? void 0 : _a.iso_3166_1_alpha_2 }));
        }) });
}
