import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Fragment, useMemo } from "react";
import { T } from "@tolgee/react";
import tolgee from "services/translation";
import { VerticalDataValue } from "components";
import { getBeautyDateTime, getFileIcon, getFileName } from "helpers";
import FileDownloadPreview from "components/DataDisplay/FileDownloadPreview";
import { generateConsumerInfo, generateContactData, generateUrl, getClosedInquiries, getSender, } from "helpers/case";
import { useCase } from "pages/Client/Case/CaseContext";
export default function DSInfo() {
    var state = useCase()[0];
    var caseData = state.case;
    var files = caseData.files;
    var closedInquiries = getClosedInquiries(caseData.inquiries);
    var sender = getSender(caseData.case_actors);
    var consumerInfo = generateConsumerInfo(caseData);
    var senderInfo = generateContactData(sender);
    var otherInfo = useMemo(function () {
        var data = closedInquiries
            .filter(function (i) { var _a; return !["ip_address", "email", "acting_for"].includes((_a = i === null || i === void 0 ? void 0 : i.question_i18n) === null || _a === void 0 ? void 0 : _a.key); })
            .map(function (i) { return ({
            key: (i === null || i === void 0 ? void 0 : i.question_i18n) ? i.question_i18n.key : i.question,
            value: (i === null || i === void 0 ? void 0 : i.answer_i18n)
                ? tolgee.t({
                    key: i.answer_i18n.key,
                })
                : i.answer,
            answeredAt: i === null || i === void 0 ? void 0 : i.answered_at,
        }); });
        return data.filter(function (i) { return !!i.value; });
    }, [closedInquiries]);
    return (_jsxs("div", { className: "pt-5 mb-4 box-outerlayout @container", "data-testid": "dsr-info", children: [_jsx("div", { children: _jsxs("div", { className: "flex flex-col", children: [_jsx("div", { className: "flex items-center", children: _jsx("h2", { children: tolgee.t({
                                    key: "dsr.ds_information",
                                }) }) }), _jsx("div", { className: "my-4", children: tolgee.t({
                                key: "dsr.ds_information_description",
                            }) })] }) }), caseData.extra.acting_for !== "MYSELF" && (_jsxs(_Fragment, { children: [_jsx("h3", { className: "my-0 mb-1", children: tolgee.t({
                            key: "dsr.requester.".concat(caseData.extra.acting_for.toLowerCase()),
                        }) }), _jsx("div", { className: "mb-4", children: _jsxs("div", { className: "grid @lg:grid-cols-2 gap-x-2 gap-y-2 border border-slate-200 rounded-lg -mx-2 px-3 py-3", children: [senderInfo.map(function (i) { return (_jsx(VerticalDataValue, { title: tolgee.t({
                                        key: "dsr.".concat(i.key),
                                    }), value: i.value }, i.key)); }), _jsx(VerticalDataValue, { title: tolgee.t({
                                        key: "dsr.acting_for",
                                    }), value: tolgee.t({
                                        key: "ccpa.ds_info.acting_for.".concat(caseData.extra.acting_for.toLowerCase()),
                                    }) }), _jsx("br", {}), _jsx("span", { className: "text-gray-500", children: _jsx(T, { keyName: "ccpa.ds_info.requester_proof.info" }) })] }) })] })), _jsxs(_Fragment, { children: [_jsx("h3", { className: "my-0 mb-1", children: tolgee.t({
                            key: "dsr.data_subject",
                        }) }), _jsx("div", { className: "mb-4", children: _jsxs("div", { className: "grid  @lg:grid-cols-2 gap-x-2 gap-y-2 border border-slate-200 rounded-lg -mx-2 px-3 py-3 ", children: [consumerInfo.map(function (i) { return (_jsx(VerticalDataValue, { title: tolgee.t({
                                        key: "dsr.".concat(i.key),
                                    }), value: i.value }, i.key)); }), otherInfo.map(function (i) { return (_jsxs(Fragment, { children: [_jsx(VerticalDataValue, { title: tolgee.t({
                                                key: i.key,
                                            }), value: i.value }, i.key), (i === null || i === void 0 ? void 0 : i.answeredAt) && (_jsx("div", { className: "text-xs italic", children: getBeautyDateTime(i.answeredAt) }))] }, i.key)); })] }) })] }), _jsxs(_Fragment, { children: [_jsx("h3", { className: "mb-1", children: tolgee.t({
                            key: "dsr.files",
                        }) }), _jsx("div", { children: files === null || files === void 0 ? void 0 : files.map(function (file) { return (_jsx(FileDownloadPreview, { icon: getFileIcon(file.mime_type), url: generateUrl(caseData.uuid, file.uuid), fileName: getFileName(file), mimeType: file.mime_type }, file.uuid)); }) })] })] }));
}
