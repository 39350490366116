import { useLocation } from "react-router-dom";

import ProgressBar from "components/Feedback/ProgressBar";
import NavBar from "components/Layout/Navbar";
import SkeletonNavBar from "components/Layout/Navbar/SkeletonNavbar";
import { useAppSelector } from "store";
import RopaNavbar from "components/Layout/RopaNavbar";
import { isRopaToolPath } from "helpers/ropa/route";

function Main(props) {
  const location = useLocation();
  const { children } = props;
  const { userLoaded = false } = useAppSelector((state) => state.user);
  const { navbarState } = useAppSelector((state) => state.app.preferences);

  return (
    <main className="box-border flex-grow w-full mx-auto ">
      <div className="box-border flex px-4 md:px-0">
        {navbarState && (
          <div className="fixed z-30 bg-gray-50 top-0 pl-4 pr-2 pb-4 left-0 border-r border-gray-300 h-full mt-[4.5rem] max-h-[calc(100%-4.5rem)] mr-4 overflow-y-auto w-64 xl:w-80">
            {userLoaded ? <NavBar /> : <SkeletonNavBar />}
          </div>
        )}

        {userLoaded ? (
          <div
            className={`top-[4.5rem] ${
              navbarState
                ? "left-64 xl:left-80 w-[calc(100vw-16rem)] xl:w-[calc(100vw-20rem)]"
                : "w-full left-0"
            } h-[calc(100vh-4.5rem)] overflow-auto absolute p-6`}>
            {isRopaToolPath(location.pathname) && <RopaNavbar />}

            {children}
          </div>
        ) : (
          <ProgressBar />
        )}
      </div>
    </main>
  );
}

export default Main;
