var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper, Slide, Typography, IconButton, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppSelector } from "store";
import { SUCCESS, DANGER } from "const/color";
export default function NotificationService() {
    var notification = useAppSelector(function (state) { return state.app; }).notification;
    var _a = useState([]), visible = _a[0], setVisible = _a[1];
    useEffect(function () {
        if (notification.id > 0) {
            setVisible(function (v) { return __spreadArray([__assign(__assign({}, notification), { in: true })], v, true); });
            setTimeout(function () {
                setVisible(function (prevState) {
                    var index = prevState.findIndex(function (n) { return n.id === notification.id; });
                    return __spreadArray(__spreadArray(__spreadArray([], prevState.slice(0, index), true), [
                        __assign(__assign({}, prevState[index]), { in: false })
                    ], false), prevState.slice(index + 1), true);
                });
            }, notification.duration * 1000);
        }
    }, [notification]);
    var handleCloseNotification = function (id) {
        setVisible(function (prevState) { return prevState.filter(function (n) { return n.id !== id; }); });
    };
    return (_jsx("div", { children: visible &&
            visible.map(function (item, index) { return (_jsx(Slide, { direction: "left", in: item.in, mountOnEnter: true, unmountOnExit: true, children: _jsxs(Paper, { elevation: 4, className: "flex items-center py-4 px-6 fixed right-4 group", style: {
                        zIndex: 9999,
                        bottom: 32 + index * 48,
                        borderRadius: "8px",
                        border: "2px solid ".concat(item.type === "ERROR" ? DANGER.DEFAULT : "#e4e5e6"),
                    }, children: [item.type === "SUCCESS" && (_jsx(FontAwesomeIcon, { size: "lg", className: "mr-4", icon: "circle-check", color: SUCCESS.DEFAULT })), item.type === "ERROR" && (_jsx(FontAwesomeIcon, { size: "lg", className: "mr-4", icon: "circle-xmark", color: DANGER.DEFAULT })), item.type === "PENDING" && _jsx(CircularProgress, { size: "1.5rem", className: "mr-4" }), _jsx("div", { className: "invisible group-hover:visible", children: _jsx(IconButton, { style: {
                                    height: "30px",
                                    width: "30px",
                                    border: "2px solid #e4e5e6",
                                    color: "black",
                                    backgroundColor: "white",
                                    position: "absolute",
                                    top: "-15px",
                                    right: "-16px",
                                    paddingLeft: "5px",
                                }, onClick: function () { return handleCloseNotification(item.id); }, size: "small", children: _jsx(FontAwesomeIcon, { icon: "xmark" }) }) }), _jsx(Typography, { color: "black", variant: "body1", className: "pt-1", children: item.message }), item.details && (_jsx("pre", { className: "whitespace-pre-wrap", children: JSON.stringify(item.details) }))] }) }, item.id)); }) }));
}
