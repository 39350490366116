import { jsx as _jsx } from "react/jsx-runtime";
import clsx from "clsx";
var buttonColorDict = {
    SUCCESS: "text-white bg-success border-success hover:bg-white hover:text-success-400 active:bg-white-900",
    DANGER: "text-white bg-danger border-danger hover:bg-white hover:text-danger-400 active:bg-white-900",
    WARNING: "text-white bg-orange-400 border-orange-400 hover:bg-white hover:text-orange-400 active:bg-white-900",
    PRIMARY: "text-white bg-prighterblue border-prighterblue hover:bg-white hover:text-prighterblue active:bg-white-900",
    NONE: "bg-transparent text-prighterblue border-0 shadow-none",
};
/*
 * This is a link element that looks like a button. It adds middle click functionality.
 */
export default function LinkButton(props) {
    var className = props.className, href = props.href, children = props.children, _a = props.color, color = _a === void 0 ? "PRIMARY" : _a, _b = props.fullWidth, fullWidth = _b === void 0 ? false : _b;
    return (_jsx("a", { href: href, className: clsx(className, buttonColorDict[color], "text-center p-2 font-semibold no-underline border border-solid rounded-md shadow cursor-pointer", { "inline-block w-full": fullWidth }), children: children }));
}
