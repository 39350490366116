export default function CaseState(props) {
  const { state } = props;

  const stateColor = () => {
    const stateDict = {
      closed: "success",
      processing: "orange",
      check_responsibility: "warning",
    };

    let color = "turquoise";
    if (state in stateDict) {
      color = stateDict[state];
    }
    return color;
  };

  return (
    <div className={`py-2 px-4 text-sm rounded-full capitalize bg-${stateColor()}-400`}>
      {state}
    </div>
  );
}
