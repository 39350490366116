import { jsx as _jsx } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FileDownloadWithIcon } from "components";
import { getBeautyDate, getFileIcon, getFileName } from "helpers";
import { generateUrl } from "helpers/case";
import HistoryEntryTemplate from "pages/Client/History/HistoryTemplates/HistoryEntryTemplate";
export default function FileAdded(props) {
    var entityData = props.entityData, createdAt = props.createdAt, caseUuid = props.caseUuid;
    return (_jsx(HistoryEntryTemplate, { timeLineIcon: _jsx(FontAwesomeIcon, { icon: "file", color: "white", size: "lg" }), title: entityData.title, date: getBeautyDate(createdAt), processedAt: entityData.processed_at, children: _jsx("div", { className: "flex flex-col p-4 space-y-2", children: _jsx("div", { children: entityData.files.map(function (file) { return (_jsx(FileDownloadWithIcon, { icon: getFileIcon(file.mime_type), children: _jsx("a", { href: generateUrl(caseUuid, file.uuid), children: getFileName(file) }, file.uuid) }, file.uuid)); }) }) }) }));
}
