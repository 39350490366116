import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { ProgressBar } from "components";
import { redirectToFlaskUrl } from "helpers";
export default function Redirect() {
    useEffect(function () {
        document.title = "Redirecting...";
        var timeoutId = setTimeout(function () {
            redirectToFlaskUrl("/logout");
        }, 500);
        return function () {
            clearTimeout(timeoutId);
        };
    }, []);
    return (_jsx("div", { children: _jsx(ProgressBar, {}) }));
}
