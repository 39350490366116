import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@mui/material";
import { T } from "@tolgee/react";
import { BaseButton } from "components/Button";
import { SelectField } from "components/Input";
import tolgee from "services/translation";
import { DefaultPointType } from "types/ccpaSettings";
export default function DefaultDataPoints(_a) {
    var defaultPointsData = _a.defaultPointsData, defaultPointsOptions = _a.defaultPointsOptions, handleSelection = _a.handleSelection, handleDeletion = _a.handleDeletion, addAdditionalPoint = _a.addAdditionalPoint;
    var getFilteredItems = function (selectedItem) {
        return defaultPointsOptions.filter(function (item) { return !item.selected || item.value === selectedItem.value; });
    };
    var getAdditionalFilteredItems = function (selectedItem) {
        var inputStrict = defaultPointsData.find(function (item) { return item.type === DefaultPointType.required_inputs_strict; });
        // Filter items which are NOT selected + INCLUDE strictInput + INCLUDE currently selected item
        return defaultPointsOptions.filter(function (item) {
            return !item.selected || item.value === selectedItem.value || inputStrict.value === item.value;
        });
    };
    return (_jsx(_Fragment, { children: _jsxs("div", { children: [_jsxs("div", { className: "box-dotted mb-4", children: [_jsx("h3", { className: "mb-2 mt-0", children: _jsx(T, { keyName: "dsr.settings.ccpa.default_data_title" }) }), _jsx("div", { children: _jsx(T, { keyName: "dsr.settings.ccpa.default_data_description" }) }), _jsx("hr", { className: "h-px my-4 bg-gray-200 border-0" }), defaultPointsData
                            .filter(function (item) { return item.type === DefaultPointType.required_inputs; })
                            .map(function (item) { return (_jsx("div", { className: "my-4", children: _jsx(SelectField, { label: item.value || tolgee.t("dsr.settings.label_select"), value: item.value, options: getFilteredItems(item).map(function (filteredItem) { return filteredItem.value; }), onChange: function (value) { return handleSelection(item, value); } }) }, item.id)); }), _jsx("hr", { className: "h-px my-4 bg-gray-200 border-0" }), _jsx("div", { className: "mb-4", children: _jsx(T, { keyName: "dsr.settings.ccpa.strict_input_description" }) }), defaultPointsData
                            .filter(function (item) { return item.type === DefaultPointType.required_inputs_strict; })
                            .map(function (item) { return (_jsx("div", { className: "my-2", children: _jsx(SelectField, { label: item.value || tolgee.t("dsr.settings.label_select"), value: item.value, options: getFilteredItems(item).map(function (filteredItem) { return filteredItem.value; }), onChange: function (value) { return handleSelection(item, value); } }) }, item.id)); })] }), _jsxs("div", { className: "box-dotted", children: [_jsx("h3", { className: "mb-2 mt-0", children: _jsx(T, { keyName: "dsr.settings.ccpa.additional_input_title" }) }), _jsx("div", { children: _jsx(T, { keyName: "dsr.settings.ccpa.additional_input_description" }) }), _jsx("hr", { className: "h-px my-4 bg-gray-200 border-0" }), defaultPointsData
                            .filter(function (item) { return item.type === DefaultPointType.additional_inputs; })
                            .map(function (item) { return (_jsxs("div", { className: "my-4 flex fle-row items-start justify-between gap-2", children: [_jsx("div", { className: "flex grow", children: _jsx(SelectField, { label: item.value || tolgee.t("dsr.settings.label_select"), value: item.value, options: getAdditionalFilteredItems(item).map(function (filteredItem) { return filteredItem.value; }), onChange: function (value) { return handleSelection(item, value); } }) }), _jsx("div", { className: "flex py-1 border rounded-md hover:bg-slate-100 border-slate-300 bg-slate-50", children: _jsxs(IconButton, { size: "large", onClick: function () { return handleDeletion(item); }, children: [_jsx(FontAwesomeIcon, { icon: "trash", className: " text-brand-600", size: "xs" }), " "] }) })] }, item.id)); }), _jsx("div", { className: "w-full flex align-center justify-center", children: _jsx(BaseButton, { className: "w-auto", variant: "outlined", onClick: addAdditionalPoint, children: _jsx(T, { keyName: "dsr.settings.ccpa.add_additional_data_point" }) }) })] })] }) }));
}
