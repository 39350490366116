import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import tolgee from "services/translation";
import { useCase } from "pages/Client/Case/CaseContext";
import { getOpenInquiries } from "helpers/case";
import OpenInquiriesDataField from "pages/Client/Case/DSR/ClientView/Components/OpenInquiries/DataField";
export default function OpenInquiries() {
    var state = useCase()[0];
    var openInquiries = getOpenInquiries(state.case.inquiries);
    var otherInfo = openInquiries === null || openInquiries === void 0 ? void 0 : openInquiries.map(function (i) { return ({
        key: (i === null || i === void 0 ? void 0 : i.question_i18n)
            ? tolgee.t({
                key: "dsr.inquiry.".concat(i === null || i === void 0 ? void 0 : i.question_i18n.key, "_title"),
            })
            : i.question,
        upload: i === null || i === void 0 ? void 0 : i.upload_required,
    }); });
    return (openInquiries.length > 0 && (_jsxs("div", { className: "pt-5 mb-4 box-outerlayout", "data-testid": "dsr-info", children: [_jsx("div", { className: "flex items-center", children: _jsxs("h2", { children: [tolgee.t({
                            key: "dsr.open_inq",
                        }), " ", "(", openInquiries === null || openInquiries === void 0 ? void 0 : openInquiries.length, ")"] }) }), _jsx("div", { className: "grid px-2 -mx-2 divide-y divide-gray-200 rounded-lg ", children: _jsx("div", { className: "@xl:flex grid @xl:flex-wrap  gap-4", children: otherInfo === null || otherInfo === void 0 ? void 0 : otherInfo.map(function (i) { return (_jsx("div", { className: "px-4 border rounded-lg ", children: _jsx(OpenInquiriesDataField, { label: i.key, upload: i.upload }, i.key) }, i.key)); }) }) })] })));
}
