import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { syncPreferences } from "store/app";
export default function SessionService() {
    var dispatch = useAppDispatch();
    var preferences = useAppSelector(function (state) { return state.app.preferences; });
    var isFirstRender = useRef(true);
    useEffect(function () {
        if (isFirstRender.current) {
            var storedPreferences = sessionStorage.getItem("preferences");
            if (storedPreferences) {
                dispatch(syncPreferences(JSON.parse(storedPreferences)));
            }
            isFirstRender.current = false;
        }
        else {
            var session = JSON.stringify(preferences);
            sessionStorage.setItem("preferences", session);
            sessionStorage.setItem("navOpen", JSON.stringify(preferences.navbarState));
        }
    }, [dispatch, preferences]);
    return _jsx(_Fragment, {});
}
