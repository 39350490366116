import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
function NumberInputField(_a) {
    var value = _a.value, _b = _a.onChange, onChange = _b === void 0 ? function () { } : _b, required = _a.required;
    var _c = useState(value ? "".concat(Math.floor(value)) : "0"), main = _c[0], setMain = _c[1];
    var _d = useState(value ? "".concat(value % 100) : "00"), cents = _d[0], setCents = _d[1];
    useEffect(function () {
        onChange(Number.parseInt("".concat(main || 0).concat(cents), 10));
    }, [main, cents]);
    var handleMainChange = function (event) {
        var mainValue = event.target.value;
        if (!/^[-]?[0-9]*$/.test(mainValue)) {
            return;
        }
        setMain(mainValue);
    };
    var handleCentsChange = function (event) {
        var centsValue = event.target.value;
        if (!/^[0-9]*$/.test(centsValue) || centsValue.length > 2) {
            return;
        }
        setCents(event.target.value);
    };
    var handleMainBlur = function () {
        if (!main) {
            setMain("0");
        }
    };
    var handleCentBlur = function () {
        var correctedValue = cents;
        if (correctedValue.length > 2) {
            correctedValue = correctedValue.substring(0, 2);
        }
        else if (correctedValue.length < 2) {
            correctedValue = correctedValue.padEnd(2, "0");
        }
        setCents(correctedValue);
    };
    return (_jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: 0.5 }, children: [_jsx(TextField, { label: "Amount", value: main, onChange: handleMainChange, onBlur: handleMainBlur, required: required, "data-testid": "input-amount" }), _jsx("span", { children: "," }), _jsx(TextField, { label: "Cent", value: cents, onBlur: handleCentBlur, onChange: handleCentsChange, sx: { width: "50px" } })] }));
}
export default NumberInputField;
