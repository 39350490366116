var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Fragment, useEffect } from "react";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BaseTextField } from "components/Input";
import { PRIGHTER_BLUE } from "const/color";
import tolgee from "services/translation";
export default function AuthorityContactPointList(_a) {
    var _b = _a.contactPoints, contactPoints = _b === void 0 ? [] : _b, onChange = _a.onChange;
    // TODO: Find a way to separate lists, so you can handle the state more performant (and you can use index)
    useEffect(function () {
        var emailList = contactPoints.filter(function (cp) { return cp.type === "EMAIL"; });
        var phoneList = contactPoints.filter(function (cp) { return cp.type === "PHONE"; });
        var webList = contactPoints.filter(function (cp) { return cp.type === "WEB"; });
        if (!emailList.length) {
            handleIncrease("EMAIL");
        }
        if (!phoneList.length) {
            handleIncrease("PHONE");
        }
        if (!webList.length) {
            handleIncrease("WEB");
        }
    }, [contactPoints]);
    var handleChange = function (contactObj, newValue) {
        var objInd = contactPoints.findIndex(function (cp) { return cp === contactObj; });
        var tempList = __spreadArray([], contactPoints, true);
        tempList[objInd].value = newValue;
        onChange(tempList);
    };
    var handleIncrease = function (type) {
        onChange(__spreadArray(__spreadArray([], contactPoints, true), [{ type: type, value: "" }], false));
    };
    var handleRemove = function (contactObj) {
        var objInd = contactPoints.findIndex(function (cp) { return cp === contactObj; });
        var tempList = __spreadArray([], contactPoints, true);
        tempList.splice(objInd, 1);
        onChange(__spreadArray([], tempList, true));
    };
    var getEndingIcon = function (contactObj, isLastItem) {
        var testId;
        if (contactObj.type === "EMAIL") {
            testId = "add-email-button";
        }
        else if (contactObj.type === "PHONE") {
            testId = "add-phone-button";
        }
        else {
            testId = "add-web-button";
        }
        if (isLastItem) {
            return (_jsx(IconButton, { "data-testid": testId, onClick: function () { return handleIncrease(contactObj.type); }, size: "medium", children: _jsx(FontAwesomeIcon, { icon: "plus", size: "sm" }) }));
        }
        return (_jsx(IconButton, { "data-testid": "remove-contacts-button", onClick: function () { return handleRemove(contactObj); }, size: "medium", children: _jsx(FontAwesomeIcon, { icon: "trash", color: "red", size: "sm" }) }));
    };
    return (_jsxs(_Fragment, { children: [contactPoints
                .filter(function (cp) { return cp.type === "PHONE"; })
                .map(function (contactPoint, ind) { return (_jsxs(Fragment, { children: [ind === 0 ? _jsx(FontAwesomeIcon, { icon: "phone", color: PRIGHTER_BLUE }) : _jsx("div", {}), _jsx(BaseTextField, { value: contactPoint.value, onChange: function (event) { return handleChange(contactPoint, event.target.value); }, label: ind === 0 &&
                            tolgee.t({
                                key: "contacts.phone",
                            }), containerClassName: "w-full" }), getEndingIcon(contactPoint, ind + 1 === contactPoints.filter(function (cp) { return cp.type === "PHONE"; }).length)] }, "".concat(contactPoint.uuid || ind, "-phone"))); }), contactPoints
                .filter(function (cp) { return cp.type === "EMAIL"; })
                .map(function (contactPoint, ind) { return (_jsxs(Fragment, { children: [ind === 0 ? _jsx(FontAwesomeIcon, { icon: "envelope", color: PRIGHTER_BLUE }) : _jsx("div", {}), _jsx(BaseTextField, { testid: "email-block", value: contactPoint.value, onChange: function (event) { return handleChange(contactPoint, event.target.value); }, label: ind === 0 &&
                            tolgee.t({
                                key: "contacts.email",
                            }), containerClassName: "w-full" }), getEndingIcon(contactPoint, ind + 1 === contactPoints.filter(function (cp) { return cp.type === "EMAIL"; }).length)] }, "".concat(contactPoint.uuid || ind, "-email"))); }), contactPoints
                .filter(function (cp) { return cp.type === "WEB"; })
                .map(function (contactPoint, ind) { return (_jsxs(Fragment, { children: [ind === 0 ? _jsx(FontAwesomeIcon, { icon: "wifi", color: PRIGHTER_BLUE }) : _jsx("div", {}), _jsx(BaseTextField, { value: contactPoint.value, onChange: function (event) { return handleChange(contactPoint, event.target.value); }, label: ind === 0 &&
                            tolgee.t({
                                key: "contacts.web",
                            }), containerClassName: "w-full" }), getEndingIcon(contactPoint, ind + 1 === contactPoints.filter(function (cp) { return cp.type === "WEB"; }).length)] }, "".concat(contactPoint.uuid || ind, "-web"))); })] }));
}
