import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { T } from "@tolgee/react";
import { getBeautyDate } from "helpers";
import HistoryEntryTemplate from "pages/Client/History/HistoryTemplates/HistoryEntryTemplate";
import tolgee from "services/translation";
export default function StageChanged(props) {
    var entityData = props.entityData, createdAt = props.createdAt;
    return (_jsx(HistoryEntryTemplate, { title: _jsxs(_Fragment, { children: [tolgee.t({
                    key: "dsr.case_on",
                }), _jsxs("span", { className: "capitalize", children: ["\"", entityData.new, "\""] })] }), date: getBeautyDate(createdAt), processedAt: entityData.processed_at, children: _jsxs("div", { className: "flex flex-col p-4 space-y-2", children: [_jsxs("span", { children: [_jsx("b", { children: _jsx(T, { keyName: "dsr.new_stage" }) }), " ", _jsx("span", { className: "capitalize", children: entityData.new })] }), _jsxs("span", { children: [_jsx("b", { children: _jsx(T, { keyName: "dsr.old_stage" }) }), " ", _jsx("span", { className: "capitalize", children: entityData.old })] })] }) }));
}
