import { REACT_BASE_ROUTE } from "const/env";
import tolgee from "services/translation";
export var defaultDataPoints = [
    "name",
    "email",
    "address",
    "phone",
    "customer_number",
    "purchase_number",
    "pseudonym",
    "other",
];
export var sharedSoldRecipientList = [
    "category_recipient_shared_sold.1",
    "category_recipient_shared_sold.2",
    "category_recipient_shared_sold.3",
    "category_recipient_shared_sold.4",
    "category_recipient_shared_sold.5",
    "category_recipient_shared_sold.6",
    "category_recipient_shared_sold.7",
    "category_recipient_shared_sold.8",
    "category_recipient_shared_sold.9",
    "category_recipient_shared_sold.10",
    "category_recipient_shared_sold.11",
    "category_recipient_shared_sold.12",
    "category_recipient_shared_sold.13",
];
export var disclosedRecipientList = [
    "category_recipient_disclosed.1",
    "category_recipient_disclosed.2",
    "category_recipient_disclosed.3",
    "category_recipient_disclosed.4",
    "category_recipient_disclosed.5",
    "category_recipient_disclosed.6",
    "category_recipient_disclosed.7",
    "category_recipient_disclosed.8",
    "category_recipient_disclosed.9",
    "category_recipient_disclosed.10",
    "category_recipient_disclosed.11",
    "category_recipient_disclosed.12",
    "category_recipient_disclosed.13",
];
export var categoriesCollectedData = [
    "categories_of_personal_info_list.1",
    "categories_of_personal_info_list.2",
    "categories_of_personal_info_list.3",
    "categories_of_personal_info_list.4",
    "categories_of_personal_info_list.5",
    "categories_of_personal_info_list.6",
    "categories_of_personal_info_list.7",
    "categories_of_personal_info_list.8",
    "categories_of_personal_info_list.9",
    "categories_of_personal_info_list.10",
    "categories_of_personal_info_list.11",
    "categories_of_personal_info_list.12",
    "categories_of_personal_info_list.13",
    "categories_of_personal_info_list.14",
    "categories_of_personal_info_list.15",
    "categories_of_personal_info_list.16",
    "categories_of_personal_info_list.17",
    "categories_of_personal_info_list.18",
    "categories_of_personal_info_list.19",
    "categories_of_personal_info_list.20",
];
export var sharedSoldData = [
    {
        dataToSell: "categories_of_personal_info_list.1",
        sellOptions: sharedSoldRecipientList,
    },
    {
        dataToSell: "categories_of_personal_info_list.2",
        sellOptions: sharedSoldRecipientList,
    },
    {
        dataToSell: "categories_of_personal_info_list.3",
        sellOptions: sharedSoldRecipientList,
    },
    {
        dataToSell: "categories_of_personal_info_list.4",
        sellOptions: sharedSoldRecipientList,
    },
    {
        dataToSell: "categories_of_personal_info_list.5",
        sellOptions: sharedSoldRecipientList,
    },
    {
        dataToSell: "categories_of_personal_info_list.6",
        sellOptions: sharedSoldRecipientList,
    },
    {
        dataToSell: "categories_of_personal_info_list.7",
        sellOptions: sharedSoldRecipientList,
    },
    {
        dataToSell: "categories_of_personal_info_list.8",
        sellOptions: sharedSoldRecipientList,
    },
    {
        dataToSell: "categories_of_personal_info_list.9",
        sellOptions: sharedSoldRecipientList,
    },
    {
        dataToSell: "categories_of_personal_info_list.10",
        sellOptions: sharedSoldRecipientList,
    },
    {
        dataToSell: "categories_of_personal_info_list.11",
        sellOptions: sharedSoldRecipientList,
    },
    {
        dataToSell: "categories_of_personal_info_list.12",
        sellOptions: sharedSoldRecipientList,
    },
    {
        dataToSell: "categories_of_personal_info_list.13",
        sellOptions: sharedSoldRecipientList,
    },
    {
        dataToSell: "categories_of_personal_info_list.14",
        sellOptions: sharedSoldRecipientList,
    },
    {
        dataToSell: "categories_of_personal_info_list.15",
        sellOptions: sharedSoldRecipientList,
    },
    {
        dataToSell: "categories_of_personal_info_list.16",
        sellOptions: sharedSoldRecipientList,
    },
    {
        dataToSell: "categories_of_personal_info_list.17",
        sellOptions: sharedSoldRecipientList,
    },
    {
        dataToSell: "categories_of_personal_info_list.18",
        sellOptions: sharedSoldRecipientList,
    },
    {
        dataToSell: "categories_of_personal_info_list.19",
        sellOptions: sharedSoldRecipientList,
    },
    {
        dataToSell: "categories_of_personal_info_list.20",
        sellOptions: sharedSoldRecipientList,
    },
];
export var disclosedData = [
    {
        dataToSell: "categories_of_personal_info_list.1",
        sellOptions: disclosedRecipientList,
    },
    {
        dataToSell: "categories_of_personal_info_list.2",
        sellOptions: disclosedRecipientList,
    },
    {
        dataToSell: "categories_of_personal_info_list.3",
        sellOptions: disclosedRecipientList,
    },
    {
        dataToSell: "categories_of_personal_info_list.4",
        sellOptions: disclosedRecipientList,
    },
    {
        dataToSell: "categories_of_personal_info_list.5",
        sellOptions: disclosedRecipientList,
    },
    {
        dataToSell: "categories_of_personal_info_list.6",
        sellOptions: disclosedRecipientList,
    },
    {
        dataToSell: "categories_of_personal_info_list.7",
        sellOptions: disclosedRecipientList,
    },
    {
        dataToSell: "categories_of_personal_info_list.8",
        sellOptions: disclosedRecipientList,
    },
    {
        dataToSell: "categories_of_personal_info_list.9",
        sellOptions: disclosedRecipientList,
    },
    {
        dataToSell: "categories_of_personal_info_list.10",
        sellOptions: disclosedRecipientList,
    },
    {
        dataToSell: "categories_of_personal_info_list.11",
        sellOptions: disclosedRecipientList,
    },
    {
        dataToSell: "categories_of_personal_info_list.12",
        sellOptions: disclosedRecipientList,
    },
    {
        dataToSell: "categories_of_personal_info_list.13",
        sellOptions: disclosedRecipientList,
    },
    {
        dataToSell: "categories_of_personal_info_list.14",
        sellOptions: disclosedRecipientList,
    },
    {
        dataToSell: "categories_of_personal_info_list.15",
        sellOptions: disclosedRecipientList,
    },
    {
        dataToSell: "categories_of_personal_info_list.16",
        sellOptions: disclosedRecipientList,
    },
    {
        dataToSell: "categories_of_personal_info_list.17",
        sellOptions: disclosedRecipientList,
    },
    {
        dataToSell: "categories_of_personal_info_list.18",
        sellOptions: disclosedRecipientList,
    },
    {
        dataToSell: "categories_of_personal_info_list.19",
        sellOptions: disclosedRecipientList,
    },
    {
        dataToSell: "categories_of_personal_info_list.20",
        sellOptions: disclosedRecipientList,
    },
];
export var ccpaPrivacyPolicySnippet = function (url) {
    return "<a href=\"".concat(url, "\">\n    <svg role=\"img\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 30 14\" xml:space=\"preserve\" height=\"16\" width=\"43\">\n      <path d=\"M7.4 12.8h6.8l3.1-11.6H7.4C4.2 1.2 1.6 3.8 1.6 7s2.6 5.8 5.8 5.8z\"\n        style=\"fill-rule:evenodd;clip-rule:evenodd;fill:#fff\"></path>\n      <path\n        d=\"M22.6 0H7.4c-3.9 0-7 3.1-7 7s3.1 7 7 7h15.2c3.9 0 7-3.1 7-7s-3.2-7-7-7zm-21 7c0-3.2 2.6-5.8 5.8-5.8h9.9l-3.1 11.6H7.4c-3.2 0-5.8-2.6-5.8-5.8z\"\n        style=\"fill-rule:evenodd;clip-rule:evenodd;fill:#06f\"></path>\n      <path\n        d=\"M24.6 4c.2.2.2.6 0 .8L22.5 7l2.2 2.2c.2.2.2.6 0 .8-.2.2-.6.2-.8 0l-2.2-2.2-2.2 2.2c-.2.2-.6.2-.8 0-.2-.2-.2-.6 0-.8L20.8 7l-2.2-2.2c-.2-.2-.2-.6 0-.8.2-.2.6-.2.8 0l2.2 2.2L23.8 4c.2-.2.6-.2.8 0z\"\n        style=\"fill:#fff\"></path>\n      <path\n        d=\"M12.7 4.1c.2.2.3.6.1.8L8.6 9.8c-.1.1-.2.2-.3.2-.2.1-.5.1-.7-.1L5.4 7.7c-.2-.2-.2-.6 0-.8.2-.2.6-.2.8 0L8 8.6l3.8-4.5c.2-.2.6-.2.9 0z\"\n        style=\"fill:#06f\"></path>\n    </svg>\n    <span>").concat(tolgee.t({ key: "ccpa.your_privacy_options" }), "</span>\n  </a>");
};
export var optOutLink = function (businessId) {
    return "".concat(REACT_BASE_ROUTE, "/dsr/").concat(businessId, "/ccpa?type=opt-out");
};
