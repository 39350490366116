import { jsx as _jsx } from "react/jsx-runtime";
import Forbidden from "pages/General/Error/Forbidden";
import { useAppSelector } from "store";
export default function ProtectedElement(props) {
    var roles = useAppSelector(function (state) { return state.user; }).roles;
    var Component = props.Component, allowedRoles = props.allowedRoles;
    if (roles.some(function (role) { return allowedRoles.includes(role.name); })) {
        return _jsx(Component, {});
    }
    return _jsx(Forbidden, {});
}
