import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from "react";
import { BoldItalicUnderlineToggles, MDXEditor, UndoRedo, frontmatterPlugin, headingsPlugin, linkPlugin, listsPlugin, markdownShortcutPlugin, quotePlugin, tablePlugin, toolbarPlugin, } from "@mdxeditor/editor";
// eslint-disable-next-line import/no-unresolved
import "@mdxeditor/editor/style.css";
function ToolbarContents() {
    return (_jsxs(_Fragment, { children: [_jsx(UndoRedo, {}), _jsx(BoldItalicUnderlineToggles, {})] }));
}
export default function MarkdownEditor(props) {
    var value = props.value, onChange = props.onChange, readOnly = props.readOnly;
    var ref = React.useRef(null);
    var hasRun = React.useRef(false);
    useEffect(function () {
        if (value && !hasRun.current) {
            ref.current.setMarkdown(value);
            hasRun.current = true;
        }
    }, [value]);
    return (_jsx(MDXEditor, { readOnly: readOnly, ref: ref, markdown: value, onChange: onChange, plugins: [
            frontmatterPlugin(),
            listsPlugin(),
            linkPlugin(),
            tablePlugin(),
            quotePlugin(),
            headingsPlugin(),
            toolbarPlugin({
                toolbarContents: ToolbarContents,
            }),
            markdownShortcutPlugin(),
        ], contentEditableClassName: "prose", className: "min-h-[6rem] border-x-2 border-b-2 rounded-lg border-x-gray-200" }));
}
