import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { getBeautyDate } from "helpers";
import HistoryEntryTemplate from "pages/Client/History/HistoryTemplates/HistoryEntryTemplate";
export default function DefaultEntry(props) {
    var entityData = props.entityData, createdAt = props.createdAt;
    var beautifyTitle = function (title) {
        var stringifiedTitle = "".concat(title);
        return stringifiedTitle
            .split("_")
            .map(function (word) { return word[0].toUpperCase() + word.substring(1); })
            .join(" ");
    };
    return (_jsx(HistoryEntryTemplate, { title: entityData === null || entityData === void 0 ? void 0 : entityData.title, date: getBeautyDate(createdAt), processedAt: entityData.processed_at, children: _jsx("div", { className: "p-4 flex flex-col space-y-2", children: Object.entries(entityData)
                .filter(function (e) { return !["processed_at", "title"].includes(e[0]); })
                .map(function (_a) {
                var entityKey = _a[0], entityVal = _a[1];
                return (_jsxs("span", { children: [_jsxs("b", { children: [beautifyTitle(entityKey), ":"] }), typeof entityVal !== "object" ? entityVal : "[Object]"] }, entityKey));
            }) }) }));
}
