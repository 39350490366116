import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@tolgee/react";
import TimeLineDot from "components/PageSpecific/Case/TimeLineDot";
import { getBeautyDate, getFileIcon, getFileName } from "helpers";
import HistoryEntryTemplate from "pages/Client/History/HistoryTemplates/HistoryEntryTemplate";
import { FileDownloadWithIcon } from "components";
import { generateUrl } from "helpers/case";
export default function EmailReceived(props) {
    var _a, _b, _c, _d;
    var entityData = props.entityData, createdAt = props.createdAt, caseUuid = props.caseUuid;
    var from;
    if (typeof entityData.sender === "string") {
        from = entityData.sender;
    }
    else {
        from = "".concat((_a = entityData === null || entityData === void 0 ? void 0 : entityData.sender) === null || _a === void 0 ? void 0 : _a.name, " <").concat((_b = entityData === null || entityData === void 0 ? void 0 : entityData.sender) === null || _b === void 0 ? void 0 : _b.email, ">");
    }
    return (_jsx(HistoryEntryTemplate, { timeLineIcon: _jsx(FontAwesomeIcon, { icon: "building", color: "white", size: "lg" }), title: entityData.title ? entityData.title : "NO TITLE", date: getBeautyDate(createdAt), processedAt: entityData.processed_at, children: _jsxs("div", { className: "w-full p-0 my-4 secondarybox-outerlayout", children: [_jsxs("div", { className: "flex items-center w-full h-12 pl-4 font-bold bg-gray-200", children: ["From ", _jsx("em", { className: "ml-1 text-center", children: from })] }), _jsxs("div", { className: "flex flex-row justify-between py-4", style: { paddingLeft: "40px" }, children: [_jsxs("div", { className: "flex flex-row", children: [_jsx(TimeLineDot, { additionalStyle: { margin: 0 }, children: _jsx(FontAwesomeIcon, { icon: "building", color: "gray" }) }), _jsxs("div", { className: "flex flex-col space-y-4", style: { paddingLeft: "20px" }, children: [_jsx("b", { children: entityData.subject }), _jsx("div", { className: "whitespace-pre-line", style: { maxWidth: "50rem" }, children: entityData.text })] })] }), _jsxs("div", { className: "flex flex-col pr-2", children: [((_c = entityData.files) === null || _c === void 0 ? void 0 : _c.length) > 0 && (_jsxs(_Fragment, { children: [_jsxs("b", { children: [_jsx(T, { keyName: "dsr.files" }), ":"] }), entityData.files.map(function (file) { return (_jsx(FileDownloadWithIcon, { icon: getFileIcon(file.mime_type), children: _jsx("a", { href: generateUrl(caseUuid, file.uuid), children: getFileName(file) }) }, file.uuid)); })] })), _jsxs("b", { children: [_jsx(T, { keyName: "dsr.recipients" }), ":"] }), _jsxs("em", { children: [(_d = entityData === null || entityData === void 0 ? void 0 : entityData.recipients) === null || _d === void 0 ? void 0 : _d.map(function (recipient) { return recipient; }), entityData === null || entityData === void 0 ? void 0 : entityData.recipient] })] })] })] }) }));
}
