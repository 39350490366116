import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Skeleton } from "@mui/material";
import PreCheck from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/Stages/PreCheck";
import PreCheckReview from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/Stages/PreCheckReview";
import Handling from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/Stages/Handling";
import HandlingReview from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/Stages/HandlingReview";
import { useCase } from "pages/Client/Case/CaseContext";
import CheckSteps from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/CheckSteps";
export default function DSRFlow() {
    var state = useCase()[0];
    return (_jsxs("div", { className: ["PRE_CHECK", "PRE_CHECK_REVIEW", "HANDLING", "HANDLING_REVIEW"].includes(state.ui.stage) &&
            "mb-4 !p-0 !py-4 box-outerlayout", children: [!state.initialLoading ? (_jsxs(_Fragment, { children: [state.ui.stage === "PRE_CHECK" && _jsx(PreCheck, {}), state.ui.stage === "PRE_CHECK_REVIEW" && _jsx(PreCheckReview, {}), state.ui.stage === "HANDLING" && _jsx(Handling, {}), state.ui.stage === "HANDLING_REVIEW" && _jsx(HandlingReview, {}), " "] })) : (_jsxs("div", { className: "grid gap-1 px-6 mb-3", children: [_jsx(Skeleton, { width: "25%", height: 28 }), _jsx(Skeleton, { width: "55%", height: 28 })] })), !state.initialLoading ? (["PRE_CHECK", "HANDLING"].includes(state.ui.stage) && _jsx(CheckSteps, {})) : (_jsxs("div", { className: "space-y-2", children: [_jsx(Skeleton, { variant: "rectangular", height: 36 }), _jsxs("div", { className: "px-8 space-y-2", children: [_jsx(Skeleton, { width: "20%", height: 32 }), _jsx(Skeleton, { variant: "rectangular", height: 120, width: "90%" }), _jsx("div", { className: "pt-4" }), _jsx(Skeleton, { variant: "rectangular", height: 28 })] }), _jsxs("div", { className: "flex justify-end space-x-2 pt-4 pr-8", children: [_jsx(Skeleton, { variant: "rectangular", height: 40, width: "13%" }), _jsx(Skeleton, { variant: "rectangular", height: 40, width: "11%" })] })] }))] }));
}
