var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox, IconButton, TextField } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { T } from "@tolgee/react";
import { useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RightCheckbox from "pages/Public/Dsr/Ccpa/Step1/RightCheckbox";
import tolgee from "services/translation";
export default function RightsForm() {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var _k = useFormContext(), register = _k.register, watch = _k.watch, setValue = _k.setValue, errors = _k.formState.errors;
    var searchParams = useSearchParams()[0];
    var rights = ((_a = searchParams.get("type")) === null || _a === void 0 ? void 0 : _a.split(",")) || [];
    var correctionData = watch("dsrs.2.extra.correct");
    var values = watch();
    var uncheckAccess = function () {
        setValue("dsrs.1.inquiries.0.checked", false);
        setValue("dsrs.1.inquiries.1.checked", false);
    };
    var checkValues = function (value, index) {
        if (value === false) {
            setValue("dsrs.".concat(index, ".checked"), false);
            if (index === 1) {
                uncheckAccess();
            }
        }
        else if (index === 0) {
            setValue("dsrs.0.checked", true);
            setValue("dsrs.1.checked", false);
            uncheckAccess();
            setValue("dsrs.2.checked", false);
            setValue("dsrs.3.checked", false);
            setValue("dsrs.4.checked", false);
        }
        else if (index === 1 || index === 2) {
            setValue("dsrs.0.checked", false);
            setValue("dsrs.3.checked", false);
            setValue("dsrs.4.checked", false);
            setValue("dsrs.".concat(index, ".checked"), true);
        }
        else {
            setValue("dsrs.0.checked", false);
            setValue("dsrs.1.checked", false);
            uncheckAccess();
            setValue("dsrs.2.checked", false);
            setValue("dsrs.".concat(index, ".checked"), true);
        }
    };
    var addCorrectionData = function () {
        var newCorrectionData = correctionData
            ? __spreadArray(__spreadArray([], correctionData, true), [{ data: "", why: "", new: "" }], false) : [{ data: "", why: "", new: "" }];
        setValue("dsrs.2.extra.correct", newCorrectionData);
    };
    var removeCorrectionData = function (index) {
        var newCorrectionData = correctionData.filter(function (_, i) { return i !== index; });
        setValue("dsrs.2.extra.correct", newCorrectionData);
    };
    return (_jsx("div", { children: _jsxs("div", { children: [_jsx("h2", { children: _jsx(T, { keyName: "ccpa.rights_form.i_would_like_to" }) }), _jsx("hr", { className: "h-px text-gray-500 bg-gray-500 border-0 mb-2" }), (rights.length === 0 || rights.includes("deletion")) && (_jsxs(_Fragment, { children: [_jsx(RightCheckbox, { title: tolgee.t({
                                key: "ccpa.rights_form.deletion_request_title",
                            }), register: register("dsrs.0.checked"), checked: (_b = values.dsrs[0]) === null || _b === void 0 ? void 0 : _b.checked, onCheck: function () { var _a; return checkValues(!((_a = values.dsrs[0]) === null || _a === void 0 ? void 0 : _a.checked), 0); }, children: _jsx(TextField, __assign({}, register("dsrs.0.inquiries.0.answer"), { className: "w-full", label: tolgee.t({
                                    key: "ccpa.rights_form.request_refers_to_following_personal_info",
                                }), rows: 4, multiline: true, InputLabelProps: {
                                    shrink: true,
                                } })) }), _jsx("hr", { className: "h-px text-gray-500 bg-gray-500 border-0 my-1" })] })), (rights.length === 0 || rights.includes("access")) && (_jsxs(_Fragment, { children: [_jsxs(RightCheckbox, { title: tolgee.t({
                                key: "ccpa.rights_form.access_request_title",
                            }), register: register("dsrs.1.checked"), checked: (_c = values.dsrs[1]) === null || _c === void 0 ? void 0 : _c.checked, onCheck: function () { var _a; return checkValues(!((_a = values.dsrs[1]) === null || _a === void 0 ? void 0 : _a.checked), 1); }, children: [_jsxs("div", { children: [_jsx(Checkbox, __assign({}, register("dsrs.1.inquiries.0.checked"), { checked: (_d = values.dsrs[1].inquiries[0]) === null || _d === void 0 ? void 0 : _d.checked })), _jsx(T, { keyName: "ccpa.rights_form.categories" }), ":", _jsxs("div", { className: "ml-6", children: [_jsx("li", { children: _jsx(T, { keyName: "ccpa.rights_form.categories_of_personal_information_consumer" }) }), _jsx("li", { children: _jsx(T, { keyName: "ccpa.rights_form.categories_of_sources" }) }), _jsx("li", { children: _jsx(T, { keyName: "ccpa.rights_form.commertial_collection" }) }), _jsx("li", { children: _jsx(T, { keyName: "ccpa.rights_form.categories_of_third_parties" }) }), _jsx("li", { children: _jsx(T, { keyName: "ccpa.rights_form.categories_of_my_personal_info_that_sold" }) })] })] }), _jsxs("div", { className: "flex flex-col", children: [_jsxs("div", { className: "flex items-center", children: [_jsx(Checkbox, __assign({}, register("dsrs.1.inquiries.1.checked"), { checked: (_e = values.dsrs[1].inquiries[1]) === null || _e === void 0 ? void 0 : _e.checked })), _jsx(T, { keyName: "ccpa.rights_form.my_request_concerns_specific_info" })] }), ((_f = values.dsrs[1].inquiries[1]) === null || _f === void 0 ? void 0 : _f.checked) && (_jsx("div", { className: "mt-4", children: _jsx(TextField, __assign({}, register("dsrs.1.inquiries.1.answer"), { className: "w-full", label: tolgee.t({
                                                    key: "ccpa.rights_form.my_request_concerns_following_information",
                                                }), rows: 4, multiline: true, InputLabelProps: {
                                                    shrink: true,
                                                } })) }))] })] }), _jsx("hr", { className: "h-px text-gray-500 bg-gray-500 border-0 my-1" })] })), (rights.length === 0 || rights.includes("correction")) && (_jsxs(_Fragment, { children: [_jsx(RightCheckbox, { title: tolgee.t({
                                key: "ccpa.rights_form.change_request_title",
                            }), register: register("dsrs.2.checked"), checked: (_g = values.dsrs[2]) === null || _g === void 0 ? void 0 : _g.checked, onCheck: function () { var _a; return checkValues(!((_a = values.dsrs[2]) === null || _a === void 0 ? void 0 : _a.checked), 2); }, children: _jsxs("div", { className: "flex flex-col space-y-2", children: [_jsx("div", { className: "space-y-6", children: correctionData === null || correctionData === void 0 ? void 0 : correctionData.map(function (data, index) {
                                            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
                                            return (_jsxs("div", { className: "flex space-x-2", children: [_jsx(TextField, __assign({}, register("dsrs.2.extra.correct.".concat(index, ".data"), {
                                                        required: ((_a = values.dsrs[2]) === null || _a === void 0 ? void 0 : _a.checked) && index === 0,
                                                    }), { InputLabelProps: {
                                                            shrink: true,
                                                        }, className: "w-full", label: tolgee.t({
                                                            key: "ccpa.rights_form.request_related_to_following_personal_information",
                                                        }), required: index === 0, error: !!(errors.dsrs && ((_d = (_c = (_b = errors.dsrs[2]) === null || _b === void 0 ? void 0 : _b.extra) === null || _c === void 0 ? void 0 : _c.correct[index]) === null || _d === void 0 ? void 0 : _d.data)) })), _jsx(TextField, __assign({}, register("dsrs.2.extra.correct.".concat(index, ".why"), {
                                                        required: ((_e = values.dsrs[2]) === null || _e === void 0 ? void 0 : _e.checked) && index === 0,
                                                    }), { InputLabelProps: {
                                                            shrink: true,
                                                        }, className: "w-full", label: tolgee.t({
                                                            key: "ccpa.rights_form.personal_information_is_wrong_or_incomplete_because",
                                                        }), required: index === 0, error: !!(errors.dsrs && ((_h = (_g = (_f = errors.dsrs[2]) === null || _f === void 0 ? void 0 : _f.extra) === null || _g === void 0 ? void 0 : _g.correct[index]) === null || _h === void 0 ? void 0 : _h.why)) })), _jsx(TextField, __assign({}, register("dsrs.2.extra.correct.".concat(index, ".new"), {
                                                        required: ((_j = values.dsrs[2]) === null || _j === void 0 ? void 0 : _j.checked) && index === 0,
                                                    }), { InputLabelProps: {
                                                            shrink: true,
                                                        }, className: "w-full", label: tolgee.t({
                                                            key: "ccpa.rights_form.amend_the_personal_information_as_follows",
                                                        }), required: index === 0, error: !!(errors.dsrs && ((_m = (_l = (_k = errors.dsrs[2]) === null || _k === void 0 ? void 0 : _k.extra) === null || _l === void 0 ? void 0 : _l.correct[index]) === null || _m === void 0 ? void 0 : _m.new)) })), _jsx(IconButton, { size: "medium", onClick: function () { return removeCorrectionData(index); }, disabled: index === 0 && correctionData.length === 1, children: _jsx(FontAwesomeIcon, { icon: "trash", size: "xs" }) })] }, data.data));
                                        }) }), _jsx(IconButton, { size: "medium", className: "self-end", onClick: addCorrectionData, children: _jsx(FontAwesomeIcon, { icon: "plus", size: "sm" }) })] }) }), _jsx("hr", { className: "h-px text-gray-500 bg-gray-500 border-0 my-1" })] })), (rights.length === 0 || rights.includes("opt-out")) && (_jsxs(_Fragment, { children: [_jsx(RightCheckbox, { title: tolgee.t({
                                key: "ccpa.rights_form.optout_request_title",
                            }), register: register("dsrs.3.checked"), checked: (_h = values.dsrs[3]) === null || _h === void 0 ? void 0 : _h.checked, onCheck: function () { var _a; return checkValues(!((_a = values.dsrs[3]) === null || _a === void 0 ? void 0 : _a.checked), 3); } }), _jsx("hr", { className: "h-px text-gray-500 bg-gray-500 border-0 my-1" }), _jsx(RightCheckbox, { title: tolgee.t({
                                key: "ccpa.rights_form.limit_request_title",
                            }), register: register("dsrs.4.checked"), checked: (_j = values.dsrs[4]) === null || _j === void 0 ? void 0 : _j.checked, onCheck: function () { var _a; return checkValues(!((_a = values.dsrs[4]) === null || _a === void 0 ? void 0 : _a.checked), 4); } }), _jsx("hr", { className: "h-px text-gray-500 bg-gray-500 border-0 my-1" })] }))] }) }));
}
