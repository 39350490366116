export var validateEmail = function (email) {
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};
export var validateEmailDomainsSame = function (email1, email2) {
    if (!validateEmail(email1) || !validateEmail(email2)) {
        return false;
    }
    var domainRegex = /^[^\s@]+@([^\s@]+\.[^\s@]+)$/;
    var match1 = email1.match(domainRegex);
    var match2 = email2.match(domainRegex);
    return match1 && match2 && match1[1] === match2[1];
};
