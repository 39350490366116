import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Step } from "@mui/material";
import { T } from "@tolgee/react";
import { dsrFormSteps } from "const/dsr";
var icons = ["pencil", "address-card", "paper-plane"];
function ColorlibStepIcon(props) {
    var icon = props.icon;
    return _jsx("div", { children: icon });
}
export default function CustomizedStepper(_a) {
    var _b = _a.disabled, disabled = _b === void 0 ? false : _b, currentStep = _a.currentStep;
    var navigate = useNavigate();
    var publicId = useParams().publicId;
    var searchParams = useSearchParams()[0];
    var currentStepIndex = dsrFormSteps.findIndex(function (s) { return s.key === currentStep; });
    var handleChangeStep = function (chosenStep) {
        var chosenStepIndex = dsrFormSteps.findIndex(function (s) { return s.key === chosenStep.key; });
        if (chosenStepIndex < currentStepIndex) {
            var param = chosenStep.key === "type" ? "" : "/".concat(chosenStep.key);
            navigate({ pathname: "/dsr/".concat(publicId, "/ccpa").concat(param), search: searchParams.toString() });
        }
    };
    return (_jsx("div", { className: "flex", children: _jsx(Stepper, { alternativeLabel: true, activeStep: currentStepIndex, connector: _jsx(FontAwesomeIcon, { icon: "angle-right", size: "xl", className: "text-gray px-4 pt-1" }), children: dsrFormSteps.map(function (step, ind) { return (_jsx(Step, { className: "contents", children: _jsx(StepLabel, { StepIconComponent: ColorlibStepIcon, StepIconProps: {
                        icon: (_jsxs("button", { disabled: disabled, className: "flex space-x-2 items-center px-3 py-1 border-2 rounded-full ".concat(currentStep === step.key
                                ? "bg-gray-200 border-prighterblue text-prighterblue"
                                : "border-gray text-gray hover:bg-gray-200"), type: "button", onClick: function () { return handleChangeStep(step); }, children: [_jsx(FontAwesomeIcon, { className: "mb:mr-2 ".concat(currentStep === step.key ? "text-prighterblue" : "text-gray"), icon: icons[ind], size: "sm" }), _jsx("div", { className: "hidden md:flex", children: _jsx(T, { keyName: "dsr.".concat(step.name) }) })] })),
                    } }) }, step.key)); }) }) }));
}
