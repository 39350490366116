import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { useFormContext } from "react-hook-form";
import { T } from "@tolgee/react";
import RequestSelection from "pages/Client/EmailCases/Components/ExpertMultistepForm/EmailCasesFormWhat/RequestSelection";
export default function EmailCasesFormWhat() {
    var errors = useFormContext().formState.errors;
    return (_jsxs("div", { className: clsx("flex flex-col  rounded-[24px] gap-6 border  p-6", errors.dsrs ? " border-[#d32f2f]" : "border-prighterblue"), children: [_jsxs("div", { className: "flex flex-col", children: [_jsxs("div", { className: "flex  justify-between gap-3", children: [_jsx("h2", { className: "m-0 text-black font-fire text-[28px] font-normal", children: _jsx(T, { keyName: "generic.what" }) }), _jsx(FontAwesomeIcon, { icon: "question-circle", className: "text-prighterblue" })] }), _jsx("p", { className: "text-sm my-1 text-gray-500", children: _jsx(T, { keyName: "email_intake.select_reason_for_data_subject_request" }) })] }), _jsx(RequestSelection, {})] }));
}
