var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ReactMarkdown from "react-markdown";
import { BaseModal } from "components";
import { api } from "services";
import { useCase } from "pages/Client/Case/CaseContext";
export default function EmailsOverviewModal(_a) {
    var _this = this;
    var modalState = _a.modalState;
    var state = useCase()[0];
    var open = modalState[0], setOpen = modalState[1];
    var _b = useState(null), caseEmails = _b[0], setCaseEmails = _b[1];
    useEffect(function () {
        var fetchEmails = function () { return __awaiter(_this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.getCaseEmails(state.case.uuid)];
                    case 1:
                        res = _a.sent();
                        setCaseEmails(res);
                        return [2 /*return*/];
                }
            });
        }); };
        if (open) {
            fetchEmails();
        }
    }, [open, state.case.uuid]);
    return (_jsx(BaseModal, { modalState: [open, setOpen], className: "w-3/4 overflow-auto", children: _jsx("div", { children: (caseEmails === null || caseEmails === void 0 ? void 0 : caseEmails.length) > 0 ? (_jsx("div", { children: caseEmails.map(function (item, index) {
                    var _a;
                    return (_jsxs(Accordion, { children: [_jsx(AccordionSummary, { expandIcon: _jsx(ExpandMoreIcon, {}), "aria-controls": "panel".concat(index, "-content"), id: "panel".concat(index, "-header"), children: _jsx("div", { className: "font-medium", children: item.subject }) }), _jsxs(AccordionDetails, { className: "bg-slate-100", children: [_jsxs("div", { className: "flex flex-row flex-wrap gap-2 mb-2", children: [_jsx("div", { className: "font-bold mb-2", children: "Subject:" }), _jsx("div", { children: item.subject })] }), _jsxs("div", { className: "flex flex-row flex-wrap gap-2 mb-2", children: [_jsx("div", { className: "font-bold mb-2", children: "Recipients:" }), _jsx("div", { children: (_a = item.to_email) === null || _a === void 0 ? void 0 : _a.map(function (toEmailItem) { return toEmailItem; }).join(", ") })] }), _jsxs("div", { className: "flex flex-row flex-wrap gap-2 mb-2", children: [_jsx("div", { className: "font-bold mb-2", children: "Content Type:" }), _jsx("div", { children: item.content_type })] }), _jsxs("div", { className: "flex flex-row flex-wrap gap-2 mb-2", children: [_jsx("div", { className: "font-bold mb-2", children: "Sent at:" }), _jsx("div", { children: item.sent_at })] }), _jsxs("div", { className: "flex flex-row flex-wrap gap-2 mb-2", children: [_jsx("div", { className: "font-bold mb-2", children: "Created at:" }), _jsx("div", { children: item.created_at })] }), _jsxs("div", { children: [_jsx("div", { className: "font-bold mb-2", children: "Body:" }), _jsx(ReactMarkdown, { className: "break-words", children: item.content })] })] })] }));
                }) })) : (_jsx("div", { children: "There is no emails at the moment" })) }) }));
}
