var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useId, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import LoadingButton from "@mui/lab/LoadingButton";
import { BaseButton, BaseIconButton } from "components/Button";
export default function FileField(props) {
    var label = props.label, className = props.className, required = props.required, _a = props.multiple, multiple = _a === void 0 ? false : _a, _b = props.onSubmit, onSubmit = _b === void 0 ? function () { } : _b, _c = props.loading, loading = _c === void 0 ? false : _c;
    var id = useId();
    var hiddenFileInput = useRef(null);
    var _d = useState([]), fileList = _d[0], setFileList = _d[1];
    var handleClick = function () {
        hiddenFileInput.current.click();
    };
    var handleSubmit = function () {
        onSubmit(fileList);
        handleClear();
    };
    var handleChange = function (event) {
        if (multiple) {
            setFileList(function (oldFileList) { return __spreadArray(__spreadArray([], oldFileList, true), Array.from(event.target.files), true); });
        }
        else {
            setFileList([event.target.files[0]]);
        }
    };
    var handleClear = function () {
        setFileList([]);
        hiddenFileInput.current.value = "";
        if (hiddenFileInput.current.value) {
            hiddenFileInput.current.type = "text";
            hiddenFileInput.current.type = "file";
        }
    };
    var handleRemove = function (file) {
        setFileList(function (oldFileList) { return oldFileList.filter(function (oldFile) { return oldFile !== file; }); });
    };
    return (_jsxs("div", { className: "flex flex-col items-end space-y-4", children: [_jsx("div", { className: "flex flex-row items-center space-x-2", children: _jsxs("div", { className: clsx(className, "border-2 rounded-2xl border-dashed border-prighterblue flex flex-col items-center w-fit p-4"), children: [_jsxs(BaseButton, { variant: "outlined", onClick: handleClick, children: [label, " ", required && "*"] }), _jsx("input", { type: "file", ref: hiddenFileInput, className: "hidden", multiple: multiple, onChange: handleChange }), multiple ? (_jsxs("label", { htmlFor: id, className: "flex flex-col items-start space-y-2 p-2 pl-1 pb-1 text-gray-600", children: [fileList.map(function (file) { return (_jsxs("div", { className: "flex flex-row items-center", children: [_jsx("div", { className: "border-2 border-dashed rounded-full border-prighterblue py-2 px-4 mr-1", children: file.name }), _jsx(BaseIconButton, { onClick: function () { return handleRemove(file); }, className: "w-5 h-5", size: "medium", children: _jsx(FontAwesomeIcon, { size: "xs", icon: "xmark", color: "danger" }) })] }, file.name)); }), fileList.length === 0 && "No file".concat(multiple ? "s" : "", " selected")] })) : (_jsx("label", { htmlFor: id, className: "flex flex-col items-start space-y-2 p-2 pl-1 pb-1 text-gray-600", children: fileList.length === 0 ? "No file".concat(multiple ? "s" : "", " selected") : fileList[0].name }))] }) }), _jsxs("div", { className: "flex space-x-2", children: [fileList.length > 0 && _jsx(BaseButton, { onClick: handleClear, children: "Cancel" }), _jsx(LoadingButton, { color: "primary", variant: "contained", loading: loading, disabled: fileList.length === 0, onClick: handleSubmit, children: "Upload Files" })] })] }));
}
