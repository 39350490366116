import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputAdornment, TextField } from "@mui/material";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import { BaseIconButton } from "components/Button";
export default function SearchField(props) {
    var searchState = props.searchState, _a = props.className, className = _a === void 0 ? "" : _a;
    var search = searchState[0], setSearch = searchState[1];
    var handleChange = useCallback(function (text) {
        setSearch(text.target.value);
    }, [setSearch]);
    var emptySearch = useCallback(function () { return setSearch(""); }, [setSearch]);
    return (_jsx("div", { className: clsx(className, "my-2"), children: _jsx(TextField, { "data-testid": "search-field", value: search, onChange: handleChange, type: "search", label: "Search for a value...", fullWidth: true, InputProps: {
                endAdornment: (_jsx(InputAdornment, { position: "end", children: _jsx(BaseIconButton, { className: "border-full", onClick: emptySearch, children: _jsx("div", { className: "w-4 h-4 flex items-center justify-center", children: _jsx(FontAwesomeIcon, { icon: faXmark, color: "gray" }) }) }) })),
            } }) }));
}
