import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { Checkbox, ListItemText } from "@mui/material";
var ITEM_HEIGHT = 48;
var ITEM_PADDING_TOP = 8;
var MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
export default function MultipleSelectChip(_a) {
    var value = _a.value, label = _a.label, disabled = _a.disabled, options = _a.options, onChange = _a.onChange;
    var handleChange = function (event) {
        var val = event.target.value;
        onChange(val);
    };
    return (_jsxs(FormControl, { className: "w-full", children: [_jsx(InputLabel, { id: "demo-multiple-chip-label", children: label }), _jsx(Select, { disabled: disabled, multiple: true, value: value, onChange: handleChange, input: _jsx(OutlinedInput, { id: "select-multiple-chip", label: "Chip" }), renderValue: function (selected) { return (_jsx(Box, { sx: { display: "flex", flexWrap: "wrap", gap: 0.5 }, children: selected.map(function (selectedValue) { return (_jsx(Chip, { label: selectedValue }, selectedValue)); }) })); }, MenuProps: MenuProps, children: options === null || options === void 0 ? void 0 : options.map(function (item) { return (_jsxs(MenuItem, { value: item, children: [_jsx(Checkbox, { checked: value.includes(item) }), _jsx(ListItemText, { primary: item })] }, item)); }) })] }));
}
