import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormControl } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { T } from "@tolgee/react";
import { BaseSelectField } from "components";
import { selectStyle } from "pages/Client/EmailCases/Pages/styling";
import tolgee from "services/translation";
export default function EmailCasesFormWhere() {
    var _a = useFormContext(), watch = _a.watch, setValue = _a.setValue, errors = _a.formState.errors;
    var type = watch("type");
    return (_jsxs("div", { className: "flex flex-col  rounded-[24px] gap-6 border border-prighterblue p-6", children: [_jsxs("div", { className: "flex  justify-between gap-3", children: [_jsx("h2", { className: "m-0 text-black font-fire text-[28px] font-normal", children: _jsx(T, { keyName: "email_intake.data_subject_requested_from_california" }) }), _jsx(FontAwesomeIcon, { icon: "question-circle", className: "text-prighterblue" })] }), _jsx(FormControl, { fullWidth: true, className: "flex flex-col gap-3", children: _jsx(BaseSelectField, { error: !!(errors === null || errors === void 0 ? void 0 : errors.type), required: true, sx: selectStyle, state: [type, function (val) { return setValue("type", val); }], options: {
                        DSR_CCPA: tolgee.t("generic.yes"),
                        AUTHORITY: tolgee.t("generic.no"),
                    } }) })] }));
}
