export var infoDictionary = function (type, id, businessId) {
    var dict = {
        persons: "/businesses/".concat(businessId, "/persons/").concat(id),
        processes: "/businesses/".concat(businessId, "/processes/").concat(id),
        "data-categories": "/businesses/".concat(businessId, "/datacategories/").concat(id),
        "affected-groups": "/businesses/".concat(businessId, "/affectedgroups/").concat(id),
        controllers: "/businesses/".concat(businessId, "/controllers/").concat(id),
        "internal-recipients": "/businesses/".concat(businessId, "/recipients/internal/").concat(id),
        "external-recipients": "/businesses/".concat(businessId, "/recipients/external/").concat(id),
        "processor-recipients": "/businesses/".concat(businessId, "/recipients/processor/").concat(id),
    };
    if (type in dict) {
        return dict[type];
    }
    return "";
};
export var entityDictionary = function (type, businessId) {
    var dict = {
        persons: "/businesses/".concat(businessId, "/persons"),
        processes: "/businesses/".concat(businessId, "/processes"),
        "data-categories": "/businesses/".concat(businessId, "/datacategories"),
        "affected-groups": "/businesses/".concat(businessId, "/affectedgroups"),
        controllers: "/businesses/".concat(businessId, "/controllers"),
        "internal-recipients": "/businesses/".concat(businessId, "/recipients/internal"),
        "external-recipients": "/businesses/".concat(businessId, "/recipients/external"),
        "processor-recipients": "/businesses/".concat(businessId, "/recipients/processor"),
    };
    if (type in dict) {
        return dict[type];
    }
    return "";
};
