import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { T } from "@tolgee/react";
import { useEffect } from "react";
import tolgee from "services/translation";
export default function Forbidden() {
    useEffect(function () {
        document.title = tolgee.t({
            key: "generic.forbidden",
        });
    }, []);
    return (_jsx("div", { className: "flex items-center w-full h-screen justify-center bg-gray-50", "data-testid": "error-element", children: _jsxs("div", { className: "text-center", children: [_jsx("h1", { children: "403" }), _jsx("h2", { children: _jsx(T, { keyName: "generic.forbidden" }) }), _jsx("p", { children: "..." })] }) }));
}
