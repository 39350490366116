import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Typography, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { T } from "@tolgee/react";
import tolgee from "services/translation";
var StyledButton = styled(Button)(function (_a) {
    var theme = _a.theme;
    return ({
        width: "75px",
        height: "75px",
        border: "1px solid ".concat(theme.palette.divider),
    });
});
var DotIndicator = styled("div")(function (_a) {
    var theme = _a.theme;
    return ({
        width: "12px",
        height: "12px",
        borderRadius: "50%",
        backgroundColor: theme.palette.primary.main,
    });
});
export default function LogoPositionSelector(_a) {
    var position = _a.position, setPosition = _a.setPosition;
    var positions = [
        "TOP_LEFT",
        "TOP_CENTER",
        "TOP_RIGHT",
        "CENTER_LEFT",
        "",
        "CENTER_RIGHT",
        "BOTTOM_LEFT",
        "BOTTOM_CENTER",
        "BOTTOM_RIGHT",
    ];
    return (_jsxs(Paper, { className: "w-fit", elevation: 0, sx: { p: 2, bgcolor: "background.default" }, children: [_jsx(Box, { display: "grid", className: "w-64 h-64", gridTemplateColumns: "repeat(3, 1fr)", gap: 1, children: positions.map(function (pos) { return (_jsx(StyledButton, { variant: pos === position ? "contained" : "outlined", onClick: function () { return pos && setPosition(pos); }, disabled: !pos, className: "".concat(!pos ? "opacity-0" : ""), "aria-label": pos ? "Set logo position to ".concat(pos.toLowerCase().replace("_", " ")) : undefined, children: pos === position && _jsx(DotIndicator, {}) }, pos)); }) }), _jsxs(Typography, { variant: "caption", display: "block", textAlign: "center", mt: 1, children: [_jsx(T, { keyName: "clp.selected_position" }), ": ", tolgee.t({ key: "clp.".concat(position.toLowerCase()) })] })] }));
}
