var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Fragment, useEffect, useState } from "react";
import { IconButton, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { ExtendedSelectField } from "components/Input";
import { getCountryAsOptions } from "helpers";
import { useAppSelector } from "store";
import { PRIGHTER_BLUE } from "const/color";
import tolgee from "services/translation";
export default function AddressForm(_a) {
    var _b = _a.contactAddresses, contactAddresses = _b === void 0 ? [] : _b, validationError = _a.validationError, onChange = _a.onChange, multiple = _a.multiple, addressIcon = _a.addressIcon, shrink = _a.shrink, required = _a.required;
    var _c = useState([]), countryOptions = _c[0], setCountryOptions = _c[1];
    var countries = useAppSelector(function (state) { return state.app; }).countries;
    useEffect(function () {
        if (countries) {
            var options = getCountryAsOptions(countries);
            setCountryOptions(options);
        }
    }, [countries]);
    useEffect(function () {
        if (contactAddresses.length === 0) {
            handleIncrease();
        }
    }, [contactAddresses]);
    var handleChange = function (index, objKey, value) {
        var tempPrev = __spreadArray([], contactAddresses, true);
        tempPrev[index][objKey] = value;
        onChange(tempPrev);
    };
    var handleIncrease = function () {
        onChange(__spreadArray(__spreadArray([], contactAddresses, true), [{ country_iso: "", city: "", street: "", postal: "" }], false));
    };
    var handleRemove = function (index) {
        var tempContactAddresses = __spreadArray([], contactAddresses, true);
        tempContactAddresses.splice(index, 1);
        onChange(__spreadArray([], tempContactAddresses, true));
    };
    var getEndingIcon = function (index, isLastItem) {
        if (isLastItem) {
            return (_jsx(IconButton, { "data-testid": "add-address-button", onClick: handleIncrease, size: "medium", children: _jsx(FontAwesomeIcon, { icon: "plus", size: "sm" }) }));
        }
        return (_jsx(IconButton, { "data-testid": "remove-address-button", onClick: function () { return handleRemove(index); }, size: "medium", children: _jsx(FontAwesomeIcon, { icon: "trash", color: "red", size: "sm" }) }));
    };
    return (_jsx(_Fragment, { children: contactAddresses.map(function (contactAddress, ind) { return (_jsxs(Fragment, { children: [addressIcon &&
                    (ind === 0 ? (_jsx(FontAwesomeIcon, { className: "self-center", icon: "location-dot", color: PRIGHTER_BLUE })) : (_jsx("div", {}))), _jsxs("div", { className: clsx({ "xl:flex-col": shrink }, "flex flex-col xl:flex-row"), "data-testid": "address-block", children: [_jsxs("div", { className: clsx({ "xl:max-w-md": !shrink }, "flex space-x-4"), children: [_jsx(TextField, { className: "w-48", value: contactAddress.postal, onChange: function (text) { return handleChange(ind, "postal", text.target.value); }, label: tolgee.t({
                                        key: "generic.postal",
                                    }), required: required, error: !contactAddress.postal ? validationError : false }), _jsx(TextField, { className: "w-full", value: contactAddress.city, onChange: function (text) { return handleChange(ind, "city", text.target.value); }, label: tolgee.t({
                                        key: "generic.city",
                                    }), required: required, error: !contactAddress.city ? validationError : false })] }), _jsx("div", { className: "w-4 h-4" }), _jsxs("div", { className: clsx({ "xl:flex-col xl:space-y-4 xl:space-x-0": shrink, "xl:space-x-4": !shrink }, "flex flex-col xl:flex-row space-y-4 xl:space-y-0 w-full"), children: [_jsx(TextField, { className: "w-full grow", value: contactAddress.street, onChange: function (text) { return handleChange(ind, "street", text.target.value); }, label: tolgee.t({
                                        key: "generic.street",
                                    }), multiline: true, maxRows: 2, required: required, error: !contactAddress.street ? validationError : false }), _jsx("div", { children: _jsx(ExtendedSelectField, { state: [
                                            contactAddress.country_iso,
                                            function (text) { return handleChange(ind, "country_iso", text); },
                                        ], options: countryOptions, className: "min-w-[12rem]", label: tolgee.t({
                                            key: "generic.country",
                                        }), error: !contactAddress.country_iso ? validationError : false, required: required }) })] })] }), multiple && getEndingIcon(ind, ind + 1 === contactAddresses.length)] }, contactAddress.uuid || ind)); }) }));
}
