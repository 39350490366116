import { jsx as _jsx } from "react/jsx-runtime";
export function getInvoiceStatusElement(status) {
    var generateElement = function (bgColor, textColor) { return (_jsx("div", { className: "".concat(bgColor, " rounded px-2 w-fit self-start"), children: _jsx("span", { className: textColor, children: status[0].toUpperCase() + status.substring(1) }) })); };
    switch (status) {
        case "PAID":
            return generateElement("bg-success-400/75", "text-success-900");
        case "VOID":
        case "UNCOLLECTIBLE":
            return generateElement("bg-prighterblue/75", "text-brand-900");
        default:
            return generateElement("bg-orange-400/75", "text-orange-900");
    }
}
