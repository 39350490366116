var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { T } from "@tolgee/react";
import { Checkbox, TextField } from "@mui/material";
import { notify } from "store/app";
import { BaseButton } from "components";
import { api } from "services";
import tolgee from "services/translation";
export default function PrivacyPolicyModal(_a) {
    var _this = this;
    var configData = _a.configData, onLinkUpdate = _a.onLinkUpdate;
    var defaultValues = {
        privacy_policy_ext_url: configData.privacy_policy_ext_url,
        added: configData.prighter_in_privacy_policy,
    };
    var businessId = useParams().businessId;
    var dispatch = useDispatch();
    var _b = useForm({
        defaultValues: defaultValues,
    }), reset = _b.reset, handleSubmit = _b.handleSubmit, register = _b.register, control = _b.control, watch = _b.watch, isValid = _b.formState.isValid;
    var added = watch("added");
    var linkReg = register("privacy_policy_ext_url");
    useEffect(function () {
        reset(defaultValues);
    }, [configData]);
    var onSubmit = function (values) { return __awaiter(_this, void 0, void 0, function () {
        var settings, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    settings = __assign(__assign({}, configData), { privacy_policy_ext_url: values.privacy_policy_ext_url, prighter_in_privacy_policy: added });
                    return [4 /*yield*/, api.clp.updateBusinessConfig(businessId, settings)];
                case 1:
                    res = _a.sent();
                    if (res) {
                        dispatch(notify({
                            message: tolgee.t({
                                key: "billing.link_updated_successfully",
                            }),
                            type: "SUCCESS",
                        }));
                        onLinkUpdate();
                        handleFormReset();
                    }
                    else {
                        dispatch(notify({
                            message: tolgee.t({
                                key: "generic.smth_went_wrong",
                            }),
                            type: "ERROR",
                        }));
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var handleFormReset = function () {
        reset();
    };
    return (_jsxs(_Fragment, { children: [_jsx("h1", { className: "flex w-[400px] mbs-8", children: _jsx(T, { keyName: "billing.privacy_url" }) }), _jsx("div", { className: "flex w-full flex-col mb-2", children: _jsxs("form", { children: [_jsx(TextField, { className: "w-full", onChange: linkReg.onChange, onBlur: linkReg.onBlur, name: linkReg.name, ref: linkReg.ref, "data-testid": "link" }), _jsxs("div", { className: "flex items-center font-bold mb-4 mt-4 -ml-2", children: [_jsx(Controller, { name: "added", control: control, defaultValue: false, render: function (_a) {
                                        var _b = _a.field, value = _b.value, ref = _b.ref, field = __rest(_b, ["value", "ref"]);
                                        return (_jsx(Checkbox, __assign({}, field, { inputRef: ref, checked: !!value })));
                                    } }), _jsx("div", { className: "mt-1", children: _jsx(T, { keyName: "gdpr.eurep_info" }) })] }), _jsx("div", { className: "flex justify-end space-x-2 mt-8", children: _jsx(BaseButton, { onClick: handleSubmit(onSubmit), disabled: !isValid, children: _jsx(T, { keyName: "generic.save" }) }) })] }) })] }));
}
