var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { T } from "@tolgee/react";
import { BaseButton } from "components/Button";
import { BaseTextField } from "components/Input";
import { api } from "services";
export default function SupportMessage(_a) {
    var _this = this;
    var businessId = _a.businessId, supportMessageType = _a.supportMessageType, initialContent = _a.initialContent, modalTitle = _a.modalTitle, contentPlaceholder = _a.contentPlaceholder, openSupportModal = _a.openSupportModal;
    var _b = useState({
        type: supportMessageType,
        content: initialContent || "",
        business_id: businessId,
    }), supportContent = _b[0], setSupportContent = _b[1];
    var handleSendRequest = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.message.postSupportMessage(supportContent)];
                case 1:
                    _a.sent();
                    openSupportModal(false);
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs("div", { className: "w-[40rem] overflow-auto", children: [_jsx("h2", { className: "mb-4", children: modalTitle }), _jsx("div", { className: "mb-8", children: _jsx(BaseTextField, { containerClassName: "w-full", value: supportContent.content, placeholder: contentPlaceholder, onChange: function (event) {
                        return setSupportContent(function (prevState) { return (__assign(__assign({}, prevState), { content: event.target.value })); });
                    } }) }), _jsxs("div", { className: "flex flex-row justify-end gap-6", children: [_jsx(BaseButton, { disabled: !supportContent.content, color: "success", variant: "outlined", onClick: handleSendRequest, children: _jsx(T, { keyName: "general.send" }) }), _jsx(BaseButton, { color: "error", onClick: function () { return openSupportModal(false); }, children: _jsx(T, { keyName: "general.discard" }) })] })] }));
}
