var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Checkbox, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { captureException } from "@sentry/react";
import { T } from "@tolgee/react";
import { useEffect, useState } from "react";
import { BaseSelectField, FormFileField } from "components";
import { validateEmail } from "helpers/validate";
import tolgee from "services/translation";
import AddressForm from "components/complex/AddressForm";
export default function IdentificationForm() {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var _k = useState(true), sameEmail = _k[0], setSameEmail = _k[1];
    var _l = useFormContext(), register = _l.register, watch = _l.watch, setValue = _l.setValue, control = _l.control, errors = _l.formState.errors;
    var acting_for = watch("actors.0.acting_for");
    var files = watch("files");
    var ds_address = watch("actors.0.address");
    var email = watch("actors.0.email");
    var identifiableInfoEmail = watch("identifiable_info.email");
    useEffect(function () {
        if (sameEmail) {
            setValue("identifiable_info.email", email);
        }
    }, [email]);
    register("actors.0.acting_for", {
        validate: {
            minLength: function (e) { return "".concat(e).trim().length > 0 || "Please choose something."; },
        },
    });
    register("actors.0.address.postal", {
        validate: {
            minLength: function (e) { return "".concat(e).trim().length > 0; },
        },
    });
    register("actors.0.address.city", {
        validate: {
            minLength: function (e) { return "".concat(e).trim().length > 0; },
        },
    });
    register("actors.0.address.street", {
        validate: {
            minLength: function (e) { return "".concat(e).trim().length > 0; },
        },
    });
    register("actors.0.address.country_iso", {
        validate: {
            minLength: function (e) { return !!e && "".concat(e).trim().length > 0; },
        },
    });
    var handleUploadFiles = function (fileList) {
        var fileArray = Array.from(fileList);
        var fadpFileArray = fileArray.map(function (file) {
            return new Promise(function (resolve, reject) {
                var reader = new FileReader();
                reader.onloadend = function () {
                    var base64String = reader.result;
                    resolve(base64String);
                };
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        });
        Promise.all(fadpFileArray)
            .then(function (resolvedFileArray) {
            setValue("files", resolvedFileArray.map(function (f, ind) { return ({ name: fileArray[ind].name, content: f }); }));
        })
            .catch(function (error) {
            captureException(error);
        });
    };
    var handleCheckboxChange = function (event) {
        setSameEmail(event.target.checked);
        if (event.target.checked) {
            setValue("identifiable_info.email", email);
        }
        else {
            setValue("identifiable_info.email", "");
        }
    };
    return (_jsxs("div", { children: [_jsxs("h2", { children: [_jsx(T, { keyName: "fadp.requestor" }), ":"] }), _jsxs("div", { className: "flex flex-col space-y-4 mb-8", children: [_jsx(BaseSelectField, { error: !!(errors === null || errors === void 0 ? void 0 : errors.actors) && !!((_a = errors === null || errors === void 0 ? void 0 : errors.actors[0]) === null || _a === void 0 ? void 0 : _a.acting_for), required: true, state: [acting_for, function (val) { return setValue("actors.0.acting_for", val); }], label: tolgee.t({
                            key: "fadp.ds_info.acting_for",
                        }), options: {
                            MYSELF: tolgee.t({
                                key: "fadp.ds_info.acting_for.myself",
                            }),
                            AS_PARENT: tolgee.t({
                                key: "fadp.ds_info.acting_for.as_parent",
                            }),
                            AS_GUARDIAN: tolgee.t({
                                key: "fadp.ds_info.acting_for.as_guardian",
                            }),
                            AS_LEGAL_REPRESENTATIVE: tolgee.t({
                                key: "fadp.ds_info.acting_for.as_legal_representative",
                            }),
                            OTHER: tolgee.t({
                                key: "fadp.ds_info.acting_for.other",
                            }),
                        } }), !!(errors === null || errors === void 0 ? void 0 : errors.actors) && ((_b = errors === null || errors === void 0 ? void 0 : errors.actors[0]) === null || _b === void 0 ? void 0 : _b.acting_for) && (_jsx("span", { className: "text-danger pl-4 text-sm", children: _jsx(T, { keyName: "fadp.error_choose_acting_for" }) })), acting_for !== "MYSELF" && (_jsxs(_Fragment, { children: [_jsx(TextField, __assign({ label: tolgee.t({
                                    key: "generic.name",
                                }) }, register("actors.0.name", {
                                validate: {
                                    minLength: function (e) {
                                        return "".concat(e).length >= 4 || "Minimum length of your name should be 4.";
                                    },
                                },
                            }), { error: !!(errors === null || errors === void 0 ? void 0 : errors.actors) && !!((_c = errors === null || errors === void 0 ? void 0 : errors.actors[0]) === null || _c === void 0 ? void 0 : _c.name), helperText: !!(errors === null || errors === void 0 ? void 0 : errors.actors) && ((_e = (_d = errors === null || errors === void 0 ? void 0 : errors.actors[0]) === null || _d === void 0 ? void 0 : _d.name) === null || _e === void 0 ? void 0 : _e.message), required: true })), _jsx(TextField, __assign({ label: tolgee.t({
                                    key: "generic.email",
                                }) }, register("actors.0.email", {
                                validate: {
                                    minLength: function (e) {
                                        return "".concat(e).length >= 4 || "Minimum length of your email should be 4.";
                                    },
                                    validEmail: function (e) { return validateEmail(e) || "You need to add a valid email."; },
                                },
                            }), { error: !!(errors === null || errors === void 0 ? void 0 : errors.actors) && !!((_f = errors === null || errors === void 0 ? void 0 : errors.actors[0]) === null || _f === void 0 ? void 0 : _f.email), helperText: !!(errors === null || errors === void 0 ? void 0 : errors.actors) && ((_h = (_g = errors === null || errors === void 0 ? void 0 : errors.actors[0]) === null || _g === void 0 ? void 0 : _g.email) === null || _h === void 0 ? void 0 : _h.message), required: true })), _jsxs("div", { children: [_jsxs("span", { className: "font-bold", children: [_jsx(T, { keyName: "ccpa.ds_info.necessary_files_for_identification" }), ":"] }), _jsx(FormFileField, { label: "Upload a file", className: "mt-2", value: files, onChange: handleUploadFiles, multiple: true, fullWidth: true })] })] }))] }), _jsxs("h2", { className: "mt-8", children: [_jsx(T, { keyName: "dsr.data_subject" }), ":"] }), _jsxs("div", { className: "flex flex-col space-y-4 mb-8", children: [_jsx(TextField, __assign({ label: tolgee.t({
                            key: "generic.name",
                        }) }, register("identifiable_info.username"))), _jsx(Controller, { name: "identifiable_info.email", control: control, defaultValue: identifiableInfoEmail, render: function (_a) {
                            var _b, _c, _d;
                            var _e = _a.field, value = _e.value, ref = _e.ref, field = __rest(_e, ["value", "ref"]);
                            return (_jsx(TextField, __assign({}, field, { inputRef: ref, value: value, onChange: function (e) {
                                    setValue("identifiable_info.email", e.target.value);
                                    if (e.target.value === email) {
                                        setSameEmail(true);
                                    }
                                    else {
                                        setSameEmail(false);
                                    }
                                }, label: tolgee.t({
                                    key: "generic.email",
                                }), InputProps: acting_for !== "MYSELF" && {
                                    startAdornment: (_jsx(Checkbox, { checked: sameEmail, style: { padding: 0, margin: 0, marginRight: 2 }, onChange: handleCheckboxChange })),
                                }, error: !!((_b = errors === null || errors === void 0 ? void 0 : errors.identifiable_info) === null || _b === void 0 ? void 0 : _b.email), helperText: (_d = (_c = errors === null || errors === void 0 ? void 0 : errors.identifiable_info) === null || _c === void 0 ? void 0 : _c.email) === null || _d === void 0 ? void 0 : _d.message })));
                        } })] }), _jsxs("div", { className: "flex flex-col space-y-3", children: [_jsx(AddressForm, { contactAddresses: [ds_address], onChange: function (val) { return setValue("actors.0.address", val[0]); }, required: true, shrink: true }), !!(errors === null || errors === void 0 ? void 0 : errors.actors) && ((_j = errors === null || errors === void 0 ? void 0 : errors.actors[0]) === null || _j === void 0 ? void 0 : _j.address) && (_jsx("span", { className: "text-danger pl-4 text-sm", children: _jsx(T, { keyName: "fadp.error_provide_valid_address" }) }))] }), acting_for === "MYSELF" && (_jsxs("div", { children: [_jsxs("span", { className: "font-bold", children: [_jsx(T, { keyName: "ccpa.ds_info.necessary_files_for_identification" }), ":"] }), _jsx(FormFileField, { label: "Upload a file", className: "mt-2", value: files, onChange: handleUploadFiles, multiple: true, fullWidth: true })] }))] }));
}
