var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BaseTextField } from "components/Input";
import { PRIGHTER_BLUE } from "const/color";
import tolgee from "services/translation";
export default function ContactPointList(_a) {
    var _b = _a.contactPoints, contactPoints = _b === void 0 ? [] : _b, onChange = _a.onChange;
    var handleChange = function (contactObj, newValue) {
        var objInd = contactPoints.findIndex(function (cp) { return cp === contactObj; });
        var tempList = __spreadArray([], contactPoints, true);
        tempList[objInd].value = newValue;
        onChange(tempList);
    };
    var handleRemove = function (contactObj) {
        var objInd = contactPoints.findIndex(function (cp) { return cp === contactObj; });
        var tempList = __spreadArray([], contactPoints, true);
        tempList.splice(objInd, 1);
        onChange(__spreadArray([], tempList, true));
    };
    return (_jsxs("div", { className: "flex flex-col space-y-4", children: [contactPoints
                .filter(function (cp) { return cp.type === "PHONE"; })
                .map(function (contactPoint, ind) { return (_jsxs("div", { className: "flex items-center space-x-4", children: [_jsx(FontAwesomeIcon, { icon: "phone", color: PRIGHTER_BLUE }), _jsx(BaseTextField, { shrink: true, value: contactPoint.value, onChange: function (event) { return handleChange(contactPoint, event.target.value); }, label: tolgee.t({
                            key: "contacts.phone",
                        }), containerClassName: "w-full" }), _jsx("div", { className: "py-1 border rounded-md hover:bg-slate-100 border-slate-300 bg-slate-50", children: _jsxs(IconButton, { "data-testid": "remove-contacts-button", onClick: function () { return handleRemove(contactPoint); }, size: "large", children: [_jsx(FontAwesomeIcon, { icon: "trash", className: " text-brand-600", size: "xs" }), " "] }) })] }, "".concat(contactPoint.uuid || ind, "-phone"))); }), contactPoints
                .filter(function (cp) { return cp.type === "EMAIL"; })
                .map(function (contactPoint, ind) { return (_jsxs("div", { className: "flex items-center space-x-4", children: [_jsx(FontAwesomeIcon, { icon: "envelope", color: PRIGHTER_BLUE }), _jsx(BaseTextField, { shrink: true, testid: "email-block", value: contactPoint.value, onChange: function (event) { return handleChange(contactPoint, event.target.value); }, label: tolgee.t({
                            key: "contacts.email",
                        }), containerClassName: "w-full" }), _jsx("div", { className: "py-1 border rounded-md hover:bg-slate-100 border-slate-300 bg-slate-50", children: _jsxs(IconButton, { "data-testid": "remove-contacts-button", onClick: function () { return handleRemove(contactPoint); }, size: "large", children: [_jsx(FontAwesomeIcon, { icon: "trash", className: " text-brand-600", size: "xs" }), " "] }) })] }, "".concat(contactPoint.uuid || ind, "-email"))); }), contactPoints
                .filter(function (cp) { return cp.type === "WEB"; })
                .map(function (contactPoint, ind) { return (_jsxs("div", { className: "flex items-center space-x-4", children: [_jsx(FontAwesomeIcon, { icon: "wifi", color: PRIGHTER_BLUE, className: "w-4" }), _jsx(BaseTextField, { shrink: true, value: contactPoint.value, onChange: function (event) { return handleChange(contactPoint, event.target.value); }, label: tolgee.t({
                            key: "contacts.web",
                        }), containerClassName: "w-full" }), _jsx("div", { className: "py-1 border rounded-md hover:bg-slate-100 border-slate-300 bg-slate-50", children: _jsxs(IconButton, { "data-testid": "remove-contacts-button", onClick: function () { return handleRemove(contactPoint); }, size: "large", children: [_jsx(FontAwesomeIcon, { icon: "trash", className: " text-brand-600", size: "xs" }), " "] }) })] }, "".concat(contactPoint.uuid || ind, "-web"))); })] }));
}
