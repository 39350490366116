export var emptyDashboardData = {
    user_todos: [],
    business_todos: {},
    stats: {},
    product_status: {},
};
export function getFinishedToDoCount(data) {
    var finishedUserTodos = data.user_todos.filter(function (i) { return i.todo; }).length;
    var finishedBusinessTodos = Object.values(data.business_todos).reduce(function (acc, curr) {
        return acc + curr.filter(function (i) { return i.todo; }).length;
    }, 0);
    return finishedUserTodos + finishedBusinessTodos;
}
export function getTotalToDoCount(data) {
    var totalUserTodos = data.user_todos.length;
    var totalBusinessTodos = Object.values(data.business_todos).reduce(function (acc, curr) {
        return acc + curr.length;
    }, 0);
    return totalUserTodos + totalBusinessTodos;
}
export function isThereUnfinishedToDo(data) {
    var isThereUnfinishedUserToDo = data.user_todos.some(function (todo) { return !todo.todo; });
    var isThereUnfinishedBusinessToDo = Object.values(data.business_todos).some(function (array) {
        return array.some(function (todo) { return !todo.todo; });
    });
    return isThereUnfinishedUserToDo || isThereUnfinishedBusinessToDo;
}
