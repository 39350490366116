import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactMarkdown from "react-markdown";
import { T } from "@tolgee/react";
import { getBeautyDate } from "helpers";
import HistoryEntryTemplate from "pages/Client/History/HistoryTemplates/HistoryEntryTemplate";
export default function CaseFinalizePreview(props) {
    var entityData = props.entityData, createdAt = props.createdAt;
    return (_jsx(HistoryEntryTemplate, { timeLineIcon: _jsx(FontAwesomeIcon, { icon: "envelope", color: "white", size: "lg" }), title: entityData.title, date: getBeautyDate(createdAt), processedAt: entityData.processed_at, children: _jsxs("div", { className: "p-2 flex flex-col space-y-4", style: { paddingLeft: "20px" }, children: [_jsxs("span", { children: [_jsxs("b", { className: "capitalize", children: [_jsx(T, { keyName: "generic.subject" }), ":"] }), entityData.subject] }), _jsx("div", { className: "bg-gray-200 p-2 secondarybox-outerlayout", style: { maxWidth: "50rem" }, children: _jsx(ReactMarkdown, { className: "prose max-w-[100%]", children: entityData.content }) })] }) }));
}
