import { Tolgee, BackendFetch, DevTools, FormatSimple } from "@tolgee/react";

import { TOLGEE_CDN, TOLGEE_KEY, TOLGEE_URL } from "const/env";

function getTolgee() {
  const tolgee = Tolgee()
    .use(BackendFetch({ prefix: TOLGEE_CDN }))
    .use(DevTools())
    .use(FormatSimple())
    .init({
      language: "en",
      fallbackLanguage: "en",
      apiUrl: TOLGEE_URL,
      apiKey: TOLGEE_KEY,
    });

  return tolgee;
}

export default getTolgee();
