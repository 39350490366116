import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";
export default function CollapsibleInnerBox(_a) {
    var className = _a.className, title = _a.title, children = _a.children, _b = _a.defaultCollapsed, defaultCollapsed = _b === void 0 ? false : _b;
    var _c = useState(defaultCollapsed), collapsed = _c[0], setCollapsed = _c[1];
    return (_jsxs("div", { className: clsx(className, "box-innerlayout"), children: [_jsxs("div", { className: "flex flex-row justify-between items-center", children: [title || _jsx(_Fragment, {}), _jsx(IconButton, { className: "w-12 h-12", onClick: function () { return setCollapsed(function (prev) { return !prev; }); }, children: _jsx(FontAwesomeIcon, { icon: collapsed ? "arrow-up" : "arrow-down" }) })] }), !collapsed && children] }));
}
