import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import tolgee from "services/translation";
import Certificate from "pages/Client/RepPages/components/Certificate";
import { BaseTabs } from "components/Tabs/Tabs";
export default function NisRepresentation() {
    var _a = useState(0), defaultTab = _a[0], setDefaultTab = _a[1];
    var search = useLocation().search;
    useEffect(function () {
        var query = new URLSearchParams(search);
        var openParam = query.get("open");
        if (openParam === "nisuk") {
            setDefaultTab(1);
        }
    }, [search]);
    useEffect(function () {
        document.title = tolgee.t({
            key: "nis.nis",
        });
    }, []);
    var tabsData = [
        {
            id: 1,
            label: "\uD83C\uDDEA\uD83C\uDDFA ".concat(tolgee.t({ key: "nis.nis_eu" })),
            content: _jsx(Certificate, { type: "niseu" }),
            testId: "niseu",
        },
        {
            id: 2,
            label: "\uD83C\uDDEC\uD83C\uDDE7 ".concat(tolgee.t({ key: "nis.nis_uk" })),
            content: _jsx(Certificate, { type: "nisuk" }),
            testId: "nisuk",
        },
    ];
    return (_jsx("div", { className: "mx-auto max-w-7xl box-outerlayout", "data-testid": "nis-page", children: _jsx(BaseTabs, { tabsData: tabsData, defaultTab: defaultTab }) }));
}
