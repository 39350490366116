import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { T } from "@tolgee/react";
import { useEffect } from "react";
import tolgee from "services/translation";
import Snippet from "pages/Client/RepPages/components/Snippet";
import Certificate from "pages/Client/RepPages/components/Certificate";
export default function TurkeyRep() {
    useEffect(function () {
        document.title = tolgee.t({
            key: "rep.turkeyrep_title",
        });
    }, []);
    return (_jsxs("div", { className: "flex flex-col mx-auto max-w-7xl", "data-testid": "turkeyrep-page", children: [_jsx("h1", { children: _jsx(T, { keyName: "rep.turkeyrep_title" }) }), _jsx(Snippet, { type: "turkeyrep" }), _jsx(Certificate, { type: "turkeyrep" })] }));
}
