import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { T } from "@tolgee/react";
import { useEffect } from "react";
import Snippet from "pages/Client/RepPages/components/Snippet";
import tolgee from "services/translation";
import Info from "pages/Client/RepPages/EURep/Components/Info";
import Location from "pages/Client/RepPages/EURep/Components/Location";
import Certificate from "pages/Client/RepPages/components/Certificate";
export default function EURep() {
    useEffect(function () {
        document.title = tolgee.t({
            key: "gdpr.gdpr_rep",
        });
    }, []);
    return (_jsxs("div", { className: "flex flex-col mx-auto max-w-7xl", children: [_jsx("h1", { children: _jsx(T, { keyName: "gdpr.eurep_title" }) }), _jsx(Info, {}), _jsx(Snippet, { type: "art27" }), _jsx(Certificate, { type: "art27" }), _jsx(Location, {})] }));
}
