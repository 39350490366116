import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton, Tooltip } from "@mui/material";
import { useState } from "react";
import clsx from "clsx";
import ConfirmationModal from "components/DataDisplay/Modal/ConfirmationModal";
export default function BaseIconButton(props) {
    var _a = props.size, size = _a === void 0 ? "medium" : _a, className = props.className, style = props.style, _b = props.onClick, onClick = _b === void 0 ? function () { } : _b, children = props.children, confirmPopup = props.confirmPopup, tooltip = props.tooltip, _c = props.disabled, disabled = _c === void 0 ? false : _c, _d = props.testid, testid = _d === void 0 ? "" : _d;
    var _e = useState(false), open = _e[0], setOpen = _e[1];
    var handleClick = function () {
        if (confirmPopup) {
            setOpen(true);
        }
        else {
            onClick();
        }
    };
    var buttonElement = (_jsx(IconButton, { disabled: disabled, "data-testid": testid, size: size, style: style, onClick: handleClick, className: clsx(className, "h-fit w-fit"), children: children }));
    return (_jsxs(_Fragment, { children: [!tooltip ? (buttonElement) : (_jsx(Tooltip, { title: tooltip === null || tooltip === void 0 ? void 0 : tooltip.title, placement: tooltip === null || tooltip === void 0 ? void 0 : tooltip.placement, children: buttonElement })), confirmPopup && _jsx(ConfirmationModal, { modalState: [open, setOpen], onYes: onClick })] }));
}
