import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";

export default function ExtendedSelectField({
  state,
  label,
  options = [],
  required = false,
  className = "",
  error = false,
  sx = {},
}) {
  const [key, setKey] = state;
  const [localValue, setLocalValue] = useState(null);

  useEffect(() => {
    if (key && options.length) {
      setLocalValue(options.find((o) => o.key === key));
    }
  }, [key, options]);

  const handleChange = (_, newValue) => {
    setLocalValue(newValue);
    setKey(newValue?.key);
  };

  return (
    <Autocomplete
      required={required}
      value={localValue}
      options={options}
      sx={sx}
      getOptionLabel={(option) => option.label}
      onChange={handleChange}
      isOptionEqualToValue={(option, val) => option && val && option.key === val.key}
      className={className}
      renderInput={(params) => (
        <TextField
          error={error}
          {...params}
          required={required}
          InputLabelProps={{}}
          label={label}
        />
      )}
    />
  );
}
