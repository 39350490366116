import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getBeautyDate } from "helpers";
import HistoryEntryTemplate from "pages/Client/History/HistoryTemplates/HistoryEntryTemplate";
import tolgee from "services/translation/tolgee";
export default function CaseCheckOpened(props) {
    var entityData = props.entityData, createdAt = props.createdAt;
    return (_jsx(HistoryEntryTemplate, { timeLineIcon: _jsx(FontAwesomeIcon, { icon: "check", color: "white", size: "lg" }), title: entityData.title, date: getBeautyDate(createdAt), processedAt: entityData.processed_at, children: _jsx("div", { className: "p-4 ", children: _jsxs("span", { children: [_jsx("b", { children: tolgee.t({
                            key: "dsr.check_name",
                        }) }), ": ", tolgee.t({
                        key: "dsr.step_title.".concat(entityData.name.toLowerCase()),
                    })] }) }) }));
}
