var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { T } from "@tolgee/react";
import { notify } from "store/app";
import { BaseButton } from "components";
import { api } from "services";
import AddressForm from "components/complex/AddressForm";
var schema = yup
    .object()
    .shape({
    street: yup.string().required().min(2),
    postal: yup.string().required().min(2),
    country_iso: yup.string().required().min(2),
    city: yup.string().required().min(2),
})
    .required();
export default function CompanyAddressModal(_a) {
    var _this = this;
    var companyData = _a.companyData, onAddressUpdate = _a.onAddressUpdate;
    var defaultValues = {
        city: companyData.address.city,
        street: companyData.address.street,
        country_iso: companyData.address.country_iso,
        postal: companyData.address.postal,
    };
    var businessId = useParams().businessId;
    var dispatch = useDispatch();
    var _b = useForm({
        defaultValues: defaultValues,
        resolver: yupResolver(schema),
    }), reset = _b.reset, watch = _b.watch, handleSubmit = _b.handleSubmit, isValid = _b.formState.isValid;
    var addressState = watch();
    useEffect(function () {
        reset(defaultValues);
    }, [companyData]);
    var handleAddressChange = function (contactAddresses) {
        reset(contactAddresses[0]);
    };
    var onSubmit = function (values) { return __awaiter(_this, void 0, void 0, function () {
        var newAddress, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    newAddress = values;
                    return [4 /*yield*/, api.company.updateCompany(businessId, newAddress)];
                case 1:
                    res = _a.sent();
                    if (res) {
                        dispatch(notify({ message: "Address updated successfully", type: "SUCCESS" }));
                        onAddressUpdate();
                    }
                    else {
                        dispatch(notify({ message: "Something went wrong", type: "ERROR" }));
                    }
                    handleFormReset();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleFormReset = function () {
        reset();
    };
    return (_jsxs(_Fragment, { children: [_jsx("h1", { className: "flex w-full mbs-8", children: _jsx(T, { keyName: "billing.company_address" }) }), _jsx("div", { className: "flex w-full flex-col mb-2", children: _jsxs("form", { children: [_jsx(AddressForm, { contactAddresses: [addressState], onChange: handleAddressChange, shrink: true, required: true }), _jsx("div", { className: "flex justify-end space-x-2 mt-8", children: _jsx(BaseButton, { onClick: handleSubmit(onSubmit), disabled: !isValid, children: _jsx(T, { keyName: "generic.save" }) }) })] }) })] }));
}
