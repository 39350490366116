var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { captureException } from "@sentry/react";
import { Button, FormControl, TextField } from "@mui/material";
import { T } from "@tolgee/react";
import { useFieldArray, useForm } from "react-hook-form";
import { notify } from "store/app";
import tolgee from "services/translation";
import { api } from "services";
import { FormFileField } from "components";
export default function OpenInquiries() {
    var _this = this;
    var dispatch = useDispatch();
    var _a = useState(), data = _a[0], setData = _a[1];
    var _b = useState(0), reload = _b[0], setReload = _b[1];
    var _c = useState([]), wrongAnswers = _c[0], setWrongAnswers = _c[1];
    var _d = useState([]), rightAnswers = _d[0], setRightAnswers = _d[1];
    var _e = useForm(), handleSubmit = _e.handleSubmit, register = _e.register, reset = _e.reset, control = _e.control, watch = _e.watch, setValue = _e.setValue;
    useFieldArray({
        control: control,
        name: "answers",
    });
    var answersData = watch("answers");
    var _f = useParams(), caseUuid = _f.caseUuid, businessId = _f.businessId;
    useEffect(function () {
        document.title = tolgee.t({
            key: "dsr.dsr_request",
        });
        fetchData();
    }, [caseUuid, reload]);
    var fetchData = function () { return __awaiter(_this, void 0, void 0, function () {
        var res, defaultValues;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.openInquiries(caseUuid, businessId)];
                case 1:
                    res = _a.sent();
                    if (!res) {
                        dispatch(notify({
                            message: tolgee.t({
                                key: "generic.smth_went_wrong",
                            }),
                            type: "ERROR",
                        }));
                        return [2 /*return*/];
                    }
                    setData(res);
                    defaultValues = res.map(function () {
                        return { answer: "" };
                    });
                    reset({ answers: defaultValues });
                    return [2 /*return*/];
            }
        });
    }); };
    var handleUploadFiles = function (files, idx) {
        var fileArray = Array.from(files);
        var ccpaFileArray = fileArray === null || fileArray === void 0 ? void 0 : fileArray.map(function (file) {
            return new Promise(function (resolve, reject) {
                var reader = new FileReader();
                reader.onloadend = function () {
                    var base64String = reader.result;
                    resolve(base64String);
                };
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        });
        Promise.all(ccpaFileArray)
            .then(function (resolvedFileArray) {
            setValue("answers.".concat(idx, ".files"), resolvedFileArray === null || resolvedFileArray === void 0 ? void 0 : resolvedFileArray.map(function (f, ind) { return ({
                name: fileArray[ind].name,
                mime_type: fileArray[ind].type,
                content: f,
            }); }));
        })
            .catch(function (error) {
            captureException(error);
        });
    };
    var getQuestion = function (inquiry, idx) {
        var _a;
        var lastItem = data.length - 1 === idx;
        return (_jsxs("div", { className: "flex flex-col space-y-4 p-6 ".concat(wrongAnswers.includes(inquiry.uuid) ? "bg-danger-100" : "", " ").concat(rightAnswers.includes(inquiry.uuid) ? "bg-success-100" : "", " ").concat(!lastItem && "border-b border-black"), children: [inquiry.question && _jsx("div", { children: inquiry.question }), inquiry.question_i18n && (_jsx("div", { children: tolgee.t({
                        key: "dsr.inquiry.".concat(inquiry.question_i18n.key, "_title"),
                    }) })), _jsx(TextField, __assign({ label: tolgee.t({
                        key: "dsr.answer",
                    }) }, register("answers.".concat(idx, ".answer")))), inquiry.upload_required && (_jsx(FormFileField, { label: "Upload a file", className: "mt-2", value: answersData ? (_a = answersData[idx]) === null || _a === void 0 ? void 0 : _a.files : "", onChange: function (files) { return handleUploadFiles(files, idx); }, multiple: true, fullWidth: true }))] }, idx));
    };
    var getFormattedData = function (userResponse) {
        var _a;
        var formattedData = (_a = userResponse === null || userResponse === void 0 ? void 0 : userResponse.answers) === null || _a === void 0 ? void 0 : _a.map(function (item, idx) {
            var _a;
            var uploadRequired = data[idx].upload_required;
            if (uploadRequired && (item.answer === "" || !item.files || ((_a = item.files) === null || _a === void 0 ? void 0 : _a.length) === 0)) {
                setWrongAnswers(__spreadArray(__spreadArray([], wrongAnswers, true), [data[idx].uuid], false));
                return null;
            }
            if (!uploadRequired && item.answer === "") {
                return null;
            }
            setRightAnswers(__spreadArray(__spreadArray([], rightAnswers, true), [data[idx].uuid], false));
            return {
                answer: item.answer,
                files: item.files || [],
                uuid: data[idx].uuid,
            };
        });
        return formattedData === null || formattedData === void 0 ? void 0 : formattedData.filter(function (i) { return i !== null; });
    };
    var resetAnswers = function () {
        setTimeout(function () {
            setWrongAnswers([]);
            setRightAnswers([]);
        }, 1500);
        setReload(function (prev) { return prev + 1; });
    };
    var onSubmit = function (answers) { return __awaiter(_this, void 0, void 0, function () {
        var userResponse, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    userResponse = getFormattedData(answers);
                    if (!((userResponse === null || userResponse === void 0 ? void 0 : userResponse.length) > 0)) return [3 /*break*/, 2];
                    return [4 /*yield*/, api.answerInquiries(caseUuid, userResponse)];
                case 1:
                    res = _a.sent();
                    if (res) {
                        resetAnswers();
                        dispatch(notify({
                            message: tolgee.t({
                                key: "dsr.answers_saved",
                            }),
                            type: "SUCCESS",
                        }));
                    }
                    else {
                        dispatch(notify({
                            message: tolgee.t({
                                key: "generic.smth_went_wrong",
                            }),
                            type: "ERROR",
                        }));
                    }
                    return [3 /*break*/, 3];
                case 2:
                    resetAnswers();
                    dispatch(notify({
                        message: tolgee.t({
                            key: "dsr.no_data",
                        }),
                        type: "ERROR",
                    }));
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(_Fragment, { children: (data === null || data === void 0 ? void 0 : data.length) > 0 ? (_jsxs(FormControl, { fullWidth: true, children: [_jsx("div", { className: "border border-black rounded-lg", children: data === null || data === void 0 ? void 0 : data.map(function (i, idx) { return getQuestion(i, idx); }) }), _jsx("div", { className: "flex justify-end pt-6", children: _jsx(Button, { onClick: handleSubmit(onSubmit), children: _jsx(T, { keyName: "generic.save" }) }) })] })) : (_jsx("div", { children: tolgee.t({
                key: "dsr.no_open_inquiries",
            }) })) }));
}
