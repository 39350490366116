import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputLabel } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useFormContext } from "react-hook-form";
import { T } from "@tolgee/react";
import { inputStyle } from "pages/Client/EmailCases/Pages/styling";
export default function EmailCasesFormWhen() {
    var _a = useFormContext(), watch = _a.watch, setValue = _a.setValue;
    var dateReceived = watch("received_at");
    return (_jsxs("div", { className: "flex flex-col  rounded-[24px] gap-3 border border-prighterblue p-6 ", children: [_jsxs("div", { className: "flex flex-col", children: [_jsxs("div", { className: "flex  justify-between gap-3", children: [_jsx("h2", { className: "m-0 text-black font-fire text-[28px] font-normal", children: _jsx(T, { keyName: "generic.when" }) }), _jsx(FontAwesomeIcon, { icon: "question-circle", className: "text-prighterblue" })] }), _jsx("p", { className: "text-sm my-1 text-gray-500", children: _jsx(T, { keyName: "email_intake.enter_or_select_information" }) })] }), _jsxs("div", { className: "flex flex-col gap-2", children: [_jsx(InputLabel, { id: "demo-simple-select-label", children: _jsx(T, { keyName: "generic.date_received" }) }), _jsx(LocalizationProvider, { dateAdapter: AdapterMoment, children: _jsx(DatePicker, { format: "DD/MM/YYYY", className: "w-full", sx: inputStyle, value: moment(dateReceived), onChange: function (newValue) { return setValue("received_at", newValue.toISOString()); } }) })] })] }));
}
