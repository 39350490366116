import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import ReactMarkdown from "react-markdown";
import { T } from "@tolgee/react";
import PreCheckReviewTag from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/Stages/PreCheckReviewTag";
import tolgee from "services/translation";
import { SUCCESS } from "const/color";
import { BaseButton } from "components";
import { getStageChecks } from "helpers/case";
import { useCase } from "pages/Client/Case/CaseContext";
import { finalizeCheck } from "store/thunks";
export default function PreCheckReview() {
    var _a = useCase(), state = _a[0], dispatch = _a[1];
    var caseData = state.case;
    var checks = getStageChecks(caseData.case_checks, "PRE_CHECK");
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "grid gap-1 px-6 mb-3", children: [_jsx("h2", { className: "my-0 font-bold", children: tolgee.t({
                            key: "dsr.pre_check_review_title",
                        }) }), _jsx(ReactMarkdown, { className: "prose max-w-[100%]", children: tolgee.t({
                            key: "dsr.pre_check_review_description",
                        }) })] }), _jsx("div", { "data-testid": "dsr-flow-check-data", className: "space-y-2 p-6", children: checks.map(function (check) { return (_jsx(PreCheckReviewTag, { check: check, caseData: caseData }, check.uuid)); }) }), _jsxs("div", { className: "flex justify-between px-6", children: [_jsx(BaseButton, { onClick: function () { return dispatch({ type: "UPDATE_UI_STAGE", payload: "PRE_CHECK" }); }, children: _jsx(T, { keyName: "generic.back" }) }), _jsxs(Button, { "data-testid": "dsr_confirmation_preCheckReview", disabled: caseData.state === "CLOSED", onClick: function () { return finalizeCheck()(dispatch, caseData.uuid, "PRE_CHECK_REVIEW"); }, sx: {
                            backgroundColor: SUCCESS.DEFAULT,
                            "&:hover": { backgroundColor: "#4b904f" },
                        }, children: [tolgee.t({
                                key: "dsr.next_step_handling",
                            }), _jsx(FontAwesomeIcon, { className: "ml-2", icon: "arrow-right", size: "lg" })] })] })] }));
}
