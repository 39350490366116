import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BaseButton } from "components/Button";
import BaseModal from "components/DataDisplay/Modal/BaseModal";
export default function ConfirmationModal(props) {
    var onYes = props.onYes, _a = props.onNo, onNo = _a === void 0 ? function () { } : _a, modalState = props.modalState, titleText = props.titleText;
    var handleClose = function () {
        onNo();
        modalState[1](false);
    };
    var handleYes = function () {
        onYes();
        modalState[1](false);
    };
    return (_jsx(BaseModal, { modalState: modalState, className: "rounded-md", children: _jsxs("div", { className: "flex flex-col", children: [_jsx("h3", { className: "mt-0", children: titleText || _jsx(_Fragment, { children: "Are you sure you want to do this?" }) }), _jsx("p", { className: "mt-0", children: "Please confirm this action" }), _jsxs("div", { className: "grid grid-flow-col gap-4", children: [_jsx(BaseButton, { className: "w-full", color: "success", variant: "outlined", onClick: handleYes, children: "Yes" }), _jsx(BaseButton, { className: "w-full", color: "error", onClick: handleClose, children: "No" })] })] }) }));
}
