var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { InputLabel, TextField } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { T } from "@tolgee/react";
import { inputStyle } from "pages/Client/EmailCases/Pages/styling";
import tolgee from "services/translation";
function CapacityForm() {
    var _a, _b, _c, _d;
    var _e = useFormContext(), register = _e.register, errors = _e.formState.errors, getValues = _e.getValues;
    return (_jsxs("div", { className: "grid grid-cols-1 md:grid-cols-2 gap-4  items-start text-darkgray ", children: [_jsxs("div", { className: "flex flex-col gap-2", children: [_jsx(InputLabel, { children: _jsx(T, { keyName: "generic.name" }) }), _jsx(TextField, __assign({ size: "small", sx: inputStyle, error: !!((_b = (_a = errors === null || errors === void 0 ? void 0 : errors.actors) === null || _a === void 0 ? void 0 : _a[1]) === null || _b === void 0 ? void 0 : _b.name) }, register("actors.1.name", {
                        validate: function (value) {
                            var actingFor = getValues("actors.1.acting_for");
                            if (actingFor !== "MYSELF" && !value) {
                                return tolgee.t("email_intake.name_is_required");
                            }
                            return true;
                        },
                    })))] }), _jsxs("div", { className: "flex flex-col gap-2", children: [_jsx(InputLabel, { children: _jsx(T, { keyName: "generic.email" }) }), _jsx(TextField, __assign({ size: "small", sx: inputStyle, error: !!((_d = (_c = errors === null || errors === void 0 ? void 0 : errors.actors) === null || _c === void 0 ? void 0 : _c[1]) === null || _d === void 0 ? void 0 : _d.email) }, register("actors.1.email", {
                        validate: function (value) {
                            var actingFor = getValues("actors.1.acting_for");
                            if (actingFor !== "MYSELF" && !value) {
                                return tolgee.t("email_intake.email_is_required");
                            }
                            return true;
                        },
                    })))] }), _jsxs("div", { className: "flex flex-col gap-2", children: [_jsx(InputLabel, { children: _jsx(T, { keyName: "generic.phone_number" }) }), _jsx(TextField, __assign({ size: "small", sx: inputStyle }, register("actors.1.phone")))] })] }));
}
export default CapacityForm;
