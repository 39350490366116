import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from "react-router-dom";
import { Fragment } from "react";
import { productDict } from "const";
import DashboardProduct from "pages/Client/Dashboard/Components/DashboardProduct";
export default function ProductList(_a) {
    var activeProducts = _a.activeProducts, businessId = _a.businessId;
    var getProduct = function (productName) {
        return productName in productDict && productDict[productName];
    };
    return (_jsxs("div", { className: "grid grid-cols-1 md:grid-cols-2 gap-4 mt-4", children: [activeProducts.map(function (product) { return (_jsx(Fragment, { children: product.active && getProduct(product.product).productUrl ? (_jsx(Link, { to: getProduct(product.product).productUrl(businessId), "data-testid": "active-product-list-item", children: _jsx(DashboardProduct, { product: getProduct(product.product), productStatus: "active" }) })) : (_jsx(DashboardProduct, { product: getProduct(product.product), productStatus: product.active ? "active" : product.loa_status, productPurchaseLink: product.purchase_link })) }, product.product)); }), _jsx(DashboardProduct, { product: getProduct("chinarep"), productStatus: "not_started" })] }));
}
