var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var stepsArray = [
    {
        content: _jsx("h2", { children: "Lets begin our journey!" }),
        target: "body",
        placementBeacon: "top",
        placement: "center",
    },
    {
        content: _jsx("h2", { children: "Here is the list of entities where you can choose." }),
        target: "#case-summary",
    },
    {
        content: _jsx("h2", { children: "Here are the case actions where you can do for this case." }),
        target: "#case-actions",
    },
    {
        content: (_jsx("h2", { children: "Here is the case history where you can can see the latest changes for this case." })),
        target: "#case-history",
    },
].map(function (k) { return (__assign(__assign({}, k), { hideCloseButton: true })); });
export default stepsArray;
