import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getBeautyDate } from "helpers";
import HistoryEntryTemplate from "pages/Client/History/HistoryTemplates/HistoryEntryTemplate";
import tolgee from "services/translation/tolgee";
export default function CaseAdvanced(props) {
    var entityData = props.entityData, createdAt = props.createdAt;
    return (_jsx(HistoryEntryTemplate, { timeLineIcon: _jsx(FontAwesomeIcon, { icon: "check", color: "white", size: "lg" }), title: entityData.title, date: getBeautyDate(createdAt), processedAt: entityData.processed_at, children: _jsxs("div", { className: "p-4 space-y-2", children: [_jsx("div", { className: "flex flex-col", children: _jsxs("span", { children: [_jsx("b", { children: tolgee.t({
                                    key: "dsr.new_stage",
                                }) }), " ", tolgee.t({
                                key: "dsr.stage.".concat(entityData.new_stage.toLowerCase()),
                            })] }) }), _jsx("div", { className: "flex flex-col", children: _jsxs("span", { children: [_jsx("b", { children: tolgee.t({
                                    key: "dsr.old_stage",
                                }) }), " ", tolgee.t({
                                key: "dsr.stage.".concat(entityData.old_stage.toLowerCase()),
                            })] }) })] }) }));
}
