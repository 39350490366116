import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import tolgee from "services/translation";
export default function BoldTranslation(_a) {
    var translationSource = _a.translationSource, translationKey = _a.translationKey, boldedParts = _a.boldedParts;
    var translatedText = tolgee.t("".concat(translationSource, ".").concat(translationKey));
    var parts = translatedText.split(/(\s+)/);
    return (_jsx("span", { children: parts.map(function (part) {
            var isBoldWord = boldedParts.includes(part.trim());
            return (_jsx(React.Fragment, { children: isBoldWord ? _jsx("strong", { children: part }) : part }, part));
        }) }));
}
