import { jsx as _jsx } from "react/jsx-runtime";
import { Outlet } from "react-router-dom";
import { ErrorBoundary } from "@sentry/react";
import { useAppSelector } from "store";
import NotFound from "pages/General/Error/NotFound";
import ServerError from "pages/General/Error/ServerError";
import Redirect from "pages/General/Error/Redirect";
import ErrorFallback from "services/errorHandling/ErrorFallback";
import Forbidden from "pages/General/Error/Forbidden";
export default function PublicAuthService() {
    var errorCode = useAppSelector(function (state) { return state.app.error; });
    var renderErrorSwitch = function () {
        switch (errorCode) {
            case "404":
                return _jsx(NotFound, {});
            case "500":
                return _jsx(ServerError, {});
            case "401":
                return _jsx(Redirect, {});
            case "403":
                return _jsx(Forbidden, {});
            default:
                return _jsx(Outlet, {});
        }
    };
    var fallback = function (_a) {
        var resetError = _a.resetError;
        return _jsx(ErrorFallback, { resetError: resetError });
    };
    return (_jsx("div", { "data-testid": "public-layout-element", children: _jsx(ErrorBoundary, { fallback: fallback, children: renderErrorSwitch() }) }));
}
