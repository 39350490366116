import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@tolgee/react";
import { FileDownloadWithIcon, TimeLineDot } from "components";
import { getBeautyDate, getFileIcon, getFileName } from "helpers";
import { generateUrl } from "helpers/case";
import HistoryEntryTemplate from "pages/Client/History/HistoryTemplates/HistoryEntryTemplate";
export default function NewEmail(props) {
    var _a;
    var entityData = props.entityData, createdAt = props.createdAt, caseUuid = props.caseUuid;
    var from = entityData.from_email;
    if (entityData.from_name) {
        from = "".concat(entityData.from_name, " <").concat(entityData.from_email, ">");
    }
    return (_jsx(HistoryEntryTemplate, { timeLineIcon: _jsx(FontAwesomeIcon, { icon: "envelope", color: "white", size: "lg" }), title: "New mail added to Case", date: getBeautyDate(createdAt), processedAt: entityData.processed_at, children: _jsxs("div", { className: "w-full p-0 my-4 secondarybox-outerlayout", children: [_jsxs("div", { className: "flex items-center w-full h-12 pl-4 font-bold bg-gray-200", children: ["From ", _jsx("em", { className: "ml-1 text-center", children: from })] }), _jsxs("div", { className: "flex flex-row justify-between py-4", style: { paddingLeft: "40px" }, children: [_jsxs("div", { className: "flex flex-row", children: [_jsx(TimeLineDot, { additionalStyle: { margin: 0 }, children: _jsx(FontAwesomeIcon, { icon: "building", color: "gray" }) }), _jsx("div", { className: "whitespace-pre-line", style: { paddingLeft: "20px" }, children: entityData.text.trim() || "" })] }), _jsx("div", { className: "flex flex-col pr-2 space-y-2", children: ((_a = entityData.files) === null || _a === void 0 ? void 0 : _a.length) > 0 && (_jsxs(_Fragment, { children: [_jsxs("b", { children: [_jsx(T, { keyName: "dsr.files" }), ":"] }), entityData.files.map(function (file) { return (_jsx(FileDownloadWithIcon, { icon: getFileIcon(file.mime_type), children: _jsx("a", { href: generateUrl(caseUuid, file.uuid), children: getFileName(file) }) }, file.uuid)); })] })) })] })] }) }));
}
