import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@tolgee/react";
import EmailCasesListItem from "pages/Client/EmailCases/Components/EmailCasesListItem";
export default function EmailCasesList(props) {
    var emails = props.emails, workflow = props.workflow, setWorkflow = props.setWorkflow, fetchEmailById = props.fetchEmailById;
    return (_jsxs("div", { className: "flex flex-col gap-12", children: [_jsxs("div", { className: "flex items-start justify-between font-inter", children: [_jsx("h1", { className: "m-0 font-light", children: _jsx(T, { keyName: "generic.data_subject_requests" }) }), _jsxs("div", { className: "flex rounded-3xl border-2", children: [_jsx("button", { type: "button", className: "text-sm font-normal py-1 px-3 rounded-3xl text-gray-500 ".concat(workflow === "expert" ? "bg-prighterblue text-white" : ""), onClick: function () { return setWorkflow("expert"); }, children: _jsx(T, { keyName: "generic.expert" }) }), _jsx("button", { type: "button", onClick: function () { return setWorkflow("educational"); }, className: "text-sm font-normal py-1 px-3 rounded-3xl text-gray-500 ".concat(workflow === "educational" ? "bg-prighterblue text-white" : ""), children: _jsx(T, { keyName: "generic.educational" }) })] })] }), _jsx("div", { className: "flex items-center justify-between gap-4 px-4", children: _jsxs("div", { className: "flex items-center gap-4", children: [_jsx("div", { className: "w-4 h-4 flex items-center justify-center", children: _jsx(FontAwesomeIcon, { icon: "refresh", color: "gray" }) }), _jsx("h3", { className: "m-0 font-inter", children: _jsx(T, { keyName: "generic.data_subject_requests" }) })] }) }), _jsx("div", { className: "flex flex-col gap-4 px-3", children: Array.isArray(emails) &&
                    emails.map(function (email) {
                        return (_jsx(EmailCasesListItem, { email: email, fetchEmailById: fetchEmailById, workflow: workflow }, email.uuid));
                    }) })] }));
}
