import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getBeautyDate } from "helpers";
import HistoryEntryTemplate from "pages/Client/History/HistoryTemplates/HistoryEntryTemplate";
import tolgee from "services/translation/tolgee";
export default function CaseCreateInquiries(props) {
    var _a;
    var entityData = props.entityData, createdAt = props.createdAt;
    return (_jsx(HistoryEntryTemplate, { timeLineIcon: _jsx(FontAwesomeIcon, { icon: "question", color: "white", size: "lg" }), title: entityData.title, date: getBeautyDate(createdAt), processedAt: entityData.processed_at, children: _jsx("div", { className: "p-4 space-y-2", children: (_a = entityData === null || entityData === void 0 ? void 0 : entityData.inquiries) === null || _a === void 0 ? void 0 : _a.map(function (i) { return (_jsxs("div", { children: [i.question_i18n && (_jsx("div", { className: "flex flex-col", children: _jsxs("span", { children: [_jsx("b", { children: tolgee.t({
                                        key: "dsr.question",
                                    }) }), ": ", tolgee.t({
                                    key: "dsr.inquiry.".concat(i.question_i18n.key.toLowerCase(), "_title"),
                                })] }) })), i.question && (_jsx("div", { className: "flex flex-col", children: _jsxs("span", { children: [_jsx("b", { children: tolgee.t({
                                        key: "dsr.question",
                                    }) }), ": ", i.question] }) })), _jsx("div", { className: "flex flex-col", children: _jsxs("span", { children: [_jsx("b", { children: tolgee.t({
                                        key: "dsr.files_required",
                                    }) }), ": ", i.upload_required
                                    ? tolgee.t({
                                        key: "generic.yes",
                                    })
                                    : tolgee.t({
                                        key: "generic.no",
                                    })] }) })] }, i.uuid)); }) }) }));
}
