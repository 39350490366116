import { createTheme } from "@mui/material/styles";

import { PRIGHTER_BLUE, SUCCESS, WHITE } from "const/color";

const theme = createTheme({
  typography: {
    fontFamily: `"Arial", sans-serif`,
    button: {
      textTransform: "none",
    },
  },
  palette: {
    primary: {
      main: PRIGHTER_BLUE,
    },
    success: {
      main: SUCCESS.DEFAULT,
      contrastText: WHITE,
    },
  },
  components: {
    MuiTimelineItem: {
      styleOverrides: {
        root: {
          "&:before": {
            display: "none",
          },
        },
      },
    },
    MuiDataGrid: {
      defaultProps: {
        autoHeight: true,
        disableColumnResize: true,
      },
      styleOverrides: {
        root: {
          minHeight: 200,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
      styleOverrides: {
        root: {
          fontWeight: 600,
        },
      },
    },
  },
});

export default theme;
