import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
export default function EnhancedTableHead(props) {
    var order = props.order, orderBy = props.orderBy, _a = props.shouldSort, shouldSort = _a === void 0 ? true : _a, onRequestSort = props.onRequestSort, keys = props.keys, actionTitle = props.actionTitle, _b = props.noTableHead, noTableHead = _b === void 0 ? false : _b;
    var createSortHandler = function (property) { return function (event) {
        if (shouldSort) {
            onRequestSort(event, property);
        }
    }; };
    return (_jsx(TableHead, { children: _jsxs(TableRow, { style: { height: noTableHead ? "0px" : "60px" }, children: [keys.map(function (headCell) { return (_jsx(TableCell, { style: { backgroundColor: "#d9d9d9" }, sortDirection: orderBy === headCell ? order : false, children: shouldSort ? (_jsxs(TableSortLabel, { className: "font-lg font-bold", active: orderBy === headCell, direction: orderBy === headCell ? order : "asc", onClick: createSortHandler(headCell), children: [headCell, orderBy === headCell ? (_jsx("span", { style: {
                                    border: 0,
                                    clip: "rect(0 0 0 0)",
                                    height: 1,
                                    margin: -1,
                                    overflow: "hidden",
                                    padding: 0,
                                    position: "absolute",
                                    top: 20,
                                    width: 1,
                                }, children: order === "desc" ? "sorted descending" : "sorted ascending" })) : null] })) : (_jsx("span", { className: "font-lg font-bold", children: headCell })) }, headCell)); }), actionTitle && (_jsx(TableCell, { style: {
                        backgroundColor: "#d9d9d9",
                    }, className: "font-lg font-bold", align: "right", children: actionTitle })), keys.length === 0 && !noTableHead && _jsx(TableCell, { children: "Empty" })] }) }));
}
