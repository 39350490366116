import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ReactMarkdown from "react-markdown";
import tolgee from "services/translation";
export default function PreCheck() {
    return (_jsxs("div", { className: "grid gap-1 px-6 mb-3", children: [_jsx("h2", { className: "my-0 font-bold", children: tolgee.t({
                    key: "dsr.pre_check_title",
                }) }), _jsx(ReactMarkdown, { className: "prose max-w-[100%]", children: tolgee.t({
                    key: "dsr.pre_check_description",
                }) })] }));
}
