export var dataGridConfig = function (data) {
    var currentPage = data.currentPage, rowsPerPage = data.rowsPerPage, count = data.count, pageSizeOptions = data.pageSizeOptions, disableColumnMenu = data.disableColumnMenu, disableColumnFilter = data.disableColumnFilter, onRowClick = data.onRowClick, rowSelection = data.rowSelection;
    return {
        disableColumnFilter: disableColumnFilter || true,
        disableColumnMenu: disableColumnMenu || true,
        pageSizeOptions: pageSizeOptions || [25, 50, 100],
        paginationModel: { page: currentPage, pageSize: rowsPerPage },
        paginationMode: "server",
        rowCount: count,
        sortingMode: "server",
        onRowClick: onRowClick,
        rowSelection: rowSelection || false,
        sortingOrder: ["asc", "desc"],
        getRowHeight: function () { return "auto"; },
    };
};
