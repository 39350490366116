import { jsx as _jsx } from "react/jsx-runtime";
import { Step, StepLabel, Stepper } from "@mui/material";
var steps = ["Where", "What", "Who", "When", "Info"];
function EmailCasesStepper(props) {
    var activeStep = props.activeStep;
    return (_jsx(Stepper, { activeStep: activeStep + 1, alternativeLabel: true, sx: {
            "& .MuiStepConnector-line": {
                borderWidth: "1px",
                borderColor: "#848484",
            },
            "& .Mui-completed .MuiStepConnector-line": {
                borderColor: "white",
            },
        }, children: steps.map(function (label) { return (_jsx(Step, { children: _jsx(StepLabel, { sx: {
                    "& .MuiStepIcon-root": {
                        cursor: "pointer",
                        color: "white",
                        opacity: 0.5,
                    },
                    "& .MuiStepIcon-root:hover": {
                        color: "white",
                    },
                    "& .MuiStepLabel-label": {
                        color: "#EBEBEB",
                        opacity: 0.5,
                    },
                    "& .Mui-active": {
                        color: "white !important",
                    },
                    "& .Mui-completed": {
                        color: "white !important",
                        opacity: 1,
                    },
                }, children: label }) }, label)); }) }));
}
export default EmailCasesStepper;
