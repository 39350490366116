var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { TextField } from "@mui/material";
import { T } from "@tolgee/react";
import { BaseButton } from "components";
import { api } from "services";
import { notify } from "store/app";
import PrighterLogo from "components/Prighter/PrighterLogo";
export default function MarketingUnsubscribe() {
    var _this = this;
    var dispatch = useDispatch();
    var searchParams = useSearchParams()[0];
    var _a = useState(""), email = _a[0], setEmail = _a[1];
    var _b = useState(false), displayForm = _b[0], setDisplayForm = _b[1];
    var _c = useState(false), error = _c[0], setError = _c[1];
    useEffect(function () {
        if (searchParams.has("email_address")) {
            unsubscribe(searchParams.get("email_address"));
        }
        else {
            setDisplayForm(true);
        }
    }, [searchParams]);
    var unsubscribe = function (emailParam) { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.unsubscribeMarketingMails(emailParam)];
                case 1:
                    res = _a.sent();
                    if (res) {
                        dispatch(notify({ type: "SUCCESS", message: "You successfully unsubscribed." }));
                        setDisplayForm(false);
                    }
                    else {
                        setDisplayForm(true);
                        dispatch(notify({
                            type: "ERROR",
                            message: "Something is wrong. Please check your email, or try again later.",
                        }));
                        setError(true);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var handleSubmit = function () {
        unsubscribe(email);
    };
    return (_jsx("div", { className: "flex justify-center items-center w-full h-screen bg-gray-50", children: _jsxs("div", { className: "p-0 box-outerlayout flex flex-col items-center", children: [_jsxs("div", { className: "flex items-center justify-center w-full bg-gray-50 border-0 border-b border-solid py-1 mb-6", children: [_jsx(PrighterLogo, { className: "p-1 rounded-full", width: 36 }), _jsx("span", { className: "text-prighterblue font-bold text-3xl", children: _jsx(T, { keyName: "generic.prighter" }) })] }), _jsxs("div", { className: "px-16 pb-6 flex flex-col items-center text-center", children: [_jsx("span", { className: "text-xl text-gray-700", children: _jsx(T, { keyName: "marketing.unsubscribe" }) }), _jsx("span", { className: "text-gray-600 text-sm -mt-1 mb-6", children: _jsx(T, { keyName: "marketing.sorry_you_go" }) }), displayForm && (_jsxs(_Fragment, { children: [_jsxs("span", { className: "mb-4", children: [_jsx(T, { keyName: "marketing.are_you_sure" }), " ", _jsx("br", {}), _jsx(T, { keyName: "marketing.from_mails" })] }), _jsx("div", { className: "w-full mx-2 mb-2", children: _jsx(TextField, { type: "email", autoComplete: "on", className: clsx({ "border-danger-400 border-2": error }), required: true, label: "Email", value: email, onChange: function (text) { return setEmail(text.target.value); } }) }), _jsx(BaseButton, { variant: "outlined", className: "self-end", disabled: !email, onClick: handleSubmit, children: _jsx(T, { keyName: "marketing.unsubscribe" }) })] }))] })] }) }));
}
