import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getBeautyDate } from "helpers";
import HistoryEntryTemplate from "pages/Client/History/HistoryTemplates/HistoryEntryTemplate";
import tolgee from "services/translation/tolgee";
export default function CaseExtendDeadline(props) {
    var entityData = props.entityData, createdAt = props.createdAt;
    return (_jsx(HistoryEntryTemplate, { timeLineIcon: _jsx(FontAwesomeIcon, { icon: "calendar-days", color: "white", size: "lg" }), title: entityData.title, date: getBeautyDate(createdAt), processedAt: entityData.processed_at, children: _jsxs("div", { className: "p-4 space-y-2", children: [_jsxs("span", { children: [getBeautyDate(entityData.old), " \u2192 ", getBeautyDate(entityData.new)] }), entityData.reason && (_jsx("div", { className: "flex flex-col", children: _jsxs("span", { children: [_jsx("b", { children: tolgee.t({
                                    key: "dsr.reason",
                                }) }), ": ", entityData.reason] }) }))] }) }));
}
