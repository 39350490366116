import { useEffect, useState } from "react";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";

import stepsArray from "services/guide/steps/authorityInbox";
import { useAppDispatch, useAppSelector } from "store";
import { toggleGuideRunning } from "store/app";

export function GuideService() {
  const dispatch = useAppDispatch();
  const { running, guideType } = useAppSelector((state) => state.app.guide);
  const [state, setState] = useState({
    stepIndex: 0,
    steps: [],
  });

  useEffect(() => {
    switch (guideType) {
      case "AUTHORITY_INBOX":
      default:
        setState({
          stepIndex: 0,
          steps: stepsArray,
        });
        break;
    }
  }, [guideType]);

  const locale = {
    skip: <strong aria-label="skip">Finish the Guide</strong>,
  };

  const joyrideCallback = (data) => {
    const { action, index, status, type } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      dispatch(toggleGuideRunning(false));
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      setState({ ...state, stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
    }
  };

  return (
    <Joyride
      steps={state.steps}
      callback={joyrideCallback}
      run={running}
      continuous
      locale={locale}
      showProgress
      disableCloseOnEsc
      disableOverlayClose
      showSkipButton
      scrollOffset={80}
    />
  );
}
