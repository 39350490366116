export var preCheckStepsOrder = [
    "DS_IDENTIFICATION",
    "DS_LOCATION",
    "CONTROLLER_PROCESSOR",
];
export var handlingStepsOrder = [
    "CONTROLLER_ADDED",
    "EXEMPTIONS",
    "PRIVACY_POLICY_CATEGORIES",
    "OPTOUT_COMPLY",
    "PROCESSORS_ADDED",
    "INCORRECT_SOURCES",
    "CORRECT_DELETE",
    "INFORM_THIRD_PARTY",
    "DS_DATA_UPLOAD",
];
export var stageNames = [
    "PRE_CHECK",
    "PRE_CHECK_REVIEW",
    "HANDLING",
    "HANDLING_REVIEW",
];
export var newExemptions = {
    obligatory: 4,
    optional: 7,
};
export var exemptions = {
    personal_information: 4,
    related_to_consumer_request: 0,
    business_activities: 3,
    confidentiality_privilege: 2,
    legal_position: 2,
};
export var specificExemptions = {
    DSR_CCPA_DELETE: {
        personal_information: 3,
        related_to_consumer_request: 1,
        business_activities: 0,
        confidentiality_privilege: 0,
        legal_position: 5,
    },
    DSR_CCPA_ACCESS: {
        personal_information: 0,
        related_to_consumer_request: 0,
        business_activities: 0,
        confidentiality_privilege: 0,
        legal_position: 0,
    },
    DSR_CCPA_ACCESS_CATEGORIES: {
        personal_information: 9,
        related_to_consumer_request: 1,
        business_activities: 7,
        confidentiality_privilege: 0,
        legal_position: 1,
    },
    DSR_CCPA_CORRECT: {
        personal_information: 2,
        related_to_consumer_request: 4,
        business_activities: 0,
        confidentiality_privilege: 0,
        legal_position: 1,
    },
    DSR_CCPA_OPTOUT: {
        personal_information: 3,
        related_to_consumer_request: 1,
        business_activities: 0,
        confidentiality_privilege: 0,
        legal_position: 0,
    },
    DSR_CCPA_LIMIT: {
        personal_information: 0,
        related_to_consumer_request: 0,
        business_activities: 0,
        confidentiality_privilege: 0,
        legal_position: 0,
    },
};
export var dsrFormSteps = [
    {
        key: "type",
        name: "state.type_of_request",
    },
    {
        key: "identification",
        name: "state.provide_identification",
    },
    {
        key: "send",
        name: "state.send_request",
    },
];
export var ccpaRetainDataReasonCount = 3;
export var correctInformationExemptions = [
    "dsr.correctionData.exemptions.1",
    "dsr.correctionData.exemptions.2",
    "dsr.correctionData.exemptions.3",
    "dsr.correctionData.exemptions.4",
    "dsr.correctionData.exemptions.5",
    "dsr.correctionData.exemptions.6",
    "dsr.correctionData.exemptions.7",
];
