var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chip, IconButton } from "@mui/material";
import { T } from "@tolgee/react";
import { PRIGHTER_BLUE } from "const/color";
import api from "services/api";
import tolgee from "services/translation";
import { useAppDispatch } from "store";
import { notify } from "store/app";
export default function AddNotification(_a) {
    var _this = this;
    var notifications = _a.notifications, fetchNotifications = _a.fetchNotifications;
    var dispatch = useAppDispatch();
    var noNotifications = function () {
        return (_jsx("div", { className: "italic", children: _jsx(T, { keyName: "notifications.no_active" }) }));
    };
    var handleDelete = function (uuid) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            api.notifications.deleteNotification(uuid).then(function (res) {
                if (res) {
                    dispatch(notify({
                        message: tolgee.t({
                            key: "notifications.notifications_deleted",
                        }),
                        type: "SUCCESS",
                    }));
                    fetchNotifications();
                }
                else {
                    dispatch(notify({
                        message: tolgee.t({
                            key: "generic.smth_went_wrong",
                        }),
                        type: "ERROR",
                    }));
                }
            });
            return [2 /*return*/];
        });
    }); };
    var handleResendLink = function (uuid) { return __awaiter(_this, void 0, void 0, function () {
        var success;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.notifications.resendNotification(uuid)];
                case 1:
                    success = _a.sent();
                    if (success) {
                        dispatch(notify({
                            message: tolgee.t({
                                key: "notifications.resend_link_success",
                            }),
                            type: "SUCCESS",
                        }));
                        fetchNotifications();
                    }
                    else {
                        dispatch(notify({
                            message: tolgee.t({
                                key: "generic.smth_went_wrong",
                            }),
                            type: "ERROR",
                        }));
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var testNotification = function (uuid) { return __awaiter(_this, void 0, void 0, function () {
        var success;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.notifications.testNotification(uuid)];
                case 1:
                    success = _a.sent();
                    if (success) {
                        dispatch(notify({
                            message: tolgee.t({
                                key: "notifications.test_success",
                            }),
                            type: "SUCCESS",
                        }));
                        fetchNotifications();
                    }
                    else {
                        dispatch(notify({
                            message: tolgee.t({
                                key: "generic.smth_went_wrong",
                            }),
                            type: "ERROR",
                        }));
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx("div", { className: "border rounded px-4 py-6 space-y-2 mt-4", children: (notifications === null || notifications === void 0 ? void 0 : notifications.length) > 0
            ? notifications === null || notifications === void 0 ? void 0 : notifications.map(function (i) { return (_jsxs("div", { className: "flex justify-between", children: [_jsxs("div", { className: "flex space-x-4 items-center", children: [_jsx("div", { className: "font-bold", children: i.address }), _jsx("div", { children: _jsx(Chip, { label: tolgee.t({
                                        key: "notifications.".concat(i.type.toLowerCase()),
                                    }) }) }), i.validated ? (_jsx(FontAwesomeIcon, { icon: "check" })) : (_jsx("div", { className: "italic", children: tolgee.t({
                                    key: "notifications.waiting",
                                }) }))] }), _jsxs("div", { className: "flex space-x-4", children: [!i.validated && (_jsx("div", { children: _jsx(IconButton, { size: "medium", onClick: function () { return handleResendLink(i.uuid); }, children: _jsx("div", { className: "w-3 h-3 flex items-center justify-center", children: _jsx(FontAwesomeIcon, { icon: "redo", color: PRIGHTER_BLUE }) }) }) })), i.validated && (_jsx("div", { children: _jsx(IconButton, { size: "medium", onClick: function () { return testNotification(i.uuid); }, children: _jsx("div", { className: "w-3 h-3 flex items-center justify-center", children: _jsx(FontAwesomeIcon, { icon: "paper-plane", color: PRIGHTER_BLUE }) }) }) })), i.deletable && (_jsx("div", { children: _jsx(IconButton, { size: "medium", onClick: function () { return handleDelete(i.uuid); }, children: _jsx("div", { className: "w-3 h-3 flex items-center justify-center", children: _jsx(FontAwesomeIcon, { icon: "trash", color: PRIGHTER_BLUE }) }) }) }, i.uuid))] })] }, i.uuid)); })
            : noNotifications() }));
}
