import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import ServerError from "pages/General/Error/ServerError";

export default function ErrorFallback({ resetError }) {
  const location = useLocation();
  const [initialLocation, _] = useState(location.pathname);

  useEffect(() => {
    if (initialLocation !== location.pathname) {
      resetError();
    }
  }, [initialLocation, location, resetError]);

  return <ServerError />;
}
