var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { API_BASE_ROUTE, PRIGHTER_URL } from "const/env";
export function getUrl(type, path, params) {
    var finalPath = path;
    if (type === "API" && API_BASE_ROUTE.endsWith("/api")) {
        finalPath = "/api".concat(path);
    }
    var route = new URL(finalPath, type === "API" ? API_BASE_ROUTE : PRIGHTER_URL);
    if (params) {
        route.search = params.toString();
    }
    return route.toString();
}
export function getFileIcon(type) {
    var fileType = type.toLowerCase();
    switch (true) {
        case fileType.startsWith("image"):
            return "file-image";
        case fileType.startsWith("video"):
            return "file-video";
        case fileType.startsWith("text"):
            return "file-text";
        case fileType.includes("pdf"):
            return "file-pdf";
        case fileType.includes("word"):
            return "file-word";
        case ["zip", "tar", "rar"].some(function (el) { return fileType.includes(el); }):
            return "file-zipper";
        case fileType.includes("excel"):
            return "file-excel";
        default:
            return "file";
    }
}
export function getBeautyDateTime(dateTimeRaw) {
    if (!dateTimeRaw) {
        return "No Date";
    }
    return new Date(dateTimeRaw).toLocaleString(undefined, {
        hour12: false,
    });
}
export function getBeautyDate(dateTimeRaw, long) {
    if (!dateTimeRaw) {
        return "No Date";
    }
    if (long) {
        return new Date(dateTimeRaw).toLocaleDateString(undefined, {
            year: "numeric",
            month: "long",
            day: "numeric",
        });
    }
    return new Date(dateTimeRaw).toLocaleDateString();
}
export function getImgSrc(src) {
    return "".concat(PRIGHTER_URL).concat(src);
}
export function reorderArrayElements(from, to, array) {
    array.splice(to, 0, array.splice(from, 1)[0]);
}
export function getCountryAsOptions(countries) {
    return countries.map(function (country) { return ({
        key: country.iso_3166_1_alpha_2,
        label: country.flag ? "".concat(country.flag, " ").concat(country.name) : country.name,
    }); });
}
export function objectDeepCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
}
export function snakeCaseToSentenceCase(word) {
    return word
        .split("_")
        .map(function (state) { return state.charAt(0).toUpperCase() + state.slice(1); })
        .join(" ");
}
export function getFileName(file) {
    var _a;
    return ((_a = file === null || file === void 0 ? void 0 : file.name_original) === null || _a === void 0 ? void 0 : _a.length) > 0 ? "".concat(file.name, " (").concat(file.name_original, ")") : file.name;
}
export function isEmpty(object) {
    return Object.values(object).every(function (x) { return x === null || x === ""; });
}
export function redirectToFlaskUrl(url) {
    window.location.assign(getUrl("FLASK", url));
}
export var getInputValue = function (dict, key) {
    return dict && dict[key] ? dict[key] : null;
};
export var isString = function (value) {
    return typeof value === "string" || value instanceof String;
};
export var isStringEmpty = function (value) {
    return !value || (isString(value) && value && value.trim() === "");
};
export var fileToFileObject = function (file) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, new Promise(function (resolve, reject) {
                var reader = new FileReader();
                reader.onloadend = function () {
                    var base64String = reader.result;
                    resolve({
                        name: file.name,
                        content: base64String,
                        mime_type: file.type,
                        name_original: file.name,
                    });
                };
                reader.onerror = reject;
                reader.readAsDataURL(file);
            })];
    });
}); };
