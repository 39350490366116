import { faker } from "faker";
import { DEV_ENV } from "const/env";
var initialFadpObject = [
    {
        checked: DEV_ENV,
        type: "DSR_FADP_DELETE",
        inquiries: [
            {
                question_i18n: {
                    key: "fadp.rights_form.request_refers_to_following_personal_info",
                    lang: "en",
                },
                answer: faker.lorem.words(20),
            },
        ],
    },
    {
        checked: DEV_ENV,
        type: "DSR_FADP_ACCESS",
        inquiries: [
            {
                question_i18n: {
                    key: "fadp.rights_form.access_request_title",
                    lang: "en",
                },
                answer_i18n: {
                    key: "fadp.rights_form.categories_of_personal_information",
                    lang: "en",
                },
            },
            {
                question_i18n: {
                    key: "fadp.rights_form.my_request_concerns_following_information",
                    lang: "en",
                },
                answer: "",
            },
        ],
    },
    {
        checked: DEV_ENV,
        type: "DSR_FADP_CORRECT",
        inquiries: [
            {
                question_i18n: {
                    key: "fadp.rights_form.request_related_to_following_personal_information",
                    lang: "en",
                },
            },
            {
                question_i18n: {
                    key: "fadp.rights_form.personal_information_is_wrong_or_incomplete_because",
                    lang: "en",
                },
            },
            {
                question_i18n: {
                    key: "fadp.rights_form.amend_the_personal_information_as_follows",
                    lang: "en",
                },
            },
        ],
    },
    { checked: DEV_ENV, type: "DSR_FADP_AUTOMATED_DECISION", inquiries: [] },
    { checked: DEV_ENV, type: "DSR_FADP_PROHIBIT_DATA_PROCESSING", inquiries: [] },
];
export var fadpFormInitialValues = {
    dsrs: initialFadpObject,
    actors: [
        {
            name: faker.person.fullName(),
            email: faker.internet.exampleEmail(),
            acting_for: DEV_ENV ? "MYSELF" : "",
            address: {
                street: "",
                postal: "",
                city: "",
                country_iso: "",
            },
        },
    ],
    identifiable_info: {
        username: "",
        email: "",
        customer_number: "",
        other: "",
    },
    files: [],
    request_privacy_related: DEV_ENV,
};
