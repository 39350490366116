var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import { T } from "@tolgee/react";
import { Button } from "@mui/material";
import CategoriesCollected from "components/DSRSettings/CCPA/CategoriesCollected";
import DisclosingData from "components/DSRSettings/CCPA/DisclosingData";
import SharingData from "components/DSRSettings/CCPA/SharingData";
import { api } from "services";
import tolgee from "services/translation";
import { categoriesCollectedData, sharedSoldData, disclosedData } from "const/ccpa";
import { BaseButton, BaseModal } from "components";
import PrivacyPolicyDsr from "components/DSRSettings/CCPA/PrivacyPolicyDsr";
import { useCase } from "pages/Client/Case/CaseContext";
import { isCheckFinished } from "helpers/case";
import { finalizeCheck } from "store/thunks";
export default function AccessCategories(_a) {
    var _this = this;
    var businessId = _a.businessId, caseId = _a.caseId;
    var _b = useCase(), state = _b[0], dispatch = _b[1];
    var isFirstRender = useRef(true);
    var isFinished = isCheckFinished(state);
    var caseData = state.case;
    var _c = useState({
        isModalDisplayed: false,
        item: null,
    }), alreadySelectedModal = _c[0], setAlreadySelectedModal = _c[1];
    var _d = useState(false), privacyPolicyDsrEnabled = _d[0], setPrivacyPolicyDsrEnabled = _d[1];
    var _e = useState({
        isEnabled: true,
        selectedItems: [],
        options: [],
    }), categoriesCollected = _e[0], setCategoriesCollected = _e[1];
    var _f = useState({
        isEnabled: false,
        selectedItems: [],
        options: [],
    }), sharingData = _f[0], setSharingData = _f[1];
    var _g = useState({
        isEnabled: false,
        selectedItems: [],
        options: [],
    }), disclosingData = _g[0], setDisclosingData = _g[1];
    useEffect(function () {
        handleBackendCcpaSettings(caseData.outcome);
    }, [businessId]);
    useEffect(function () {
        if (isFirstRender.current) {
            // Skip the first render
            isFirstRender.current = false;
            return;
        }
        var payloadData = {
            categories_collected: categoriesCollected.selectedItems
                .filter(function (dataItem) { return dataItem.value; })
                .map(function (mapItem) { return mapItem.key; }) || [],
            categories_shared: prepareCategories(sharingData),
            categories_disclosed: prepareCategories(disclosingData),
            sell_share: sharingData.isEnabled,
            disclose: disclosingData.isEnabled,
            collect: categoriesCollected.isEnabled,
            send_privacy_policy: privacyPolicyDsrEnabled,
        };
        putDataToBackend(payloadData);
    }, [
        categoriesCollected.isEnabled,
        categoriesCollected.selectedItems,
        sharingData.isEnabled,
        sharingData.selectedItems,
        disclosingData.isEnabled,
        disclosingData.selectedItems,
        privacyPolicyDsrEnabled,
    ]);
    useEffect(function () {
        // Update sharingData & disclosingData options
        var newShareDataOptions = __spreadArray([], sharingData.options, true);
        var newDisclosingDataOptions = __spreadArray([], disclosingData.options, true);
        var _loop_1 = function (i) {
            if (categoriesCollected.selectedItems.find(function (item) { return item.key === newShareDataOptions[i].dataToSell.key; })) {
                newShareDataOptions[i].isAvailable = true;
            }
            else {
                newShareDataOptions[i].isAvailable = false;
            }
        };
        for (var i = 0; i < newShareDataOptions.length; i += 1) {
            _loop_1(i);
        }
        var _loop_2 = function (i) {
            if (categoriesCollected.selectedItems.find(function (item) { return item.key === newDisclosingDataOptions[i].dataToSell.key; })) {
                newDisclosingDataOptions[i].isAvailable = true;
            }
            else {
                newDisclosingDataOptions[i].isAvailable = false;
            }
        };
        for (var i = 0; i < newDisclosingDataOptions.length; i += 1) {
            _loop_2(i);
        }
        if (newShareDataOptions.length > 0) {
            setSharingData(function (prevState) { return (__assign(__assign({}, prevState), { options: newShareDataOptions })); });
        }
        if (newDisclosingDataOptions.length > 0) {
            setDisclosingData(function (prevState) { return (__assign(__assign({}, prevState), { options: newDisclosingDataOptions })); });
        }
    }, [categoriesCollected.selectedItems]);
    var putDataToBackend = debounce(function (data) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.ccpa.updateDsrCaseCategories(caseId, data)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, 500);
    var prepareCategories = function (data) {
        var obj = {};
        for (var i = 0; i < (data === null || data === void 0 ? void 0 : data.selectedItems.length); i += 1) {
            if (data.selectedItems[i].dataToSell.key && data.selectedItems[i].sellTo.length > 0) {
                obj[data.selectedItems[i].dataToSell.key] = data.selectedItems[i].sellTo.map(function (item) { return item.key; });
            }
        }
        return obj;
    };
    var handleBackendCcpaSettings = function (data) { return __awaiter(_this, void 0, void 0, function () {
        var formattedCategoriesCollectedItems, formattedSharingDataOptions, formattedDisclosingDataOptions;
        return __generator(this, function (_a) {
            // Privacy Policy
            setPrivacyPolicyDsrEnabled(data.send_privacy_policy || false);
            formattedCategoriesCollectedItems = categoriesCollectedData.map(function (item) { return ({
                key: item,
                value: tolgee.t({ key: "".concat(item) }),
                selected: false,
            }); });
            setCategoriesCollected(function (prevState) { return (__assign(__assign({}, prevState), { options: formattedCategoriesCollectedItems })); });
            handleCategoriesCollectedInitialValue(data, formattedCategoriesCollectedItems);
            formattedSharingDataOptions = sharedSoldData.map(function (item) { return ({
                dataToSell: {
                    key: item.dataToSell,
                    value: tolgee.t({ key: "".concat(item.dataToSell) }),
                },
                sellOptions: item.sellOptions.map(function (sellOptionItem) { return ({
                    key: sellOptionItem,
                    value: tolgee.t({ key: "".concat(sellOptionItem) }),
                }); }),
                selected: false,
                isAvailable: false,
            }); });
            setSharingData(function (prevState) { return (__assign(__assign({}, prevState), { options: formattedSharingDataOptions })); });
            handleSharingDataInitialValue(data, formattedSharingDataOptions);
            formattedDisclosingDataOptions = disclosedData.map(function (item) { return ({
                dataToSell: {
                    key: item.dataToSell,
                    value: tolgee.t({ key: "".concat(item.dataToSell) }),
                },
                sellOptions: item.sellOptions.map(function (sellOptionItem) { return ({
                    key: sellOptionItem,
                    value: tolgee.t({ key: "".concat(sellOptionItem) }),
                }); }),
                selected: false,
                isAvailable: false,
            }); });
            setDisclosingData(function (prevState) { return (__assign(__assign({}, prevState), { options: formattedDisclosingDataOptions })); });
            handleDisclosingDataInitialValue(data, formattedDisclosingDataOptions);
            return [2 /*return*/];
        });
    }); };
    var handleCategoriesCollectedInitialValue = function (data, options) {
        var items = [];
        var localOptions = options;
        if (data.categories_collected) {
            var _loop_3 = function (i) {
                var collectedItem = {
                    id: items.length + 1,
                    key: options.find(function (formattedItem) { return formattedItem.key === data.categories_collected[i]; })
                        .key,
                    value: options.find(function (item) { return item.key === data.categories_collected[i]; }).value,
                };
                localOptions.find(function (item) { return item.key === data.categories_collected[i]; }).selected = true;
                items.push(collectedItem);
            };
            for (var i = 0; i < data.categories_collected.length; i += 1) {
                _loop_3(i);
            }
        }
        setCategoriesCollected(function (prevState) { return (__assign(__assign({}, prevState), { isEnabled: data.collect, selectedItems: items, options: localOptions })); });
    };
    var handleSharingDataInitialValue = function (data, options) {
        var shareData = data.categories_shared;
        var localOptions = __spreadArray([], options, true);
        var shareDataItems = [];
        Object.keys(shareData).forEach(function (key) {
            var formattedItem = options.find(function (item) { return item.dataToSell.key === key; });
            var selectedItem = {
                dataToSell: formattedItem.dataToSell,
                sellOptions: formattedItem.sellOptions,
                // @ts-expect-error ---
                sellTo: shareData[key].map(function (val) {
                    return formattedItem.sellOptions.find(function (option) { return option.key === val; });
                }),
            };
            shareDataItems.push(selectedItem);
            localOptions.find(function (item) { return item.dataToSell.key === selectedItem.dataToSell.key; }).selected =
                true;
        });
        if (shareDataItems.length === 0) {
            var item = {
                dataToSell: {
                    key: "",
                    value: "",
                },
                sellOptions: [],
                sellTo: [],
            };
            shareDataItems.push(item);
        }
        setSharingData(function (prevState) { return (__assign(__assign({}, prevState), { isEnabled: data.sell_share, selectedItems: shareDataItems, options: localOptions })); });
    };
    var handleDisclosingDataInitialValue = function (data, options) {
        var discloseData = data.categories_disclosed;
        var discloseDataItems = [];
        Object.keys(discloseData).forEach(function (key) {
            var formattedItem = options.find(function (item) { return item.dataToSell.key === key; });
            var selectedItem = {
                dataToSell: formattedItem.dataToSell,
                sellOptions: formattedItem.sellOptions,
                // @ts-expect-error ---
                sellTo: discloseData[key].map(function (val) {
                    return formattedItem.sellOptions.find(function (option) { return option.key === val; });
                }),
            };
            discloseDataItems.push(selectedItem);
            var newData = __spreadArray([], options, true);
            newData.find(function (item) { return item.dataToSell.key === selectedItem.dataToSell.key; }).selected = true;
            setDisclosingData(function (prevState) { return (__assign(__assign({}, prevState), { options: newData })); });
        });
        if (discloseDataItems.length === 0) {
            var item = {
                dataToSell: {
                    key: "",
                    value: "",
                },
                sellOptions: [],
                sellTo: [],
            };
            discloseDataItems.push(item);
        }
        setDisclosingData(function (prevState) { return (__assign(__assign({}, prevState), { isEnabled: data.disclose, selectedItems: discloseDataItems })); });
    };
    var handleCategoriesCollectedSelect = function (updatedItem, updatedValue) {
        var updatedDataItems = categoriesCollected.selectedItems.map(function (item) {
            return item.id === updatedItem.id
                ? __assign(__assign({}, item), { key: categoriesCollected.options.find(function (dataItem) { return dataItem.value === updatedValue; })
                        .key, value: updatedValue }) : item;
        });
        var updatedOptions = categoriesCollected.options.map(function (item) {
            // Update selected one
            if (item.value === updatedValue) {
                return __assign(__assign({}, item), { selected: !item.selected });
            }
            // Update previously selected one
            if (item.value === updatedItem.value) {
                return __assign(__assign({}, item), { selected: false });
            }
            // Rest of items just return
            return item;
        });
        setCategoriesCollected(function (prevState) { return (__assign(__assign({}, prevState), { selectedItems: updatedDataItems, options: updatedOptions })); });
    };
    var handleCategoriesCollectedDeletion = function (deletionItem) {
        if (sharingData.selectedItems.find(function (item) { return item.dataToSell.key === deletionItem.key; }) ||
            disclosingData.selectedItems.find(function (item) { return item.dataToSell.key === deletionItem.key; })) {
            setAlreadySelectedModal(function (prevState) { return (__assign(__assign({}, prevState), { isModalDisplayed: true, item: deletionItem })); });
            return;
        }
        deleteCategoriesCollectedItem(deletionItem);
    };
    var deleteCategoriesCollectedItem = function (deletionItem) {
        var updatedItems = __spreadArray([], categoriesCollected.selectedItems, true);
        var itemIndex = updatedItems.findIndex(function (item) { return item.key === deletionItem.key; });
        updatedItems.splice(itemIndex, 1);
        var updatedOptions = categoriesCollected.options.map(function (item) {
            if (item.key === deletionItem.key) {
                return __assign(__assign({}, item), { selected: !item.selected });
            }
            return item;
        });
        setCategoriesCollected(function (prevState) { return (__assign(__assign({}, prevState), { selectedItems: updatedItems, options: updatedOptions })); });
    };
    var deleteAlreadySelectedItem = function (deletionItem) {
        deleteCategoriesCollectedItem(deletionItem);
        if (sharingData.selectedItems.find(function (item) { return item.dataToSell.key === deletionItem.key; })) {
            handleSharingDataDeletion(sharingData.selectedItems.find(function (item) { return item.dataToSell.key === deletionItem.key; }));
        }
        if (disclosingData.selectedItems.find(function (item) { return item.dataToSell.key === deletionItem.key; })) {
            handleDisclosingDataDeletion(disclosingData.selectedItems.find(function (item) { return item.dataToSell.key === deletionItem.key; }));
        }
    };
    var addEmptyCategoriesCollectedData = function () {
        var item = {
            id: categoriesCollected.selectedItems.length + 1,
            key: "",
            value: "",
        };
        var newDataItems = __spreadArray([], categoriesCollected.selectedItems, true);
        newDataItems.push(item);
        setCategoriesCollected(function (prevState) { return (__assign(__assign({}, prevState), { selectedItems: newDataItems })); });
    };
    var handleSharingDataCategory1Select = function (selectedItem, index, value) {
        var localItem = selectedItem;
        var newData = __spreadArray([], sharingData.options, true);
        if (localItem.dataToSell.value !== "") {
            newData.find(function (item) { return item.dataToSell.value === localItem.dataToSell.value; }).selected = false;
        }
        newData.find(function (item) { return item.dataToSell.value === value; }).selected = true;
        setSharingData(function (prevState) { return (__assign(__assign({}, prevState), { options: newData })); });
        if (localItem.dataToSell.value !== value) {
            localItem = {
                dataToSell: {
                    key: sharingData.options.find(function (item) { return item.dataToSell.value === value; }).dataToSell.key,
                    value: value,
                },
                sellTo: [],
                sellOptions: sharingData.options.filter(function (item) { return item.dataToSell.value === value; })[0]
                    .sellOptions,
            };
        }
        else {
            localItem = __assign(__assign({}, selectedItem), { dataToSell: {
                    key: sharingData.options.find(function (item) { return item.dataToSell.value === value; }).dataToSell.key,
                    value: value,
                }, sellOptions: sharingData.options.filter(function (item) { return item.dataToSell.value === value; })[0]
                    .sellOptions });
        }
        var updatedItems = __spreadArray(__spreadArray(__spreadArray([], sharingData.selectedItems.slice(0, index), true), [
            localItem
        ], false), sharingData.selectedItems.slice(index + 1), true);
        setSharingData(function (prevState) { return (__assign(__assign({}, prevState), { selectedItems: updatedItems })); });
    };
    var handleSharingDataCategory2Select = function (selectedItem, index, value) {
        var item = __assign(__assign({}, selectedItem), { sellTo: value.map(function (val) {
                return selectedItem.sellOptions.find(function (option) { return option.value === val; });
            }) });
        var updatedItems = __spreadArray(__spreadArray(__spreadArray([], sharingData.selectedItems.slice(0, index), true), [
            item
        ], false), sharingData.selectedItems.slice(index + 1), true);
        setSharingData(function (prevState) { return (__assign(__assign({}, prevState), { selectedItems: updatedItems })); });
    };
    var handleSharingDataDeletion = function (deletionItem) {
        var updatedItems = __spreadArray([], sharingData.selectedItems, true);
        var itemIndex = updatedItems.findIndex(function (item) { return item.dataToSell.key === deletionItem.dataToSell.key; });
        updatedItems.splice(itemIndex, 1);
        var updatedOptions = sharingData.options.map(function (item) {
            if (item.dataToSell.key === deletionItem.dataToSell.key) {
                return __assign(__assign({}, item), { selected: !item.selected });
            }
            return item;
        });
        setSharingData(function (prevState) { return (__assign(__assign({}, prevState), { selectedItems: updatedItems, options: updatedOptions })); });
    };
    var addEmptySharingDataCategory = function () {
        var item = {
            dataToSell: {
                key: "",
                value: "",
            },
            sellOptions: [],
            sellTo: [],
        };
        var updatedItems = __spreadArray(__spreadArray([], sharingData.selectedItems, true), [item], false);
        setSharingData(function (prevState) { return (__assign(__assign({}, prevState), { selectedItems: updatedItems })); });
    };
    var handleDisclosingDataCategory1Select = function (selectedItem, index, value) {
        var localItem = selectedItem;
        var newData = __spreadArray([], disclosingData.options, true);
        if (localItem.dataToSell.value !== "") {
            newData.find(function (item) { return item.dataToSell.value === localItem.dataToSell.value; }).selected = false;
        }
        newData.find(function (item) { return item.dataToSell.value === value; }).selected = true;
        setDisclosingData(function (prevState) { return (__assign(__assign({}, prevState), { options: newData })); });
        if (localItem.dataToSell.value !== value) {
            localItem = {
                dataToSell: {
                    key: disclosingData.options.find(function (item) { return item.dataToSell.value === value; }).dataToSell
                        .key,
                    value: value,
                },
                sellTo: [],
                sellOptions: disclosingData.options.filter(function (item) { return item.dataToSell.value === value; })[0]
                    .sellOptions,
            };
        }
        else {
            localItem = __assign(__assign({}, selectedItem), { dataToSell: {
                    key: disclosingData.options.find(function (item) { return item.dataToSell.value === value; }).dataToSell
                        .key,
                    value: value,
                }, sellOptions: disclosingData.options.filter(function (item) { return item.dataToSell.value === value; })[0]
                    .sellOptions });
        }
        var updatedItems = __spreadArray(__spreadArray(__spreadArray([], disclosingData.selectedItems.slice(0, index), true), [
            localItem
        ], false), disclosingData.selectedItems.slice(index + 1), true);
        setDisclosingData(function (prevState) { return (__assign(__assign({}, prevState), { selectedItems: updatedItems })); });
    };
    var handleDisclosingDataCategory2Select = function (selectedItem, index, value) {
        var item = __assign(__assign({}, selectedItem), { sellTo: value.map(function (val) {
                return selectedItem.sellOptions.find(function (option) { return option.value === val; });
            }) });
        var updatedItems = __spreadArray(__spreadArray(__spreadArray([], disclosingData.selectedItems.slice(0, index), true), [
            item
        ], false), disclosingData.selectedItems.slice(index + 1), true);
        setDisclosingData(function (prevState) { return (__assign(__assign({}, prevState), { selectedItems: updatedItems })); });
    };
    var handleDisclosingDataDeletion = function (deletionItem) {
        var updatedItems = __spreadArray([], disclosingData.selectedItems, true);
        var itemIndex = updatedItems.findIndex(function (item) { return item.dataToSell.key === deletionItem.dataToSell.key; });
        updatedItems.splice(itemIndex, 1);
        var updatedOptions = disclosingData.options.map(function (item) {
            if (item.dataToSell.key === deletionItem.dataToSell.key) {
                return __assign(__assign({}, item), { selected: !item.selected });
            }
            return item;
        });
        setDisclosingData(function (prevState) { return (__assign(__assign({}, prevState), { selectedItems: updatedItems, options: updatedOptions })); });
    };
    var addEmptyDisclosingDataCategory = function () {
        var item = {
            dataToSell: {
                key: "",
                value: "",
            },
            sellOptions: [],
            sellTo: [],
        };
        var updatedItems = __spreadArray(__spreadArray([], disclosingData.selectedItems, true), [item], false);
        setDisclosingData(function (prevState) { return (__assign(__assign({}, prevState), { selectedItems: updatedItems })); });
    };
    var handleSubmitCategories = function () { return __awaiter(_this, void 0, void 0, function () {
        var decisionName;
        return __generator(this, function (_a) {
            decisionName = privacyPolicyDsrEnabled ? "SEND_PRIVACY_POLICY" : "SEND_CATEGORIES";
            finalizeCheck()(dispatch, state.case.uuid, "PRIVACY_POLICY_CATEGORIES", decisionName);
            return [2 /*return*/];
        });
    }); };
    return (_jsxs("div", { children: [_jsx("div", { className: "flex justify-end mb-8", children: _jsx(Button, { color: "success", disabled: isFinished, onClick: handleSubmitCategories, children: _jsx(T, { keyName: "ccpa.check.privacy_policy_categories" }) }) }), _jsxs("div", { className: isFinished ? "pointer-events-none" : "pointer-events-auto", children: [_jsx("div", { className: "mb-4", children: _jsx(PrivacyPolicyDsr, { isEnabled: privacyPolicyDsrEnabled, handleEnabled: function (value) {
                                setPrivacyPolicyDsrEnabled(value);
                            } }) }), !privacyPolicyDsrEnabled ? (_jsxs("div", { children: [_jsx("div", { className: "mb-4", children: _jsx(CategoriesCollected, { isEnabled: categoriesCollected.isEnabled, categoriesCollectedData: categoriesCollected.selectedItems, categoriesCollectedOptions: categoriesCollected.options, handleEnabled: function (value) {
                                        setCategoriesCollected(function (prevState) { return (__assign(__assign({}, prevState), { isEnabled: value })); });
                                        // If disabled then clear and disable sharingData & disclosingData
                                        if (!value) {
                                            setSharingData(function (prevState) { return (__assign(__assign({}, prevState), { isEnabled: false, selectedItems: [] })); });
                                            setDisclosingData(function (prevState) { return (__assign(__assign({}, prevState), { isEnabled: false, selectedItems: [] })); });
                                        }
                                    }, handleSelection: handleCategoriesCollectedSelect, handleDeletion: handleCategoriesCollectedDeletion, addEmptyCategory: addEmptyCategoriesCollectedData }) }), _jsx(BaseModal, { modalState: [
                                    alreadySelectedModal.isModalDisplayed,
                                    function (isDisplayed) {
                                        return setAlreadySelectedModal(function (prev) { return (__assign(__assign({}, prev), { isModalDisplayed: isDisplayed })); });
                                    },
                                ], children: _jsxs("div", { className: "w-[40rem] overflow-auto", children: [_jsx("div", { className: "mb-4", children: _jsx(T, { keyName: "dsr.settings.ccpa.categories_collected.item_already_selected" }) }), _jsxs("div", { className: "flex flex-row justify-end gap-6", children: [_jsx(BaseButton, { color: "error", variant: "outlined", onClick: function () {
                                                        setAlreadySelectedModal(function (prevState) { return (__assign(__assign({}, prevState), { isModalDisplayed: false })); });
                                                        deleteAlreadySelectedItem(alreadySelectedModal.item);
                                                    }, children: _jsx(T, { keyName: "generic.yes" }) }), _jsx(BaseButton, { color: "primary", onClick: function () {
                                                        return setAlreadySelectedModal(function (prevState) { return (__assign(__assign({}, prevState), { isModalDisplayed: false, item: null })); });
                                                    }, children: _jsx(T, { keyName: "generic.no" }) })] })] }) }), categoriesCollected.isEnabled ? (_jsxs("div", { children: [_jsx("div", { className: "mb-4", children: _jsx(SharingData, { isEnabled: sharingData.isEnabled, shareData: sharingData.selectedItems, shareDataOptions: sharingData.options, handleEnabled: function (value) {
                                                var initialItem = {
                                                    dataToSell: {
                                                        key: "",
                                                        value: "",
                                                    },
                                                    sellOptions: [],
                                                    sellTo: [],
                                                };
                                                setSharingData(function (prevState) { return (__assign(__assign({}, prevState), { isEnabled: value, selectedItems: value ? [initialItem] : [] })); });
                                            }, handleCategory1Select: handleSharingDataCategory1Select, handleCategory2Select: handleSharingDataCategory2Select, handleDeletion: handleSharingDataDeletion, addEmptyCategory: addEmptySharingDataCategory }) }), _jsx("div", { className: "mb-4", children: _jsx(DisclosingData, { isEnabled: disclosingData.isEnabled, discloseData: disclosingData.selectedItems, discloseDataOptions: disclosingData.options, handleEnabled: function (value) {
                                                var initialItem = {
                                                    dataToSell: {
                                                        key: "",
                                                        value: "",
                                                    },
                                                    sellOptions: [],
                                                    sellTo: [],
                                                };
                                                setDisclosingData(function (prevState) { return (__assign(__assign({}, prevState), { isEnabled: value, selectedItems: value ? [initialItem] : [] })); });
                                            }, handleCategory1Select: handleDisclosingDataCategory1Select, handleCategory2Select: handleDisclosingDataCategory2Select, handleDeletion: handleDisclosingDataDeletion, addEmptyCategory: addEmptyDisclosingDataCategory }) })] })) : ("")] })) : ("")] })] }));
}
