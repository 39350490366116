import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import tolgee from "services/translation";
function Row(props) {
    var row = props.row, columns = props.columns;
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    return (_jsxs(_Fragment, { children: [_jsxs(TableRow, { sx: { "& > *": { borderBottom: "unset" } }, children: [_jsx(TableCell, { children: _jsx(IconButton, { "aria-label": "expand row", size: "small", onClick: function () { return setOpen(!open); }, children: _jsx(FontAwesomeIcon, { icon: open ? "arrow-up" : "arrow-down" }) }) }), columns.map(function (column) { return (_jsx(TableCell, { component: "th", scope: "row", children: row[column.field] })); })] }), _jsx(TableRow, { children: _jsx(TableCell, { style: { paddingBottom: 0, paddingTop: 0 }, colSpan: 6, children: _jsx(Collapse, { in: open, timeout: "auto", unmountOnExit: true, children: _jsx(Box, { sx: { margin: 1 }, className: "pt-4", children: row.collapsible }) }) }) })] }));
}
export default function CollapsibleTable(props) {
    var data = props.data, columns = props.columns;
    return (_jsx("div", { className: "mx-auto max-w-7xl", children: (data === null || data === void 0 ? void 0 : data.length) > 0 ? (_jsx(TableContainer, { component: Paper, children: _jsxs(Table, { "aria-label": "collapsible table", children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, {}), columns.map(function (column) { return (_jsx(TableCell, { children: column.name })); })] }) }), _jsx(TableBody, { children: data === null || data === void 0 ? void 0 : data.map(function (row, idx) { return _jsx(Row, { row: row, columns: columns }, idx); }) })] }) })) : (_jsx("div", { children: tolgee.t({
                key: "billing.no_data",
            }) })) }));
}
