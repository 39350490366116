export var CorrectExemptionResultEnum;
(function (CorrectExemptionResultEnum) {
    CorrectExemptionResultEnum["PENDING"] = "PENDING";
    CorrectExemptionResultEnum["CONFIRM"] = "CONFIRM";
    CorrectExemptionResultEnum["DENY"] = "DENY";
})(CorrectExemptionResultEnum || (CorrectExemptionResultEnum = {}));
export var CaseTagNameEnum;
(function (CaseTagNameEnum) {
    CaseTagNameEnum["EMAIL_VERIFIED"] = "EMAIL_VERIFIED";
    CaseTagNameEnum["IDENTIFICATION_PENDING"] = "IDENTIFICATION_PENDING";
    CaseTagNameEnum["PROCESSING"] = "PROCESSING";
    CaseTagNameEnum["EMAIL_NOT_VERIFIED"] = "EMAIL_NOT_VERIFIED";
    CaseTagNameEnum["CHECK_RESPONSIBILITY"] = "CHECK_RESPONSIBILITY";
    CaseTagNameEnum["DEADLINE_EXTENDED"] = "DEADLINE_EXTENDED";
})(CaseTagNameEnum || (CaseTagNameEnum = {}));
