import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { T } from "@tolgee/react";
import { useStripe } from "@stripe/react-stripe-js";
import { useDispatch } from "react-redux";
import { captureException } from "@sentry/react";
import PrighterLogo from "components/Prighter/PrighterLogo";
import { COMPANY_FULL_NAME, COMPANY_FULL_NAME_WITH_COM } from "const/context";
import { notify } from "store/app";
export default function InvoicePaidBox() {
    var stripe = useStripe();
    var dispatch = useDispatch();
    var _a = useState(), paymentIntent = _a[0], setPaymentIntent = _a[1];
    var _b = useState(), errorMessage = _b[0], setErrorMessage = _b[1];
    useEffect(function () {
        var fetchPaymentIntent = function () {
            var clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
            if (!clientSecret) {
                setErrorMessage("Asn error occurred. Please try again later.");
                return;
            }
            stripe.retrievePaymentIntent(clientSecret).then(function (pi) {
                if (pi.error) {
                    document.title = "Invoice Could not paid";
                    if (pi.error.type === "card_error") {
                        setErrorMessage(pi.error.message);
                    }
                    else {
                        captureException(pi.error);
                        setErrorMessage("An error occurred. Please try again later.");
                    }
                }
                else {
                    if (pi.paymentIntent.status === "succeeded") {
                        document.title = "Invoice Paid Successfully";
                        dispatch(notify({
                            message: "You paid the invoice successfully!",
                            type: "SUCCESS",
                            duration: 7,
                        }));
                    }
                    setPaymentIntent(pi.paymentIntent);
                }
            });
        };
        if (stripe) {
            fetchPaymentIntent();
        }
    }, [dispatch, stripe]);
    return !errorMessage ? (_jsxs("div", { className: "flex flex-col space-y-2", children: [_jsxs("div", { className: "flex flex-row items-center p-2 pt-0", children: [_jsx(PrighterLogo, { className: "w-8 h-8 p-1 bg-white rounded-full" }), _jsx("span", { className: "ml-4 text-gray-200 text-lg font-bold w-full", children: COMPANY_FULL_NAME_WITH_COM })] }), _jsxs("div", { className: "bg-gray-50 box-outerlayout", children: [_jsxs("div", { className: "flex flex-row justify-between mt-2", children: [_jsxs("div", { className: "flex flex-col mb-4", children: [_jsx("span", { className: "font-bold text-4xl", children: (paymentIntent === null || paymentIntent === void 0 ? void 0 : paymentIntent.amount) ? "\u20AC ".concat((paymentIntent.amount / 100).toFixed(2)) : "" }), _jsx("span", { className: "font-bold text-gray-500", children: paymentIntent === null || paymentIntent === void 0 ? void 0 : paymentIntent.description })] }), _jsx("div", { className: "bg-success-400/75 rounded px-2 w-fit self-start", children: _jsx("span", { className: "text-success-900", children: "PAID" }) })] }), _jsxs("div", { className: "grid pl-8", style: { gridTemplateColumns: "100px 300px" }, children: [_jsx("span", { className: "font-bold text-gray-600", children: _jsx(T, { keyName: "generic.from" }) }), _jsx("span", { children: COMPANY_FULL_NAME })] })] })] })) : (_jsx("div", { children: errorMessage }));
}
