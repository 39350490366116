var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, TextField } from "@mui/material";
import { T } from "@tolgee/react";
import { GRAY_200, PRIGHTER_HIGHLIGHT } from "const/color";
import { buttonStyle, multilineStyle } from "pages/Client/EmailCases/Pages/styling";
export default function EducationalFormAdditional(props) {
    var previousStep = props.previousStep;
    return (_jsxs("div", { className: "flex flex-col gap-12", children: [_jsxs("div", { className: "flex flex-col gap-3", children: [_jsxs("h2", { className: "m-0 text-black font-fire text-[28px]", children: [_jsx(T, { keyName: "generic.section" }), " 5: ", _jsx(T, { keyName: "generic.additional_information" })] }), _jsx("h2", { className: "m-0 text-black font-fire text-[28px] font-normal", children: _jsx(T, { keyName: "email_intake.additional_data_regarding_request" }) })] }), _jsx(TextField, { label: "Additional information", multiline: true, sx: multilineStyle, rows: 3 }), _jsxs("div", { className: "flex items-center gap-2", children: [_jsx(Button, { type: "button", sx: __assign(__assign({}, buttonStyle), { backgroundColor: GRAY_200, color: "white" }), onClick: previousStep, children: _jsx(T, { keyName: "generic.back" }) }), _jsx(Button, { type: "submit", sx: __assign(__assign({}, buttonStyle), { backgroundColor: PRIGHTER_HIGHLIGHT, color: "white" }), children: _jsx(T, { keyName: "generic.save_request" }) }), _jsx("span", { className: "ml-2 text-[#B6B6B6]", children: _jsx(T, { keyName: "generic.or_press_enter" }) }), _jsx(FontAwesomeIcon, { icon: "circle-exclamation", color: "orange", size: "lg", className: "ml-3" }), _jsx("span", { tabIndex: 0, role: "button", className: "ml-2 underline decoration-solid text-prighterdark", children: _jsx(T, { keyName: "generic.missing_information" }) })] }), _jsxs("div", { children: [_jsx("h2", { children: _jsx(T, { keyName: "email_intake.education" }) }), _jsxs("div", { className: "box", children: [_jsx("p", { children: _jsx(T, { keyName: "email_intake.which_dates_are_important" }) }), _jsx("p", { children: _jsx(T, { keyName: "email_intake.different_legal_deadlines" }) })] }), _jsx("p", { children: _jsx(T, { keyName: "email_intake.different_legal_jurisdictions" }) }), _jsx("h2", { children: _jsx(T, { keyName: "generic.examples" }) })] })] }));
}
