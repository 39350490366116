import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
export default function BaseSelectField(_a) {
    var _b = _a.state, state = _b === void 0 ? ["", function () { }] : _b, className = _a.className, defaultValue = _a.defaultValue, _c = _a.label, label = _c === void 0 ? "" : _c, options = _a.options, _d = _a.required, required = _d === void 0 ? false : _d, _e = _a.error, error = _e === void 0 ? false : _e, _f = _a.disabled, disabled = _f === void 0 ? false : _f, _g = _a.fullWidth, fullWidth = _g === void 0 ? true : _g, _h = _a.sx, sx = _h === void 0 ? {} : _h;
    var value = state[0], setValue = state[1];
    var handleChange = function (event) {
        setValue(event.target.value);
    };
    return (_jsxs(FormControl, { required: required, fullWidth: fullWidth, error: error, children: [_jsx(InputLabel, { id: "demo-simple-select-label", children: label }), _jsx(Select, { disabled: disabled, required: required, sx: sx, className: className, labelId: "demo-simple-select-label", id: "demo-simple-select", value: value, label: label, onChange: handleChange, defaultValue: defaultValue, children: Object.entries(options).map(function (_a) {
                    var key = _a[0], val = _a[1];
                    return (_jsx(MenuItem, { value: key, children: val }, key));
                }) })] }));
}
