import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext } from "react-hook-form";
import { BaseSelectField } from "components";
import { selectStyle } from "pages/Client/EmailCases/Pages/styling";
import tolgee from "services/translation";
function ActingForForm() {
    var _a, _b;
    var _c = useFormContext(), watch = _c.watch, setValue = _c.setValue, errors = _c.formState.errors;
    var acting_for = watch("actors.1.acting_for");
    return (_jsx("div", { className: "flex flex-col gap-2  items-start text-darkgray ", children: _jsx(BaseSelectField, { error: !!((_b = (_a = errors === null || errors === void 0 ? void 0 : errors.actors) === null || _a === void 0 ? void 0 : _a.at(0)) === null || _b === void 0 ? void 0 : _b.acting_for), required: true, sx: selectStyle, state: [acting_for, function (val) { return setValue("actors.1.acting_for", val); }], options: {
                MYSELF: tolgee.t("email_intake.option_for_himself"),
                AS_PARENT: tolgee.t("email_intake.option_as_parent"),
                AS_LEGAL_REPRESENTATIVE: tolgee.t("email_intake.option_as_legal_representative"),
            } }) }));
}
export default ActingForForm;
