var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
export function BaseTabs(_a) {
    var tabsData = _a.tabsData, _b = _a.defaultTab, defaultTab = _b === void 0 ? 0 : _b;
    var _c = useState(defaultTab), activeTab = _c[0], setActiveTab = _c[1];
    var handleTabChange = function (event, newValue) {
        setActiveTab(newValue);
    };
    return (_jsxs(Box, { children: [_jsx(Tabs, { value: activeTab, onChange: handleTabChange, sx: {
                    borderBottom: 1,
                    borderColor: "divider",
                    "& .MuiTab-root": {
                        textTransform: "none",
                        backgroundColor: "transparent",
                        border: "1px solid transparent",
                        borderRadius: "8px 8px 0 0",
                        padding: "10px 20px",
                        transition: "background-color 0.3s ease, border 0.3s ease",
                        "&:not(.Mui-selected)": {
                            backgroundColor: "transparent",
                        },
                        "&.Mui-selected": {
                            backgroundColor: "white",
                            color: "primary.main",
                            borderBottom: "none",
                            borderLeft: "1px solid #ddd",
                            borderRight: "1px solid #ddd",
                            borderTop: "1px solid #ddd",
                            fontWeight: "bold",
                        },
                        "&:hover": {
                            backgroundColor: "rgba(255, 255, 255, 0.2)",
                        },
                    },
                    "& .MuiTabs-indicator": {
                        display: "none",
                    },
                }, children: tabsData.map(function (tab) { return (_jsx(Tab, { "data-testid": tab.testId, label: tab.label }, tab.id)); }) }), tabsData.map(function (tab, index) { return (_jsx(TabPanel, { value: activeTab, index: index, children: tab.content }, tab.id)); })] }));
}
function TabPanel(_a) {
    var children = _a.children, value = _a.value, index = _a.index, other = __rest(_a, ["children", "value", "index"]);
    return (_jsx("div", __assign({ role: "tabpanel", hidden: value !== index, id: "tabpanel-".concat(index), "aria-labelledby": "tab-".concat(index) }, other, { children: value === index && _jsx(Box, { py: 3, children: children }) })));
}
