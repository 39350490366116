var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@tolgee/react";
import useEnhancedTable from "helpers/hooks/useEnhancedTable";
import { api } from "services";
import { EnhancedTable } from "components";
export default function InvalidName() {
    var _a = useEnhancedTable(), tableConfig = _a[0], setKeys = _a[1], setValues = _a[2], setSettings = _a[3];
    useEffect(function () {
        function getUsersWithInvalidName() {
            return __awaiter(this, void 0, void 0, function () {
                var res, tempValues, clickables;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, api.getUsersWithInvalidName()];
                        case 1:
                            res = _a.sent();
                            if (!res) {
                                return [2 /*return*/];
                            }
                            tempValues = [];
                            clickables = [];
                            res.forEach(function (user) {
                                tempValues.push({
                                    cells: {
                                        Company: { displayedString: user.company_name },
                                        Name: { displayedString: user.name },
                                        Email: { displayedString: user.email },
                                        Country: { displayedString: user.company_country },
                                        Actions: {
                                            displayedElement: (_jsx("a", { href: user.admin_link, className: "button-primary rounded-full w-8 h-8 flex justify-center items-center", children: _jsx(FontAwesomeIcon, { icon: "info", size: "sm" }) })),
                                            displayedString: user.admin_link,
                                        },
                                    },
                                    id: user.uuid,
                                });
                                clickables.push({
                                    id: user.uuid,
                                    actionUrl: user.admin_link,
                                });
                            });
                            setValues(tempValues);
                            setKeys(["Company", "Name", "Email", "Country", "Actions"]);
                            setSettings({
                                onClickType: "REDIRECT_FLASK",
                                onClickEvents: clickables,
                            });
                            return [2 /*return*/];
                    }
                });
            });
        }
        getUsersWithInvalidName();
    }, []);
    return (_jsxs("div", { className: "flex flex-col mx-auto max-w-7xl", "data-testid": "invalid-name-page", children: [_jsx("h1", { children: _jsx(T, { keyName: "generic.invalid_name" }) }), tableConfig && _jsx(EnhancedTable, { config: tableConfig })] }));
}
