// Remove this when you can, this is bad design
export var getProcessId = function () {
    return serializeRopaRoute(window.location.pathname).entityId;
};
// Remove this when you can, this is bad design
export var getBusinessId = function () {
    var path = window.location.pathname;
    var businessIdRegex = /\/business\/(\d+)\//;
    var k = path === null || path === void 0 ? void 0 : path.match(businessIdRegex);
    return Array.isArray(k) && k.length > 1 ? k[1] : "";
};
export function isRopaToolPath(path) {
    var regex = /^\/business\/\d+\/ropa/;
    return regex.test(path);
}
export function serializeRopaRoute(path) {
    var regex = /business\/(\d+)\/ropa(?:\/([^/]+))?(?:\/([^/]+))?/;
    var match = path.match(regex);
    if (!isRopaToolPath(path) || !match) {
        return null;
    }
    var final_segment = "list";
    if (match[3] === "create") {
        final_segment = "create";
    }
    else if (match[3] && !Number.isNaN(match[3])) {
        final_segment = "detail";
    }
    return {
        businessId: match[1],
        navItem: match[2],
        page: final_segment,
        entityId: match[3] && !Number.isNaN(match[3]) ? match[3] : null,
    };
}
export function generateRopaRoute(history) {
    switch (history.page) {
        case "create":
            return "/business/".concat(history.businessId, "/ropa/").concat(history.navItem, "/create");
        case "detail":
            return "/business/".concat(history.businessId, "/ropa/").concat(history.navItem, "/").concat(history.entityId);
        default:
            return "/business/".concat(history.businessId, "/ropa/").concat(history.navItem);
    }
}
