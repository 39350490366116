var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Checkbox, TextField } from "@mui/material";
import { T } from "@tolgee/react";
import { useFormContext } from "react-hook-form";
import tolgee from "services/translation";
export default function AdditionalInfoForm(_a) {
    var _b, _c;
    var data = _a.data, additionalInfoState = _a.additionalInfoState;
    var dsrs = data.dsrs, actors = data.actors;
    var additionalInfo = additionalInfoState[0], setAdditionalInfo = additionalInfoState[1];
    var _d = useFormContext(), register = _d.register, watch = _d.watch, setValue = _d.setValue;
    var privacy_related = watch("request_privacy_related");
    var identifiable_info = watch("identifiable_info");
    var files = watch("files");
    var getTypesList = function () {
        var dsrsTypes = [];
        dsrs === null || dsrs === void 0 ? void 0 : dsrs.filter(function (i) { return i.checked === true; }).forEach(function (i) {
            if (i.type === "DSR_CCPA_ACCESS") {
                return i.inquiries.forEach(function (j) {
                    if (j.checked) {
                        dsrsTypes.push(j.type);
                    }
                });
            }
            return dsrsTypes.push(i.type);
        });
        return dsrsTypes === null || dsrsTypes === void 0 ? void 0 : dsrsTypes.map(function (j) { return (_jsxs("div", { children: ["- ", _jsx(T, { keyName: "dsr.type.".concat(j.toLowerCase()) })] }, j)); });
    };
    var formatAddressValue = function (address) {
        var value = "".concat(address.postal, " ").concat(address.city, ", ").concat(address.street, ", ").concat(address.country_iso);
        return value;
    };
    return (_jsxs(_Fragment, { children: [_jsxs("h2", { children: [_jsx(T, { keyName: "ccpa.summary" }), ":"] }), _jsxs(_Fragment, { children: [_jsx("h4", { children: _jsx(T, { keyName: "ccpa.request_type" }) }), getTypesList()] }), _jsxs(_Fragment, { children: [_jsx("h4", { children: _jsx(T, { keyName: "ccpa.data_subject_info" }) }), Object.entries(identifiable_info).map(function (_a) {
                        var key = _a[0], value = _a[1];
                        return (_jsxs("option", { value: key, children: [key === "address" && (_jsxs(_Fragment, { children: [_jsx(T, { keyName: "dsr.settings.ccpa.default_data.".concat(key) }), ":", " ", formatAddressValue(value)] })), key !== "address" && (_jsxs(_Fragment, { children: [_jsx(T, { keyName: "dsr.settings.ccpa.default_data.".concat(key) }), ": ", value] }))] }, key));
                    })] }), ((_b = actors[1]) === null || _b === void 0 ? void 0 : _b.acting_for) !== "MYSELF" && (_jsxs(_Fragment, { children: [_jsx("h4", { children: _jsx(T, { keyName: "ccpa.requester_info" }) }), ((_c = actors[1]) === null || _c === void 0 ? void 0 : _c.acting_for) && (_jsxs("div", { children: [_jsx(T, { keyName: "ccpa.acting_for" }), ":", " ", _jsx(T, { keyName: "ccpa.ds_info.acting_for.".concat(actors[1].acting_for.toLowerCase()) })] })), _jsxs("div", { children: [_jsx(T, { keyName: "ccpa.name" }), ": ", actors[1].name] }), _jsxs("div", { children: [_jsx(T, { keyName: "ccpa.email" }), ": ", actors[1].email] })] })), files.length > 0 && (_jsxs("div", { children: [_jsx("h4", { children: _jsx(T, { keyName: "ccpa.uploads" }) }), files.map(function (i) { return (_jsxs("div", { children: ["- ", i.name] }, i.name)); })] })), _jsxs("div", { className: "mt-8", children: [_jsxs("b", { children: [_jsx(T, { keyName: "dsr.email.for_contact" }), ":"] }), " ", actors[1].email] }), _jsxs(_Fragment, { children: [_jsxs("h2", { className: "mt-8", children: [_jsx(T, { keyName: "ccpa.additional_data" }), ":"] }), _jsx(TextField, { onChange: function (e) { return setAdditionalInfo(e.target.value); }, value: additionalInfo, className: "w-full", label: tolgee.t({
                            key: "ccpa.additional_data.additional_info",
                        }), rows: 5, multiline: true, InputLabelProps: {
                            shrink: true,
                        } })] }), _jsx("button", { className: "mt-2", type: "button", onClick: function () { return setValue("request_privacy_related", !privacy_related); }, children: _jsxs("div", { className: "flex items-center text-center", children: [_jsx(Checkbox, __assign({}, register("request_privacy_related"), { checked: privacy_related })), _jsxs("span", { className: "font-bold", children: [_jsx(T, { keyName: "ccpa.additional_data.request_privacy_related" }), " *"] })] }) })] }));
}
