var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import api from "services/api/base";
import { entityDictionary, infoDictionary } from "const/ropa/dictionaries";
var ROPA_URL = "http://127.0.0.1:31231";
var getLinkRequestUrl = function (targetType, businessId, processId, targetId) {
    return "/businesses/".concat(businessId, "/processes/").concat(processId, "/").concat(targetType).concat(targetId ? "/".concat(targetId) : "");
};
export function getAll(type, businessId) {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.get(entityDictionary(type, businessId), {
                        baseURL: ROPA_URL,
                    })];
                case 1:
                    response = _a.sent();
                    if (Array.isArray(response.data)) {
                        return [2 /*return*/, response.data];
                    }
                    return [2 /*return*/, []];
            }
        });
    });
}
export function get(type, businessId, id) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = infoDictionary(type, id, businessId);
                    if (!url) {
                        return [2 /*return*/, null];
                    }
                    return [4 /*yield*/, api.get(url, {
                            baseURL: ROPA_URL,
                        })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    });
}
export function create(type, businessId, body) {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.post(entityDictionary(type, businessId), body, {
                        baseURL: ROPA_URL,
                    })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response === null || response === void 0 ? void 0 : response.data];
            }
        });
    });
}
export function update(type, businessId, targetId, body) {
    return __awaiter(this, void 0, void 0, function () {
        var url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = infoDictionary(type, targetId, businessId);
                    if (!url) {
                        return [2 /*return*/, null];
                    }
                    return [4 /*yield*/, api.patch(url, body, {
                            baseURL: ROPA_URL,
                        })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.status >= 200 && res.status < 300];
            }
        });
    });
}
export function deleteResource(type, businessId, targetId) {
    return __awaiter(this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.delete("".concat(entityDictionary(type, businessId), "/").concat(targetId), {
                        baseURL: ROPA_URL,
                    })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.status >= 200 && res.status < 300];
            }
        });
    });
}
export function getUnlinked(targetType, businessId, processId) {
    return __awaiter(this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.get("".concat(getLinkRequestUrl(targetType, businessId, processId), "/notlinked"), {
                        baseURL: ROPA_URL,
                    })];
                case 1:
                    res = _a.sent();
                    if (Array.isArray(res.data)) {
                        return [2 /*return*/, res.data];
                    }
                    return [2 /*return*/, []];
            }
        });
    });
}
function getLinked(targetType, businessId, processId) {
    return __awaiter(this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.get(getLinkRequestUrl(targetType, businessId, processId), {
                        baseURL: ROPA_URL,
                    })];
                case 1:
                    res = _a.sent();
                    if (Array.isArray(res.data)) {
                        return [2 /*return*/, res.data];
                    }
                    return [2 /*return*/, []];
            }
        });
    });
}
export function getAllLinked(businessId, processId) {
    return __awaiter(this, void 0, void 0, function () {
        var entitiesExceptProcessor, response, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    entitiesExceptProcessor = [
                        "affectedgroups",
                        "datacategories",
                        "controllers",
                        "recipients/internal",
                        "recipients/external",
                        "recipients/processor",
                    ];
                    return [4 /*yield*/, Promise.all(entitiesExceptProcessor.map(function (type) {
                            return getLinked(type, businessId, processId).then(function (res) {
                                if (res.length > 0) {
                                    return res.map(function (element) {
                                        return __assign(__assign({}, element), { type: type }); // should be entityDictionary[type] instead of type
                                    });
                                }
                                return [];
                            });
                        }))];
                case 1:
                    response = _a.sent();
                    data = [].concat.apply([], response);
                    return [2 /*return*/, data];
            }
        });
    });
}
export function getAllLinkedProcesses(type, businessId, id) {
    return __awaiter(this, void 0, void 0, function () {
        var url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = infoDictionary(type, id, businessId);
                    if (!url) {
                        return [2 /*return*/, []];
                    }
                    return [4 /*yield*/, api.get("".concat(url, "/processes"), {
                            baseURL: ROPA_URL,
                        })];
                case 1:
                    res = _a.sent();
                    if (Array.isArray(res.data)) {
                        return [2 /*return*/, res.data];
                    }
                    return [2 /*return*/, []];
            }
        });
    });
}
export function link(targetType, businessId, processId, targetId, body) {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.post(getLinkRequestUrl(targetType, businessId, processId, targetId), __assign({ business_id: businessId, process_id: processId, targetType: targetId }, body), {
                        baseURL: ROPA_URL,
                    })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    });
}
export function unlink(targetType, businessId, processId, targetId) {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.delete(getLinkRequestUrl(targetType, businessId, processId, targetId), {
                        baseURL: ROPA_URL,
                    })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    });
}
export function getDescriptions() {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.get("/gui/descriptionhelper", {
                        baseURL: ROPA_URL,
                    })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    });
}
export function getBusinessIds() {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.get("/businesses_with_access", {
                        baseURL: ROPA_URL,
                    })];
                case 1:
                    response = _a.sent();
                    if (Array.isArray(response)) {
                        return [2 /*return*/, response.data];
                    }
                    return [2 /*return*/, []];
            }
        });
    });
}
