import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useState } from "react";
import GeoLocation from "pages/Client/Case/DSR/ClientView/Components/GeoLocation";
import tolgee from "services/translation";
import { useCase } from "pages/Client/Case/CaseContext";
export default function DSGeoLocation() {
    var state = useCase()[0];
    var _a = useState(false), expanded = _a[0], setExpanded = _a[1];
    return (state.case.extra.ip_geo_location && (_jsx("div", { className: "mb-4 box-outerlayout", children: _jsxs(Accordion, { expanded: expanded, onChange: function () { return setExpanded(!expanded); }, sx: {
                backgroundColor: "white",
                border: "none",
                boxShadow: "none",
                borderRadius: 0,
                padding: 0,
            }, className: "w-full p-2 ", "data-testid": "ds-location", children: [_jsx(AccordionSummary, { expandIcon: _jsx(FontAwesomeIcon, { size: "lg", icon: "angle-down" }), "aria-controls": "panel1a-content", sx: { padding: "0" }, id: "panel1a-header", children: _jsx("h2", { children: tolgee.t({
                            key: "dsr.ds_location_info",
                        }) }) }), _jsx(AccordionDetails, { sx: { padding: 0 }, children: _jsx(GeoLocation, { geoLocation: state.case.extra.ip_geo_location, ipAddress: state.case.extra.ip_address, physicalAddress: state.case.extra.address }) })] }) })));
}
