var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { PRIGHTER_BLUE, PRIGHTER_HIGHLIGHT } from "const/color";
var baseStyle = {
    borderRadius: "12px",
    backgroundColor: "#F7F7F7",
};
export var selectStyle = __assign(__assign({}, baseStyle), { height: "44px" });
export var multilineStyle = {
    "& .MuiInputBase-root": __assign({}, baseStyle),
};
export var inputStyle = {
    "& .MuiInputBase-root": __assign(__assign({}, baseStyle), { height: "44px", color: PRIGHTER_HIGHLIGHT }),
};
export var buttonStyle = __assign(__assign({}, baseStyle), { backgroundColor: PRIGHTER_BLUE, height: "40px", paddingX: "24px", paddingY: "8px" });
