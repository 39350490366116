var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { InputLabel, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { T } from "@tolgee/react";
import { ExtendedSelectField } from "components";
import { getCountryAsOptions } from "helpers";
import { inputStyle } from "pages/Client/EmailCases/Pages/styling";
import { useAppSelector } from "store";
import tolgee from "services/translation";
function ConsumerForm() {
    var _a, _b;
    var _c = useFormContext(), register = _c.register, watch = _c.watch, getValues = _c.getValues, setValue = _c.setValue, errors = _c.formState.errors;
    var _d = useState([]), countryOptions = _d[0], setCountryOptions = _d[1];
    var countries = useAppSelector(function (state) { return state.app; }).countries;
    useEffect(function () {
        if (countries) {
            var options = getCountryAsOptions(countries);
            setCountryOptions(options);
        }
    }, [countries]);
    var countryIso = watch("identifiable_info.address.country_iso");
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "grid grid-cols-1 md:grid-cols-2 gap-4  items-start text-darkgray ", children: [_jsxs("div", { className: "flex flex-col gap-2", children: [_jsx(InputLabel, { children: _jsx(T, { keyName: "generic.name" }) }), _jsx(TextField, __assign({ size: "small", sx: inputStyle, error: !!((_a = errors === null || errors === void 0 ? void 0 : errors.identifiable_info) === null || _a === void 0 ? void 0 : _a.username) }, register("identifiable_info.username", {
                                required: tolgee.t("email_intake.name_is_required"),
                            })))] }), _jsxs("div", { className: "flex flex-col gap-2", children: [_jsx(InputLabel, { children: _jsx(T, { keyName: "generic.email" }) }), _jsx(TextField, __assign({ size: "small", sx: inputStyle, error: !!((_b = errors === null || errors === void 0 ? void 0 : errors.identifiable_info) === null || _b === void 0 ? void 0 : _b.email) }, register("identifiable_info.email", {
                                validate: function (value) {
                                    var actingFor = getValues("actors.1.acting_for");
                                    if (actingFor === "MYSELF" && !value) {
                                        return tolgee.t("email_intake.email_is_required");
                                    }
                                    return true;
                                },
                            })))] }), _jsxs("div", { className: "flex flex-col gap-2", children: [_jsx(InputLabel, { children: _jsx(T, { keyName: "generic.customer_id" }) }), _jsx(TextField, __assign({ size: "small", sx: inputStyle }, register("identifiable_info.customer_number")))] }), _jsxs("div", { className: "flex flex-col gap-2", children: [_jsx(InputLabel, { children: _jsx(T, { keyName: "generic.phone_number" }) }), _jsx(TextField, __assign({ size: "small", sx: inputStyle }, register("identifiable_info.phone")))] })] }), _jsxs("div", { className: "grid grid-cols-2 md:grid-cols-4 gap-4  items-start text-darkgray ", children: [_jsxs("div", { className: "flex flex-col gap-2 col-span-2", children: [_jsx(InputLabel, { children: _jsx(T, { keyName: "generic.city" }) }), _jsx(TextField, __assign({ size: "small", sx: inputStyle }, register("identifiable_info.address.city")))] }), _jsxs("div", { className: "flex flex-col gap-2 col-span-2", children: [_jsx(InputLabel, { children: _jsx(T, { keyName: "generic.street" }) }), _jsx(TextField, __assign({ size: "small", sx: inputStyle }, register("identifiable_info.address.street")))] }), _jsxs("div", { className: "flex flex-col gap-2 ", children: [_jsx(InputLabel, { children: _jsx(T, { keyName: "generic.postal" }) }), _jsx(TextField, __assign({ size: "small", sx: inputStyle }, register("identifiable_info.address.postal")))] }), _jsxs("div", { className: "flex flex-col gap-2 col-span-2 ", children: [_jsx(InputLabel, { children: _jsx(T, { keyName: "generic.country" }) }), _jsx(ExtendedSelectField, { sx: inputStyle, state: [
                                    countryIso,
                                    function (text) { return setValue("identifiable_info.address.country_iso", text); },
                                ], options: countryOptions, label: "" })] })] })] }));
}
export default ConsumerForm;
