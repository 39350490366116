import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { STRIPE_KEY } from "const/env";
import InvoicePaidBox from "pages/Client/Billing/PayInvoice/InvoicePaidBox";
export default function InvoicePaid() {
    var _a = useState(), stripeOptions = _a[0], setStripeOptions = _a[1];
    var _b = useState(), stripePromise = _b[0], setStripePromise = _b[1];
    useEffect(function () {
        var sp = loadStripe(STRIPE_KEY);
        if (sp) {
            setStripePromise(sp);
        }
    }, []);
    useEffect(function () {
        var clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
        var tempOptions = {
            clientSecret: clientSecret,
        };
        setStripeOptions(tempOptions);
    }, []);
    return (_jsx("div", { className: "bg-prighterblue w-full h-screen overflow-auto flex items-center justify-center", children: stripePromise && (stripeOptions === null || stripeOptions === void 0 ? void 0 : stripeOptions.clientSecret) && (_jsx(Elements, { stripe: stripePromise, options: stripeOptions, children: _jsx(InvoicePaidBox, {}) })) }));
}
