var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Checkbox, FormControlLabel, FormGroup, TextField, ToggleButton, ToggleButtonGroup, } from "@mui/material";
import { T } from "@tolgee/react";
import { useEffect, useRef, useState } from "react";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCase } from "pages/Client/Case/CaseContext";
import tolgee from "services/translation";
import { CorrectExemptionResultEnum } from "types/case";
import { BaseButton, BaseModal } from "components";
import { correctInformationExemptions } from "const/dsr";
import { api } from "services";
import { fetchCaseData, finalizeCheck, reopenCheck } from "store/thunks";
import { isCheckFinished } from "helpers/case";
export default function CCPACorrectionExcludeData() {
    var _this = this;
    var _a = useCase(), state = _a[0], dispatch = _a[1];
    var caseData = state.case;
    var isFirstRender = useRef(true);
    var isFinished = isCheckFinished(state);
    var lowerRequestType = "correct";
    var _b = useState([]), correctedExemptions = _b[0], setCorrectedExemptions = _b[1];
    var _c = useState({
        dataIndex: null,
        isDisplayed: false,
        selectedItems: [],
        options: correctInformationExemptions.map(function (item) { return ({
            value: item,
            selected: false,
        }); }),
    }), correctionModal = _c[0], setCorrectionModal = _c[1];
    useEffect(function () {
        if (isFirstRender.current) {
            // Skip the first render
            isFirstRender.current = false;
            return;
        }
        if (!caseData.case_checks.find(function (item) { return item.name === "EXEMPTIONS"; }).closed_at) {
            putDataToBackend(correctedExemptions);
        }
    }, [correctedExemptions]);
    useEffect(function () {
        setCorrectedExemptions(state.case.outcome.correct_exemptions);
    }, [caseData]);
    var putDataToBackend = function (data) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (isFinished) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, api.putDsrCorrectExemptions(caseData.uuid, data)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleCorrectionToggle = function (newValue, index) {
        if (!newValue) {
            return;
        }
        var localData = __spreadArray([], correctedExemptions, true);
        if (newValue === CorrectExemptionResultEnum.CONFIRM) {
            localData[index].result = newValue;
            localData[index].exemptions = [];
            setCorrectedExemptions(localData);
        }
        else {
            setCorrectionModal(function (prevState) { return (__assign(__assign({}, prevState), { dataIndex: index, isDisplayed: true })); });
        }
    };
    var addDataExceptions = function () {
        var localItems = __spreadArray([], correctedExemptions, true);
        localItems[correctionModal.dataIndex].result = CorrectExemptionResultEnum.DENY;
        localItems[correctionModal.dataIndex].exemptions = correctionModal.selectedItems;
        setCorrectedExemptions(localItems);
        setCorrectionModal(function () { return ({
            dataIndex: null,
            isDisplayed: false,
            selectedItems: [],
            options: correctInformationExemptions.map(function (item) { return ({
                value: item,
                selected: false,
            }); }),
        }); });
    };
    var handleSubmit = function () {
        finalizeCheck()(dispatch, caseData.uuid, state.ui.check);
        fetchCaseData()(dispatch, caseData.uuid, false);
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex flex-col space-y-4", children: [_jsx("div", { className: "self-end mb-8", children: _jsx(Button, { "data-testid": "dsr_confirmation_button", color: "success", disabled: correctedExemptions.filter(function (item) { return item.result === CorrectExemptionResultEnum.PENDING; }).length > 0 || isFinished, onClick: handleSubmit, children: _jsx(T, { keyName: "ccpa.".concat(lowerRequestType, ".confirm") }) }) }), _jsx("div", { className: isFinished ? "pointer-events-none" : "pointer-events-auto", children: correctedExemptions.map(function (item, index) { return (_jsxs("div", { className: "flex flex-row gap-8 w-full mb-8", children: [_jsxs("div", { className: "flex flex-row w-full gap-4", children: [_jsx(TextField, { className: "w-full", value: item.data, label: tolgee.t({
                                                key: "ccpa.rights_form.request_related_to_following_personal_information",
                                            }), slotProps: {
                                                input: {
                                                    readOnly: true,
                                                },
                                            } }), _jsx(TextField, { className: "w-full", value: item.why, label: tolgee.t({
                                                key: "ccpa.rights_form.personal_information_is_wrong_or_incomplete_because",
                                            }), slotProps: {
                                                input: {
                                                    readOnly: true,
                                                },
                                            } }), _jsx(TextField, { className: "w-full", value: item.new, label: tolgee.t({
                                                key: "ccpa.rights_form.amend_the_personal_information_as_follows",
                                            }), slotProps: {
                                                input: {
                                                    readOnly: true,
                                                },
                                            } })] }), _jsx("div", { children: _jsxs(ToggleButtonGroup, { value: item.result, exclusive: true, onChange: function (event, newValue) { return handleCorrectionToggle(newValue, index); }, "aria-label": "text alignment", children: [_jsx(ToggleButton, { "data-testid": "correctionExcludeData_togglePending", disabled: item.result !== CorrectExemptionResultEnum.PENDING, value: CorrectExemptionResultEnum.PENDING, "aria-label": "left aligned", sx: {
                                                    "&.Mui-selected": {
                                                        backgroundColor: "#9e9071",
                                                        color: "white",
                                                        "&:hover": {
                                                            backgroundColor: "#afa588",
                                                        },
                                                    },
                                                }, children: _jsx(HourglassTopIcon, {}) }), _jsx(ToggleButton, { "data-testid": "correctionExcludeData_toggleConfirm", value: CorrectExemptionResultEnum.CONFIRM, "aria-label": "centered", sx: {
                                                    "&.Mui-selected": {
                                                        backgroundColor: "#2dba3c",
                                                        color: "white",
                                                        "&:hover": {
                                                            backgroundColor: "#4ad158",
                                                        },
                                                    },
                                                }, children: _jsx(CheckIcon, {}) }), _jsx(ToggleButton, { "data-testid": "correctionExcludeData_toggleDeny", value: CorrectExemptionResultEnum.DENY, "aria-label": "right aligned", sx: {
                                                    "&.Mui-selected": {
                                                        backgroundColor: "#e04850",
                                                        color: "white",
                                                        "&:hover": {
                                                            backgroundColor: "#ed7c82",
                                                        },
                                                    },
                                                }, children: _jsx(CloseIcon, {}) })] }) })] }, index)); }) }), isFinished && (_jsx("div", { className: "flex justify-end", children: _jsx(Button, { onClick: function () {
                                reopenCheck()(dispatch, caseData.uuid, state.ui.check);
                            }, variant: "outlined", endIcon: _jsx(FontAwesomeIcon, { icon: "lock-open", className: "!text-sm" }), children: tolgee.t({
                                key: "dsr.reopen",
                            }) }) }))] }), _jsxs(BaseModal, { modalState: [
                    correctionModal.isDisplayed,
                    function (isDisplayed) {
                        return setCorrectionModal(function (prevState) { return (__assign(__assign({}, prevState), { isDisplayed: isDisplayed })); });
                    },
                ], children: [_jsx("div", { className: "w-[40rem] max-h-150 overflow-auto", children: correctionModal.options.map(function (item, index) { return (_jsx("div", { children: _jsx(FormGroup, { children: _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: item.selected, onChange: function (event) {
                                            var isChecked = event.target.checked;
                                            setCorrectionModal(function (prevState) { return (__assign(__assign({}, prevState), { selectedItems: isChecked
                                                    ? __spreadArray(__spreadArray([], prevState.selectedItems, true), [item.value], false) : prevState.selectedItems.filter(function (selectedItem) { return selectedItem !== item.value; }), options: prevState.options.map(function (option) {
                                                    return option.value === item.value
                                                        ? __assign(__assign({}, option), { selected: isChecked }) : option;
                                                }) })); });
                                        } }), label: tolgee.t(item.value) }) }) }, index)); }) }), _jsxs("div", { className: "flex flex-row justify-end gap-6", children: [_jsx(BaseButton, { color: "primary", disabled: correctionModal.selectedItems.length === 0, onClick: addDataExceptions, children: _jsx(T, { keyName: "general.send" }) }), _jsx(BaseButton, { color: "warning", variant: "outlined", onClick: function () {
                                    setCorrectionModal(function () { return ({
                                        dataIndex: null,
                                        isDisplayed: false,
                                        selectedItems: [],
                                        options: correctInformationExemptions.map(function (item) { return ({
                                            value: item,
                                            selected: false,
                                        }); }),
                                    }); });
                                }, children: _jsx(T, { keyName: "general.discard" }) })] })] })] }));
}
