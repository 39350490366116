var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable default-param-last */
import { createSlice } from "@reduxjs/toolkit";
export var initialState = {
    name: null,
    email: null,
    company_representative_name: null,
    company_representative_role: null,
    company_name: null,
    company_authorized_to_sign: null,
    company_address: null,
    company_city: null,
    company_postal_code: null,
    company_country: null,
    demo: null,
    roles: [],
    managed_businesses: [],
    role: null,
    userLoaded: false,
    uuid: null,
    config: {
        light: null,
        email_channel_strict_domain: null,
        no_invoice_reminder_until: null,
        delete_after: null,
    },
};
export var userSlice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        setUser: function (previous_state, action) {
            if (previous_state === void 0) { previous_state = initialState; }
            var user = action.payload;
            var role;
            if (user.roles.some(function (r) { return r.name === "ADMIN"; })) {
                role = "ADMIN";
            }
            else if (user.roles.some(function (r) { return r.name === "DEV"; })) {
                role = "DEV";
            }
            else if (user.roles.length > 0) {
                role = "client";
            }
            return __assign(__assign(__assign({}, previous_state), action.payload), { role: role, userLoaded: true });
        },
    },
});
// Action creators are generated for each case reducer function
export var setUser = userSlice.actions.setUser;
export default userSlice.reducer;
