import { jsx as _jsx } from "react/jsx-runtime";
import { VerticalDataValue } from "components";
import tolgee from "services/translation";
export default function DSIdentificationTag(_a) {
    var _b, _c, _d, _e, _f;
    var personalData = _a.personalData;
    var data = [
        {
            key: "ds_name",
            value: (_b = personalData === null || personalData === void 0 ? void 0 : personalData.contact) === null || _b === void 0 ? void 0 : _b.name,
        },
        {
            key: "email_address",
            value: (_d = (_c = personalData === null || personalData === void 0 ? void 0 : personalData.contact) === null || _c === void 0 ? void 0 : _c.contact_points.find(function (i) { return i.type === "EMAIL"; })) === null || _d === void 0 ? void 0 : _d.value,
        },
        {
            key: "phone_number",
            value: (_f = (_e = personalData === null || personalData === void 0 ? void 0 : personalData.contact) === null || _e === void 0 ? void 0 : _e.contact_points.find(function (i) { return i.type === "PHONE"; })) === null || _f === void 0 ? void 0 : _f.value,
        },
    ].filter(function (i) { return !!i.value; });
    return (_jsx("div", { className: "flex flex-row justify-between pt-2", children: data.map(function (i) { return (_jsx(VerticalDataValue, { title: tolgee.t({
                key: "dsr.".concat(i.key),
            }), value: i.value }, i.key)); }) }));
}
