import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { T } from "@tolgee/react";
import { useTable } from "helpers/hooks";
import { Table } from "components";
import tolgee from "services/translation";
export default function CompanySubscriptions(_a) {
    var _b;
    var companyData = _a.companyData;
    var _c = useTable(), tableConfig = _c[0], setKeys = _c[1], setValues = _c[2], setSettings = _c[3];
    var activeProducts = ((_b = companyData === null || companyData === void 0 ? void 0 : companyData.features) === null || _b === void 0 ? void 0 : _b.length) > 0;
    useEffect(function () {
        if (activeProducts) {
            var subscriptions_1 = [];
            var description_1 = tolgee.t({
                key: "billing.description",
            });
            companyData.features.forEach(function (i) {
                var _a;
                subscriptions_1.push((_a = {},
                    _a[description_1] = _jsx(T, { keyName: "product_name.".concat(i) }),
                    _a.id = i,
                    _a));
            });
            setValues(subscriptions_1);
            setSettings({
                hideSearch: true,
                hideEmptyRows: true,
            });
            setKeys([description_1]);
        }
    }, [companyData]);
    return (_jsx("div", { className: "flex flex-col", style: { overflowX: "auto" }, children: activeProducts ? (tableConfig && _jsx(Table, { config: tableConfig })) : (_jsx(T, { keyName: "generic.none" })) }));
}
